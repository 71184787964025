<template>
  <div class="panel-content-vms-dashboard">
    <div class="panel-content-header-vms-dashboard">
      <div class="d-flex justify-content-between">
        <!-- <h2>{{ pageTitle }}</h2> -->
        <h2 class="d-flex">{{ host.name }} , {{ company.name }}</h2>
        <b-button size="sm" variant="primary" @click="goNewInvite">
          <i class="fas fa-plus mr-1"></i>Invite Visitor
        </b-button>
      </div>
    </div>
    <b-card
      class="bg-surface-2 d-flex justify-content-end my-3"
      title="Status Guideline"
    >
      <b-card-text>
        <div class="row">
          <div class="col">
            <table class="spacing">
              <tr>
                <h6><b-badge variant="warning">PENDING</b-badge></h6>
                <td><span class="ml-2">:</span></td>
                <td>
                  <span class="ml-2"
                    >The visitor has not yet accepted or declined the invitation
                    via email.
                  </span>
                </td>
              </tr>
              <tr>
                <h6><b-badge variant="danger">DECLINE</b-badge></h6>
                <td><span class="ml-2">:</span></td>
                <td>
                  <span class="ml-2"
                    >The visitor has declined the invitation to visit via
                    email.</span
                  >
                </td>
              </tr>
              <tr>
                <h6><b-badge variant="success">ACCEPTED</b-badge></h6>
                <td><span class="ml-2">:</span></td>
                <td>
                  <span class="ml-2"
                    >The visitor has accepted the visit invitation via
                    email.</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
      </b-card-text>
    </b-card>
    <b-card class="bg-surface-2 my-3">
      <div class="d-flex">
        <b-form-input
          class="mr-3"
          size="md"
          id="input-1"
          v-model="searchForm.keyword"
          v-on:keyup.enter="getData"
          placeholder="Search by visit code or visitor name"
        ></b-form-input>
        <b-button size="sm" variant="light" @click="getData"> Search </b-button>
        <b-button size="sm" variant="dark" @click="resetSearch" class="ml-1"
          >Clear
        </b-button>
      </div>
    </b-card>
    <b-row>
      <b-col>
        <h6 class="checkUpcoming"><strong>Upcoming Visits</strong></h6>
        <b-table
          :items="visitsList"
          :fields="visitsTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          class="tableVMS"
          striped
          responsive
          show-empty
          small
          sticky-header
        >
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(additional_metadata.check_in_time)="row">
            <div v-if="row.item.additional_metadata.check_in_time != null">
              {{
                row.item.additional_metadata.check_in_time | unixToTime12Hour
              }}
            </div>
            <div v-else>-</div>
          </template>
          <template #cell(status)="row">
            <h6>
              <b-badge
                class="p-2"
                style="width: 6rem"
                :variant="
                  row.item.status === 'APPROVED'
                    ? 'success'
                    : row.item.status === 'PENDING'
                    ? 'warning'
                    : 'danger'
                "
              >
                <div v-if="row.item.status == 'APPROVED'">ACCEPTED</div>
                <div v-else>{{ row.item.status }}</div>
                <!--                <strong class="font">{{ row.item.status }}</strong>-->
              </b-badge>
            </h6>
          </template>
          <template #cell(invite)="row">
            {{ row.item | inviteCode | dashForEmptyText }}
          </template>
          <template #cell(visitors)="row">
            <div v-for="visitor in row.item.visitors" :key="visitor.id">
              {{ visitor.name | humanizeText }}
            </div>
          </template>
          <template #cell(start_datetime)="row">
            <div>
              {{ row.item.visit_start_date | date }} -
              {{ row.item.visit_start_time | unixToTime12Hours }}
            </div>
          </template>
          <template #cell(end_datetime)="row">
            <div>
              {{ row.item.visit_end_date | date }} -
              {{ row.item.visit_end_time | unixToTime12Hours }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!--pagination -->
    <div class="d-flex justify-content-between" v-if="!busy && !search">
      <div class="d-flex">
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
          @change="handlePerPageChange"
        ></b-form-select>
        <div class="d-flex w-100">Per Page</div>
      </div>

      <b-pagination
        size="sm"
        v-if="perPage !== 'all'"
        class="d-flex"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="handlePageChange"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

export default {
  name: "VMSHostDashboard",
  data: function () {
    return {
      pageTitle: "VMS Host Dashboard",
      host: {},
      company: {},
      visitsList: [],
      visitsTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          thStyle: { width: "auto" }
        },
        /*  { key: "invite", label: "Invitation Code", sortable: true }, */
        {
          key: "additional_metadata.check_in_time",
          label: "Check-in",
          sortable: true,
          thStyle: { width: "auto" }
        },

        {
          key: "status",
          label: "Status",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "start_datetime",
          label: "Start Datetime",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "end_datetime",
          label: "End Datetime",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visitors",
          label: "Visitors",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ],
      searchForm: {
        keyword: ""
      },
      sortBy: "",
      sortDesc: true,
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  filters: {
    inviteCode(value) {
      let invite = value.invite;
      try {
        for (let key in invite) {
          var invite_code = invite[key].invite_code;
          return invite_code;
        }
      } catch (e) {
        return "-";
      }
    },
    checkIn(value) {
      let check = value.additional_metadata.check_in_time;
      console.log("check", check);
    }
  },
  mounted() {
    this.host_id = this.getCurrentUser.visitorservice_host_id;
    this.getVisitList();
    this.getHost();
  },
  methods: {
    getVisitList() {
      //debugger; // eslint-disable-line no-debugger
      let $this = this;
      $this.busy = true;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/host/" +
        this.host_id +
        "/visits/";

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              result[key] != undefined && Array.isArray(result[key]);
              {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key3 in result[validUrl]) {
              let visit_data = result[validUrl][key3];
              if (
                visit_data.status == "APPROVED" ||
                visit_data.status == "PENDING" ||
                visit_data.status == "DECLINED"
              ) {
                visit_data["invite"] = null;
                visit_data["visitors"] = null;
                $this.visitsList.push(visit_data);
                $this.fetchVisitors(visit_data);
                $this.fetchInvite(visit_data);
              }
            }
          }
          $this.busy = false;
        })
        .catch((err) => {
          console.log("visits err", err);
        });
    },
    fetchVisitors(visit_data) {
      let $this = this;
      var API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visit_data.id +
        "/visitors/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in $this.visitsList) {
              if (visit_data.id == $this.visitsList[key2].id) {
                $this.visitsList[key2].visitors = result[validUrl];
              }
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    fetchInvite(visit_data) {
      let $this = this;
      var API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/invite/all/?visit_id=" +
        visit_data.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in $this.visitsList) {
              if (visit_data.id == $this.visitsList[key2].id) {
                $this.visitsList[key2].invite = result[validUrl];
              }
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    getHost() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/host/?host_id=" +
        this.host_id;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result2 = data.result;
          let validUrl = "";
          if (result2 != undefined) {
            outer_loop: for (let key2 in result2) {
              result2[key2] != undefined && Array.isArray(result2[key2]);
              {
                validUrl = key2;
                break outer_loop;
              }
            }
          }
          if (result2[validUrl] != undefined) {
            $this.host = result2[validUrl];
            this.fetchCompanys($this.host.company_id);
          }
        })
        .catch((err) => {
          console.log("visits err", err);
        });
    },
    fetchCompanys(company_id) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/company/?company_id=" +
        company_id;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result3 = data.result;
          let validUrl = "";
          if (result3 != undefined) {
            outer_loop: for (let key3 in result3) {
              result3[key3] != undefined && Array.isArray(result3[key3]);
              {
                validUrl = key3;
                break outer_loop;
              }
            }
          }
          if (result3[validUrl] != undefined) {
            $this.company = result3[validUrl];
          }
        })
        .catch((err) => {
          console.log("visits err", err);
        });
    },
    goNewInvite: function () {
      this.$router
        .push({
          name: "create-invite",
          params: {
            isInvite: true,
            host: this.host,
            company: this.company
          }
        })
        .catch(() => {});
    },

    getData: function () {
      let $this = this;
      $this.busy = true;

      let query = {
        keyword: $this.searchForm.keyword
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/visit/search/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      try {
        const client = $this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            let result = data.result;
            if (result != undefined) {
              for (let key in result) {
                for (let key2 in result[key]) {
                  if (query.keyword != undefined) {
                    this.visitsList = result[key][key2];
                    $this.busy = false;
                  } else {
                    this.visitsList = [];
                    $this.busy = false;
                  }
                }
              }
            }
          })
          .catch((err) => {
            $this.busy = false;
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } catch (e) {
        console.log("e");
        console.log(e);
      }
    },
    resetSearch: function () {
      this.getVisitList();
      this.visitsList = [];
      this.searchForm = {
        keyword: ""
      };
    }
  }
};
</script>

<style lang="scss">
@import "./VMSHostDashboard";
</style>
