<template>
  <div id="panel_floorplan" class="h-100">
    <Floorplan
      :key="floor.id"
      :floorplan_image="floorplan_image"
      :building="building"
      :floor="floor"
      :zones="zones"
      ref="floorplanObj"
      @change-zoom-level="changeZoomLevel"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Floorplan from "@/components/PanelFloorplan/FloorplanView/Floorplan";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "FloorplanView",
  components: {
    Floorplan
  },
  data: function () {
    return {
      floorplan_image: null,
      building: {},
      floor: {},
      zones: []
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        // console.log(n);
        if (n != null && n.building != null && n.floor != null) {
          this.initView(n.building, n.floor, n.zone);
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log(this._getViewingLocation);
    if (this._getViewingLocation != null) {
      if (
        this._getViewingLocation.building != null &&
        this._getViewingLocation.floor != null
      ) {
        this.initView(
          this._getViewingLocation.building,
          this._getViewingLocation.floor,
          this._getViewingLocation.zone
        );
      }
    }
  },
  methods: {
    initView: function (building, floor, zone) {
      // console.log("FloorplanView - initView");
      var $this = this;
      var building_obj = this.$store.getters["psim/getBuilding"](building.id);

      var floor_obj = null;
      if (floor != null) {
        floor_obj = this.$store.getters["psim/getFloor"](building.id, floor.id);
      }

      var zone_obj = null;
      if (zone != null) {
        zone_obj = this.$store.getters["psim/getZone"](
          building.id,
          floor.id,
          zone.id
        );
      }

      if (floor_obj != null) {
        // var result = $this.$store.getters["psim/getFloorplans"]({"building_id": building_obj.id, "floor_id": floor_obj.id});
        // $this.$emit("fetched-floorplan", result);
        // $this.$store.dispatch("psim/fetchFloorPlan", {"building_id": building_obj.id, "floor_id": floor_obj.id}).then(result => {
        //     $this.$emit("fetched-floorplan", result);
        // });

        this.building = { id: building_obj.id, name: building_obj.name };
        this.floor = {
          id: floor_obj.id,
          name: floor_obj.name,
          index: floor_obj.index
        };
        this.zones = floor_obj.zones;
      }
    },
    resetFloorplan: function () {
      this.$refs.floorplanObj.resetFloorplan();
    },
    zoomIn: function () {
      this.$refs.floorplanObj.zoomIn();
    },
    zoomOut: function () {
      this.$refs.floorplanObj.zoomOut();
    },
    dragZoom: function (value) {
      this.$refs.floorplanObj.dragZoom(value);
    },
    changeZoomLevel: function (value) {
      this.$emit("change-toolbar-zoom-level", value);
    },
    toggleCameras: function (value) {
      this.$refs.floorplanObj.toggleCameras(value);
    },
    toggleDoors: function (value) {
      this.$refs.floorplanObj.toggleDoors(value);
    }
  }
};
</script>
