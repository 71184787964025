<template>
  <transition name="panel-slide" mode="out-in">
    <div v-if="showPanel" class="panel-alert-details panel-row">
      <div class="panel w-100 h-100" id="panel_alert_details">
        <div class="panel-content">
          <div
            class="panel-content-header mb-2 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <h2>Alert - {{ alertTitle }}</h2>
            </div>
            <div>
              <!-- <b-button variant="primary" size="sm" class="mr-2">Create a Case</b-button> -->
              <b-button @click="doHidePanel"
                ><i class="fa fa-times"></i
              ></b-button>
            </div>
          </div>

          <div class="panel-content-body w-100">
            <div class="section-alert-details scroller">
              <div>
                <h2>Alert Information</h2>
                <div class="d-flex">
                  <div v-if="matchedObject.image != ''">
                    <div class="matched-image-container">
                      <div class="matched-event-image-container">
                        <div class="matched-event-image">
                          <img v-lazy="imageSrc" />
                        </div>
                        <div
                          class="matched-event-image-label"
                          v-if="deviceType == 'camera'"
                        >
                          Captured Image
                        </div>
                      </div>
                      <div class="matched-face-image-container">
                        <div class="matched-face-image">
                          <img v-lazy="matchedObject.image" />
                        </div>
                        <div class="matched-face-image-label">
                          Matched {{ matchedObject.libraryType }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="event-image-container">
                    <img class="img-fluid" v-lazy="imageSrc" />
                  </div>

                  <div class="ml-2">
                    <div class="alert-info-card">
                      <div class="text-strong">Details</div>
                      <div>
                        <span style="text-transform: capitalize">{{
                          deviceType
                        }}</span>
                        : {{ deviceName }}
                      </div>
                      <div>Location : {{ locationName }}</div>
                      <div>
                        Created: {{ createdAtDisplay }} (<time-since
                          :date="createdAt"
                        ></time-since
                        >)
                      </div>
                    </div>

                    <div
                      class="alert-info-card mt-2"
                      v-show="matchedObject.image != ''"
                    >
                      <div class="text-strong">Matched Person</div>
                      <div>ID: {{ matchedObject.profileID }}</div>
                      <div>Name: {{ matchedObject.profileName }}</div>
                      <div>Library: {{ matchedObject.libraryName }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-100 mt-3 d-flex" v-if="deviceType == 'camera'">
                <div v-show="playbackVideoURL != null" class="pr-2">
                  <h2>Recording Playback</h2>
                  <div class="video-player-container">
                    <div
                      id="playback_video_container"
                      ref="playbackVideoContainer"
                    ></div>
                  </div>
                  <div
                    class="video-player-container d-flex align-items-center justify-content-center"
                    v-if="playbackVideoURL == null"
                  >
                    {{ playbackVideoMessage }}
                  </div>

                  <div
                    class="video-format-options-selector"
                    v-if="deviceType == 'camera'"
                  >
                    <b-form-select
                      v-model="playbackVideoFormat"
                      :options="playbackVideoFormatOptions"
                      @change="loadPlaybackPlayer"
                      size="sm"
                      class="mt-2"
                    ></b-form-select>
                  </div>
                </div>

                <div v-show="liveVideoURL != null" class="pr-2">
                  <h2>Live Stream</h2>
                  <div class="video-player-container">
                    <div
                      id="live_video_container"
                      ref="liveVideoContainer"
                    ></div>
                  </div>
                  <div
                    class="video-player-container d-flex align-items-center justify-content-center"
                    v-if="liveVideoURL == null"
                  >
                    {{ liveVideoMessage }}
                  </div>

                  <div
                    class="video-format-options-selector"
                    v-if="deviceType == 'camera'"
                  >
                    <b-form-select
                      v-model="liveVideoFormat"
                      :options="liveVideoFormatOptions"
                      @change="loadLivePlayer"
                      size="sm"
                      class="mt-2"
                    ></b-form-select>
                  </div>
                </div>
              </div>

              <div class="w-100 mt-3 mb-5" v-if="imageList.length > 1">
                <h2>Images</h2>
                <div class="d-flex">
                  <div
                    v-for="(img, i_index) in imageList"
                    :key="'image-' + i_index"
                    class="alert-image-item"
                  >
                    <img :src="img" class="img-fluid" />
                  </div>
                </div>
              </div>

              <div class="w-100 mt-3" v-if="hasMetadata">
                <div class="alert-info-card">
                  <div class="text-strong mt-3">Metadata</div>
                  <div v-for="metadata in alertMetadata" :key="metadata.key">
                    {{ metadata.name }}: {{ metadata.value }}
                  </div>
                </div>
              </div>
            </div>

            <div class="section-alert-details w-100 mt-3 scroller">
              <div>
                <h2>Similar Events</h2>
                <div
                  class="accordion"
                  role="tablist"
                  v-for="(events, date) in this.groupedSimilarEvents"
                  :key="date"
                >
                  <div>
                    <a v-b-toggle="'similar_events_' + date">
                      <i class="fa fa-calendar-alt mr-2"></i>
                      <span class="mr-2">{{ date }}</span>
                    </a>
                  </div>

                  <b-collapse visible :id="'similar_events_' + date">
                    <CardAlert
                      v-for="alert in events"
                      :key="alert.id"
                      :alert="alert"
                    />
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import Plyr from "plyr";
import Hls from "hls.js";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import CardAlert from "@/components/CardAlert/CardAlert.vue";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "AlertDetails",
  components: {
    CardAlert
  },
  data: function () {
    return {
      showPanel: false,
      alertID: "",
      alertTitle: "",
      deviceName: "",
      deviceIP: "",
      deviceType: "",
      liveVideoURL: "",
      liveVideoMessage: "",
      livePlayer: null,
      playbackVideoURL: "",
      playbackVideoMessage: "",
      playbackPlayer: null,
      imageSrc: "",
      imageList: [],
      locationName: "",
      createdAt: "",
      createdAtDisplay: "",
      alertBuilding: {},
      alertFloor: {},
      alertZone: {},
      alertMetadata: [],
      matchedObject: {
        image: "",
        profileName: "",
        profileID: "",
        id: "",
        libraryName: "",
        libraryID: "",
        libraryType: ""
      },
      similarEvents: [],
      groupedSimilarEvents: [],
      current_filters: null,
      deviceObj: null,
      playbackVideoFormat: "mpjpeg",
      playbackVideoFormatOptions: [
        { value: "mp4", text: "MP4" },
        { value: "hls", text: "HLS" },
        { value: "mpjpeg", text: "MPJPEG" },
        { value: "webm", text: "WEBM" }
      ],
      liveVideoFormat: "mpjpeg",
      liveVideoFormatOptions: [
        { value: "mp4", text: "MP4" },
        { value: "hls", text: "HLS" },
        { value: "mpjpeg", text: "MPJPEG" },
        { value: "webm", text: "WEBM" }
      ],
      videoURLs: {
        mp4: null,
        hls: null,
        mpjpeg: null,
        webm: null
      },
      playbackVideoURLs: {
        mp4: null,
        hls: null,
        mpjpeg: null,
        webm: null
      },
      videoAuthSession: null,
      videoAuthData: null,
      videoAuth: null
    };
  },
  computed: {
    ...mapGetters({
      _getViewingDetails: "psim/getViewingDetails",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    hasMetadata: function () {
      if (Object.keys(this.alertMetadata).length == 0) {
        return false;
      }
      return true;
    }
  },
  watch: {
    _getViewingDetails: {
      handler: function (n, o) {
        this.checkPanelVisible(n);
      },
      deep: true
    }
  },
  mounted: function () {
    // this.updateStatus();
  },
  methods: {
    checkPanelVisible: function (view_data) {
      var $this = this;
      var alert_types = ["alert"];

      if (view_data == null) {
        $this.showPanel = false;
        $this.resetPlaybackPlayer();
        $this.resetLivePlayer();
      } else if (alert_types.indexOf(view_data.type) < 0) {
        $this.showPanel = false;
        $this.resetPlaybackPlayer();
        $this.resetLivePlayer();
      } else {
        if ($this.showPanel == true && view_data.data.id != view_data.data.id) {
          $this.showPanel = false;
          $this.resetPlaybackPlayer();
          $this.resetLivePlayer();

          setTimeout(function () {
            $this.showPanel = true;
            $this.loadData(view_data);
          }, 500);
        } else {
          $this.showPanel = true;
          $this.loadData(view_data);
        }
      }
      var view_type = view_data != null ? view_data["type"] : null;
      // console.log(
      //   "AlertDetails: view type:",
      //   view_type,
      //   "Show?",
      //   $this.showPanel
      // );
    },
    loadData: function (view_data) {
      var $this = this;
      var disallowed_metadata = [
        "image",
        "lpr_engine",
        "cropped_image",
        "timestamp",
        "coordinates"
      ];

      $this.alertID = view_data.data.id;
      $this.alertTitle = view_data.data.title;
      $this.deviceName = view_data.data.device_name;
      $this.deviceIP = view_data.data.device_ip;
      $this.deviceType = view_data.data.device_type;
      if (
        view_data.data.building.name != null ||
        view_data.data.building.name != undefined
      ) {
        $this.locationName =
          view_data.data.building.name + " - " + view_data.data.floor.name;
      } else {
        $this.locationName = "-";
      }
      $this.createdAt = view_data.data.created;
      $this.createdAtDisplay = moment(view_data.data.created).format(
        "YYYY-MM-DD HH:MM:SS"
      );
      $this.imageSrc = view_data.data.thumbnail;
      $this.imageList = view_data.data.event_images;
      $this.alertBuilding = view_data.data.building;
      $this.alertFloor = view_data.data.floor;
      $this.alertZone = view_data.data.zone;
      $this.alertMetadata = [];
      if (view_data.data.metadata != undefined) {
        for (var m in view_data.data.metadata) {
          if (!(disallowed_metadata.indexOf(m) >= 0)) {
            let key = m;
            let value = view_data.data.metadata[m];
            let name = utils.titleCase(key, "_");
            let metadata_dict = { key: key, name: name, value: value };
            $this.alertMetadata.push(metadata_dict);
          }
        }
      }

      if (view_data.data.matched_libraryimage.libraryimage_id != undefined) {
        $this.matchedObject.id =
          view_data.data.matched_libraryimage.libraryimage_id;
        $this.matchedObject.image =
          view_data.data.matched_libraryimage.libraryimage_image;
        $this.matchedObject.profileID =
          view_data.data.matched_libraryimage.libraryimage_profile_id;
        $this.matchedObject.profileName =
          view_data.data.matched_libraryimage.libraryimage_profile_name;

        $this.matchedObject.libraryID =
          view_data.data.matched_libraryimage.library_id;
        $this.matchedObject.libraryName =
          view_data.data.matched_libraryimage.library_name;
        $this.matchedObject.libraryType =
          view_data.data.matched_libraryimage.library_type;
      } else {
        $this.matchedObject.id = "";
        $this.matchedObject.image = "";
        $this.matchedObject.profileID = "";
        $this.matchedObject.profileName = "";

        $this.matchedObject.libraryID = "";
        $this.matchedObject.libraryName = "";
        $this.matchedObject.libraryType = "";
      }

      let device_search;
      if ($this.deviceType == "camera") {
        device_search = $this.$store.getters["device/filterCameras"]({
          ip: $this.deviceIP
        });
        if (device_search.length > 0) {
          $this.deviceObj = device_search[0];

          $this.getVideoStream();
        }
      } else if ($this.deviceType == "door") {
        device_search = $this.$store.getters["device/filterDoors"]({
          ip: $this.deviceIP
        });
        if (device_search.length > 0) {
          $this.deviceObj = device_search[0];
        }
      }
      this.fetchSimilarEvents();
    },
    resetPlaybackPlayer: function () {
      var $this = this;

      try {
        if ($this.playbackPlayer != null) {
          $this.playbackPlayer.destroy();
          $this.playbackPlayer = null;
        }
      } catch (err) {
        //
      }

      if (
        $this.playbackVideoURL != null &&
        $this.$refs.playbackVideoContainer != null
      ) {
        while ($this.$refs.playbackVideoContainer.hasChildNodes()) {
          $this.$refs.playbackVideoContainer.removeChild(
            $this.$refs.playbackVideoContainer.lastChild
          );
        }
      }

      this.playbackVideoURL = null;
      $this.playbackVideoMessage = "Loading camera stream";
    },
    resetLivePlayer: function () {
      var $this = this;
      try {
        if ($this.livePlayer != null) {
          $this.livePlayer.destroy();
          $this.livePlayer = null;
        }
      } catch (err) {
        //
      }

      if (
        $this.liveVideoURL != null &&
        $this.$refs.liveVideoContainer != null
      ) {
        while ($this.$refs.liveVideoContainer.hasChildNodes()) {
          $this.$refs.liveVideoContainer.removeChild(
            $this.$refs.liveVideoContainer.lastChild
          );
        }
      }

      $this.liveVideoURL = null;
      $this.liveVideoMessage = "Loading camera stream";
    },
    doHidePanel: function () {
      var $this = this;
      $this.resetPlaybackPlayer();
      $this.resetLivePlayer();

      this.$store.dispatch("psim/setViewingDetails", null);
      this.$store.dispatch("psim/setLocatingAlert", null);

      var url_query_dict = JSON.parse(JSON.stringify(this.$route.query));
      if (url_query_dict.device_id != undefined) {
        delete url_query_dict["device_id"];
      }

      this.$router
        .push({
          name: this.$route.name,
          params: this.$route.params,
          query: url_query_dict
        })
        .catch(() => {});
    },
    getVideoStream: function () {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var has_playback = $this.deviceObj.data.details.is_recording;
      let stream_url = null;
      let stream_urls = null;
      let playback_url = null;
      var mpjpeg_stream = null;
      var playback_mpjpeg_stream = null;
      let has_stream = false;

      if ($this.deviceObj.monitor_type === "DIGIFORT") {
        stream_urls = $this.deviceObj.data.details.stream_url;
        playback_url = $this.deviceObj.data.details.playback_url;
        mpjpeg_stream = stream_urls.mpjpeg;
        playback_mpjpeg_stream = playback_url.mpjpeg;

        if (mpjpeg_stream) {
          has_stream = true;
          $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
          $this.playbackVideoURLs.mpjpeg = playback_mpjpeg_stream
            ? playback_mpjpeg_stream
            : null;
        }

        if (has_stream == false) {
          $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.deviceObj.ip) {
                      $this.videoAuthSession =
                        camera_data["details"]["authsession"]["AuthSession"];
                      $this.videoAuthData =
                        camera_data["details"]["authsession"]["AuthData"];
                      break;
                    }
                  }
                }
              }
              if (has_playback) {
                $this.loadPlaybackPlayer();
              } else if (has_playback == false) {
                $this.playbackVideoURL = null;
              }
              $this.loadLivePlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        }
      } else {
        //

        if ($this.deviceObj.data.details.stream_url != undefined) {
          stream_url = $this.deviceObj.data.details.stream_url;
          playback_url = $this.deviceObj.data.details.stream_url;
        } else {
          stream_urls = $this.deviceObj.data.details.stream_urls;
        }

        // console.log("stream_url");
        // console.log(stream_url);

        if (stream_url != null) {
          let mp4_stream = stream_url.mp4;
          let hls_stream = stream_url.hls;
          let webm_stream = stream_url.webm;
          mpjpeg_stream = stream_url.mpjpeg;

          let playback_mp4_stream = playback_url.mp4;
          let playback_hls_stream = playback_url.hls;
          let playback_webm_stream = playback_url.webm;
          playback_mpjpeg_stream = playback_url.mpjpeg;

          if (mp4_stream || hls_stream || mpjpeg_stream || webm_stream) {
            has_stream = true;
            $this.videoURLs.mp4 = mp4_stream ? mp4_stream : null;
            $this.videoURLs.hls = hls_stream ? hls_stream : null;
            $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
            $this.videoURLs.webm = webm_stream ? webm_stream : null;

            $this.playbackVideoURLs.mp4 = playback_mp4_stream
              ? playback_mp4_stream
              : null;
            $this.playbackVideoURLs.hls = playback_hls_stream
              ? playback_hls_stream
              : null;
            $this.playbackVideoURLs.mpjpeg = playback_mpjpeg_stream
              ? playback_mpjpeg_stream
              : null;
            $this.playbackVideoURLs.webm = playback_webm_stream
              ? playback_webm_stream
              : null;
          }
        } else {
          //
          has_stream = false;
        }

        // console.log("has_stream");
        // console.log(has_stream);

        if (has_stream == false) {
          // console.log("No camera stream available");
          // $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                // console.log("camera_result");
                // console.log(camera_result);

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.deviceObj.ip) {
                      camera_auth_key = camera_data["details"]["authkey"];
                      break;
                    }
                  }
                }

                if (camera_auth_key != null) {
                  break;
                }
              }

              if (camera_auth_key != null) {
                $this.videoAuth = camera_auth_key;
              }

              if (has_playback) {
                $this.loadPlaybackPlayer();
              } else if (has_playback == false) {
                $this.playbackVideoURL = null;
              }
              $this.loadLivePlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        }
      }
    },
    loadPlaybackPlayer: function () {
      var $this = this;
      let video_url = null;
      if ($this.playbackVideoFormat == "mp4") {
        video_url = $this.playbackVideoURLs.mp4;
      } else if ($this.playbackVideoFormat == "hls") {
        video_url = $this.playbackVideoURLs.hls;
      } else if ($this.playbackVideoFormat == "mpjpeg") {
        video_url = $this.playbackVideoURLs.mpjpeg;
      } else if ($this.playbackVideoFormat == "webm") {
        video_url = $this.playbackVideoURLs.webm;
      }
      console.log($this.createdAt);
      var seconds_before = 10;
      var seconds_after = 10;
      // TODO: test this
      var start_time = moment($this.createdAt)
        .subtract(seconds_before, "seconds")
        .valueOf();
      var end_time = moment($this.createdAt)
        .add(seconds_after, "seconds")
        .valueOf();

      $this.resetPlaybackPlayer();

      try {
        let playback_stream_url = new URL(video_url);
        let playback_video;
        let playback_video_source;
        let playback_player;

        if ($this.deviceObj.monitor_type === "DIGIFORT") {
          if ($this.videoAuthSession && $this.videoAuthData) {
            var start_datetime = moment($this.createdAt).subtract(
              10,
              "seconds"
            );
            var end_datetime = moment($this.createdAt).add(10, "seconds");
            var start_date = moment(start_datetime).format("YYYY.MM.DD");
            var end_date = moment(end_datetime).format("YYYY.MM.DD");
            start_time = moment(start_datetime).format("HH.mm.ss");
            end_time = moment(end_datetime).format("HH.mm.ss");

            playback_stream_url.searchParams.set("StartTime", start_time);
            playback_stream_url.searchParams.set("StartDate", start_date);
            playback_stream_url.searchParams.set("EndTime", end_time);
            playback_stream_url.searchParams.set("EndDate", end_date);
            playback_stream_url.searchParams.set(
              "AuthSession",
              $this.videoAuthSession
            );
            playback_stream_url.searchParams.set(
              "AuthData",
              $this.videoAuthData
            );
          }
        } else {
          if ($this.videoAuth) {
            playback_stream_url.searchParams.set("auth", $this.videoAuth);
            playback_stream_url.searchParams.set("pos", start_time);
            playback_stream_url.searchParams.set("endPos", end_time);
          }
        }

        $this.playbackVideoURL = playback_stream_url.toString();

        if ($this.playbackVideoFormat == "mp4") {
          playback_video = document.createElement("video");
          playback_video.setAttribute("playsinline", "");
          playback_video.setAttribute("controls", "");
          playback_video.setAttribute("crossorigin", "");
          playback_video.setAttribute("muted", "");
          $this.$refs.playbackVideoContainer.appendChild(playback_video);

          playback_video_source = document.createElement("source");
          playback_video_source.setAttribute("src", $this.playbackVideoURL);
          playback_video_source.setAttribute("type", "video/mp4");
          playback_video.appendChild(playback_video_source);

          playback_player = new Plyr(playback_video, {});
          $this.playbackPlayer = playback_player;
        } else if ($this.playbackVideoFormat == "webm") {
          playback_video = document.createElement("video");
          playback_video.setAttribute("playsinline", "");
          playback_video.setAttribute("controls", "");
          playback_video.setAttribute("crossorigin", "");
          playback_video.setAttribute("muted", "");
          $this.$refs.playbackVideoContainer.appendChild(playback_video);

          playback_video_source = document.createElement("source");
          playback_video_source.setAttribute("src", $this.playbackVideoURL);
          playback_video_source.setAttribute("type", "video/webm");
          playback_video.appendChild(playback_video_source);

          playback_player = new Plyr(playback_video, {});
          $this.playbackPlayer = playback_player;
        } else if ($this.playbackVideoFormat == "hls") {
          playback_video = document.createElement("video");
          playback_video.setAttribute("playsinline", "");
          playback_video.setAttribute("controls", "");
          playback_video.setAttribute("crossorigin", "");
          playback_video.setAttribute("muted", "");
          $this.$refs.playbackVideoContainer.appendChild(playback_video);

          const hls2 = new Hls();
          hls2.loadSource($this.playbackVideoURL);
          hls2.attachMedia(playback_video);
          playback_player = new Plyr(playback_video, {});
          $this.playbackPlayer = playback_player;
        } else if ($this.playbackVideoFormat == "mpjpeg") {
          playback_video = document.createElement("img");
          playback_video.classList.add("img-fluid");
          playback_video.setAttribute("src", $this.playbackVideoURL);
          playback_video.setAttribute("alt", "MPJPEG Player");

          $this.$refs.playbackVideoContainer.appendChild(playback_video);

          $this.playbackPlayer = null;
        }
      } catch (err) {
        $this.playbackVideoMessage = "No camera stream available";
      }
    },
    loadLivePlayer: function () {
      var $this = this;
      let video_url = null;
      if ($this.liveVideoFormat == "mp4") {
        video_url = $this.videoURLs.mp4;
      } else if ($this.liveVideoFormat == "hls") {
        video_url = $this.videoURLs.hls;
      } else if ($this.liveVideoFormat == "mpjpeg") {
        video_url = $this.videoURLs.mpjpeg;
      } else if ($this.liveVideoFormat == "webm") {
        video_url = $this.videoURLs.webm;
      }

      $this.resetLivePlayer();

      try {
        let live_stream_url = new URL(video_url);
        if ($this.deviceObj.monitor_type === "DIGIFORT") {
          if ($this.videoAuthSession && $this.videoAuthData) {
            live_stream_url.searchParams.set(
              "AuthSession",
              $this.videoAuthSession
            );
            live_stream_url.searchParams.set("AuthData", $this.videoAuthData);
          }
        } else {
          if ($this.videoAuth) {
            // console.log("$this.videoAuth");
            // console.log($this.videoAuth);
            live_stream_url.searchParams.set("auth", $this.videoAuth);
          }
        }
        $this.liveVideoURL = live_stream_url.toString();

        let live_video;
        let live_video_source;
        let live_player;

        if ($this.liveVideoFormat == "mp4") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/mp4");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.liveVideoFormat == "webm") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/webm");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.liveVideoFormat == "hls") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          const hls1 = new Hls();
          // hls1.loadSource("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8");
          hls1.loadSource($this.liveVideoURL);
          hls1.attachMedia(live_video);
          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.liveVideoFormat == "mpjpeg") {
          // live
          live_video = document.createElement("img");
          live_video.classList.add("img-fluid");
          live_video.setAttribute("src", $this.liveVideoURL);
          live_video.setAttribute("alt", "MPJPEG Player");

          $this.$refs.liveVideoContainer.appendChild(live_video);

          $this.livePlayer = null;
        }
        // console.log(live_player);
      } catch (err) {
        $this.liveVideoMessage = "No camera stream available";
      }
    },
    fetchSimilarEvents: function () {
      var $this = this;
      var alert_filter = {};
      if (this.alertFloor.id != null) {
        alert_filter["floor__id"] = this.alertFloor.id;
      } else {
        alert_filter["device_type"] = this.deviceType;
      }
      var alert_list =
        this.$store.getters["alertWS/filterAlerts"](alert_filter);
      // console.log(alert_list);
      this.similarEvents = [];
      for (var i in alert_list) {
        if (alert_list[i]["id"] != $this.alertID) {
          $this.similarEvents.push(alert_list[i]);
        }
      }

      this.similarEvents
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
        .sort(function (a, b) {
          return parseInt(b.priority) - parseInt(a.priority);
        });
      // console.log("fetchSimilarEvents", this.similarEvents);

      // helper function to get the YYYY-MM-DD from an item
      const date = (item) =>
        moment(item.created, "YYYY-MM-DD").format("YYYY-MM-DD");

      const result = _.groupBy(this.similarEvents, date);

      this.groupedSimilarEvents = result;
    }
  }
};
</script>

<style lang="scss">
@import "./AlertDetails.scss";
</style>
