<template>
  <div class="panel-content">
    <div class="panel-content-header d-flex justify-content-between">
      <h2>{{ panelHeader }}</h2>
      <div class="d-flex justify-content-end" v-if="hasPermission">
        <div class="mr-2">Cameras: {{ cameraCounter }}</div>
        <div>Controllers: {{ controllerCounter }}</div>
      </div>
    </div>
    <div class="panel-content-header" v-if="hasPermission && hideNav == false">
      <FloorNavigator></FloorNavigator>
    </div>

    <div class="panel-content-body scroller" v-if="hasPermission">
      <b-table
        striped
        small
        borderless
        :items="table_items"
        :fields="table_fields"
      >
        <template #cell(items)="data">
          <div v-if="data.value.building_id != undefined">
            <div
              class="floor-link"
              @click="goToFloor(data.value.building_id, data.value.floor_id)"
            >
              <strong>{{ data.value.first }}</strong>
            </div>
            <div>{{ data.value.last }}</div>
          </div>
          <div v-else>
            <div
              class="floor-link"
              @click="goToDevice(data.value.device_ip, data.value.device_type)"
            >
              <i
                class="fa fa-video mr-2"
                v-if="data.value.device_type == 'camera'"
              ></i>
              <i
                class="fa fa-copyright mr-2"
                v-else-if="data.value.device_type == 'controller'"
              ></i>
              <strong>{{ data.value.device_display }}</strong>
            </div>
          </div>
        </template>
        <template #cell(cameras)="data">
          <div class="text-center">
            {{ data.value.first }}<br />{{ data.value.last }}
          </div>
        </template>
        <template #cell(controllers)="data">
          <div class="text-center">
            {{ data.value.first }}<br />{{ data.value.last }}
          </div>
        </template>
        <template #cell(status)="data">
          <div v-if="data.value == 1" class="text-success">Online</div>
          <div v-else-if="data.value == 0">Offline</div>
        </template>
      </b-table>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import FloorNavigator from "@/components/FloorNavigator/FloorNavigator";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelDevices",
  components: {
    FloorNavigator
  },
  data() {
    return {
      table_fields: [],
      table_items: [],
      building: null,
      floor: null,
      isDevicesInit: false,
      isLocationInit: false,
      panelHeader: "Devices Overview",
      cameraCounter: 0,
      controllerCounter: 0,
      hideNav: false,
      hasPermission: false
    };
  },
  computed: {
    ...mapGetters({
      isDevicesInitiated: "psim/isDevicesInitiated",
      getViewingLocation: "psim/getViewingLocation",
      getSelectedWorkspace: "psim/getSelectedWorkspace",
      getCameras: "device/getCameras",
      getControllers: "device/getControllers",
      getCurrentUser: "session/getCurrentUser"
    }),
    isPanelInitiated: function () {
      if (this.isDevicesInit == true && this.isLocationInit == true) {
        // console.log("isPanelInitiated", true)
        return true;
      }
      // console.log("isPanelInitiated", false)
      return false;
    }
  },
  watch: {
    isDevicesInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.isDevicesInit = true;
        }
      }
    },
    getViewingLocation: {
      handler: function (n, o) {
        // console.log(n);
        if (n != null) {
          this.isLocationInit = true;
          this.building = n.building;
          this.floor = n.floor;

          if (this.isDevicesInitiated) {
            this.fetchDevices();
          }
        }
      },
      deep: true
    },
    isPanelInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.fetchDevices();
        }
      }
    },
    getSelectedWorkspace: {
      handler: function (n, o) {
        if (n != null) {
          this.hideNav = false;
          for (var i in n.layout) {
            if (n.layout[i].panel == "floorplan") {
              this.hideNav = true;
              break;
            }
          }
        }
      }
    },
    getCameras: {
      handler: function (n, o) {
        this.fetchDevices();
      },
      deep: true
    },
    getControllers: {
      handler: function (n, o) {
        this.fetchDevices();
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("devices") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Panel Devices mounted");

    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("devices") >= 0) {
      this.hasPermission = true;
    }

    // init data
    if (this.getViewingLocation != null && this.isDevicesInitiated == true) {
      this.building = this.getViewingLocation.building;
      this.floor = this.getViewingLocation.floor;
      this.fetchDevices();
    }

    if (this.getSelectedWorkspace != null) {
      this.hideNav = false;
      for (var i in this.getSelectedWorkspace.layout) {
        if (this.getSelectedWorkspace.layout[i].panel == "floorplan") {
          this.hideNav = true;
          break;
        }
      }
    }
  },
  methods: {
    fetchDevices: function () {
      var building_list = [];
      var mode = null;
      if (this.building != null) {
        building_list = [this.building];
        mode = "building";

        if (this.floor != null) {
          mode = "floor";
        }
      } else {
        building_list = this.$store.getters["psim/getBuildings"]();
        mode = "building_list";
      }
      // console.log("Fetch Devices Overview - mode", mode);

      var devices_list = [];
      for (var b in building_list) {
        var building_obj = building_list[b];

        if (mode == "floor") {
          var floor_obj_filter = {
            building__id: building_obj.id,
            floor__id: this.floor.id
          };
          var floor_cameras =
            this.$store.getters["device/filterCameras"](floor_obj_filter);
          var floor_controllers =
            this.$store.getters["device/filterControllers"](floor_obj_filter);
          this.cameraCounter = floor_cameras.length;
          this.controllerCounter = floor_controllers.length;
          this.panelHeader = "Devices Overview - " + this.floor.name;

          for (var c in floor_cameras) {
            let camera_display;
            if (floor_cameras[c].ip == floor_cameras[c].name) {
              camera_display = floor_cameras[c].ip;
            } else {
              camera_display =
                floor_cameras[c].ip + " (" + floor_cameras[c].name + ")";
            }
            var camera_obj_data = {
              items: {
                device_ip: floor_cameras[c].ip,
                device_type: "camera",
                device_display: camera_display
              },
              status: floor_cameras[c].status
            };
            if (floor_cameras[c].status == 0) {
              camera_obj_data["_rowVariant"] = "danger";
            }
            devices_list.push(camera_obj_data);
          }

          for (var d in floor_controllers) {
            let controller_display;
            if (floor_controllers[d].ip == floor_controllers[d].name) {
              controller_display = floor_controllers[d].ip;
            } else {
              controller_display =
                floor_controllers[d].ip +
                " (" +
                floor_controllers[d].name +
                ")";
            }
            var controller_obj_data = {
              items: {
                device_ip: floor_controllers[d].ip,
                device_type: "controller",
                device_display: controller_display
              },
              status: floor_controllers[d].status
            };
            if (floor_controllers[d].status == 0) {
              controller_obj_data["_rowVariant"] = "danger";
            }
            devices_list.push(controller_obj_data);
          }

          this.table_items = devices_list;
          this.table_fields = [
            {
              key: "items",
              label: "Devices",
              sortable: false
            },
            {
              key: "status",
              label: "Status",
              sortable: false,
              thClass: "text-center",
              tdClass: "text-center"
            }
          ];
        } else if (mode == "building") {
          var floors = building_obj.floors;
          this.panelHeader = "Devices Overview - " + building_obj.name;
          this.cameraCounter = 0;
          this.controllerCounter = 0;

          for (var f in floors) {
            var floor = floors[f];
            var floor_filter = {
              building__id: building_obj.id,
              floor__id: floor.id
            };
            var floor_offline_filter = {
              building__id: building_obj.id,
              floor__id: floor.id,
              status: 0
            };
            var floor_cameras_total =
              this.$store.getters["device/filterCameras"](floor_filter).length;
            var floor_cameras_offline =
              this.$store.getters["device/filterCameras"](
                floor_offline_filter
              ).length;
            var floor_controllers_total =
              this.$store.getters["device/filterControllers"](
                floor_filter
              ).length;
            var floor_controllers_offline =
              this.$store.getters["device/filterControllers"](
                floor_offline_filter
              ).length;

            this.cameraCounter += floor_cameras_total;
            this.controllerCounter += floor_controllers_total;

            var floor_data = {
              items: {
                first: floor.name,
                last: "Offline",
                floor_id: floor.id,
                building_id: building_obj.id
              },
              cameras: {
                first: floor_cameras_total,
                last: floor_cameras_offline
              },
              controllers: {
                first: floor_controllers_total,
                last: floor_controllers_offline
              }
            };
            if (floor_cameras_offline > 0 || floor_controllers_offline > 0) {
              floor_data["_rowVariant"] = "danger";
            }
            devices_list.push(floor_data);
          }

          this.table_items = devices_list;
          this.table_fields = [
            {
              key: "items",
              label: "Floors",
              sortable: false
            },
            {
              key: "cameras",
              label: "Cameras",
              sortable: false,
              thClass: "text-center"
            },
            {
              key: "controllers",
              label: "Controllers",
              sortable: false,
              thClass: "text-center"
            }
          ];
        } else if (mode == "building_list") {
          this.panelHeader = "Devices Overview - All buildings";
          var building_filter = { building__id: building_obj.id };
          var building_offline_filter = {
            building__id: building_obj.id,
            status: 0
          };
          var building_cameras_total =
            this.$store.getters["device/filterCameras"](building_filter).length;
          var building_cameras_offline = this.$store.getters[
            "device/filterCameras"
          ](building_offline_filter).length;
          var building_controllers_total =
            this.$store.getters["device/filterControllers"](
              building_filter
            ).length;
          var building_controllers_offline = this.$store.getters[
            "device/filterControllers"
          ](building_offline_filter).length;

          this.cameraCounter = building_cameras_total;
          this.controllerCounter = building_controllers_total;

          var building_data = {
            items: {
              first: building_obj.name,
              last: "Offline",
              building_id: building_obj.id
            },
            cameras: {
              first: building_cameras_total,
              last: building_cameras_offline
            },
            controllers: {
              first: building_controllers_total,
              last: building_controllers_offline
            }
          };
          if (
            building_cameras_offline > 0 ||
            building_controllers_offline > 0
          ) {
            building_data["_rowVariant"] = "danger";
          }
          devices_list.push(building_data);

          this.table_items = devices_list;
          this.table_fields = [
            {
              key: "items",
              label: "Buildings",
              sortable: false
            },
            {
              key: "cameras",
              label: "Cameras",
              sortable: false,
              thClass: "text-center"
            },
            {
              key: "controllers",
              label: "Controllers",
              sortable: false,
              thClass: "text-center"
            }
          ];
        }
      }
      // end fetchDevices
    },
    goToFloor: function (building_id, floor_id) {
      if (floor_id == undefined) {
        this.$router
          .push({ name: "PSIM", query: { building: building_id } })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "PSIM",
            query: { building: building_id, floor: floor_id }
          })
          .catch(() => {});
      }
    },
    goToDevice: function (device_ip, device_type) {
      var device = null;
      if (device_type == "camera") {
        const device_obj_list = this.$store.getters["device/filterCameras"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device = device_obj_list[0];
        }
      } else if (device_type == "controller") {
        const device_obj_list = this.$store.getters["device/filterControllers"](
          { ip: device_ip }
        );
        if (device_obj_list.length > 0) {
          device = device_obj_list[0];
        }
      }

      if (device != null) {
        var url_query_dict = {};
        var url_name = "PSIM";
        if (device.building != null) {
          url_query_dict["building"] = device.building.id;

          if (device.floor != null) {
            url_query_dict["floor"] = device.floor.id;

            if (device.zone != null) {
              url_query_dict["zone"] = device.zone.id;
            }
          }
        }

        url_query_dict["device_id"] = encodeURI(device.id);

        this.$store.dispatch("psim/setViewingDetails", {
          type: device.type,
          data: device
        });

        this.$router
          .push({ name: url_name, query: url_query_dict })
          .catch(() => {});

        this.$store.dispatch("psim/setLocatingAlert", null);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./PanelDevices.scss";
</style>
