<template>
  <div class="device" :style="device_data.current_coords" ref="device">
    <div
      class="device-icon device-door text-center w-100 h-100"
      :class="[
        device.status == 1 ? onlineClass : offlineClass,
        highlightDevice ? highlightClass : ''
      ]"
      @click="doTogglePopup(device.id)"
    >
      <!--            <i class="fa fa-door-closed w-100"></i>-->
      <i
        class="fa fa-door-closed w-100"
        :style="[device_data.icon_styles ? device_data.icon_styles : '']"
      ></i>
    </div>

    <div
      class="device-notification"
      v-show="showNotification"
      ref="deviceNotification"
      :style="deviceNotificationStyle"
    >
      <div class="d-flex justify-content-center">
        <div v-show="hasAlert">
          <div class="device-notification-counter d-flex mr-2">
            <div><i class="fa fa-exclamation-circle mr-1"></i></div>
            <div>{{ alert_count }}</div>
          </div>
        </div>
        <div v-show="hasCase">
          <div class="device-notification-counter d-flex">
            <div><i class="fa fa-clipboard mr-1"></i></div>
            <div>{{ case_count }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="device-popup"
      v-show="showPopup"
      ref="devicePopup"
      :style="devicePopupStyle"
    >
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="w-100 mr-2">
          <h2 class="mb-0 mr-2">{{ device.name }}</h2>
          <DeviceStatus :status="device.status"></DeviceStatus>
        </div>
        <b-button
          size="sm"
          variant="secondary"
          @click="doTogglePopup(device.id)"
          ><i class="fa fa-times"></i
        ></b-button>
      </div>

      <div class="mb-2">
        <h3 class="mb-0">Information</h3>
        <div>IP: {{ device.ip }}</div>
        <div>Model: {{ deviceModel }}</div>
      </div>

      <div class="d-flex mb-2">
        <div class="d-flex mr-4">
          <span class="text-counter"
            ><i class="fa fa-exclamation-circle mr-1"></i
          ></span>
          <div>
            <div class="alert-counter">{{ alert_count }}</div>
            <div class="alert-subtitle">Alerts</div>
          </div>
        </div>
        <div class="d-flex">
          <span class="text-counter"><i class="fa fa-clipboard mr-1"></i></span>
          <div>
            <div class="case-counter">{{ case_count }}</div>
            <div class="case-subtitle">Cases</div>
          </div>
        </div>
      </div>

      <div>
        <b-button size="sm" variant="secondary" @click="doShowDetails"
          >View Details</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceStatus from "@/components/FloorplanDevices/DeviceStatus/DeviceStatus";

export default {
  name: "DeviceCamera",
  components: {
    DeviceStatus
  },
  props: {
    device: {
      type: Object,
      required: true
    },
    containerDimensions: {
      type: Object,
      required: false
    }
  },
  data: function () {
    return {
      showNotification: true,
      showPopup: false,
      hasAlert: false,
      hasCase: false,
      highlightDevice: false,
      onlineClass: "device-online",
      offlineClass: "device-offline",
      highlightClass: "device-highlight",
      alert_count: 0,
      case_count: 0,
      device_data: this.device,
      devicePopupStyle: {},
      deviceNotificationStyle: {},
      deviceModel: "",
      controller: {}
    };
  },
  computed: {
    ...mapGetters({
      _getViewingPopup: "device/getViewingPopup",
      _getPSIMViewingDetails: "psim/getViewingDetails",
      _getViewingLocation: "psim/getViewingLocation",
      getAlertIDs: "alertWS/getAlertIDs"
    })
  },
  watch: {
    device: {
      handler: function (n, o) {
        this.updateNotificationState();
      },
      deep: true
    },
    containerDimensions: {
      handler: function (n, o) {
        this.updateCoordinates();
      },
      deep: true
    },
    _getViewingPopup: {
      handler: function (n, o) {
        if (n != null && n.ip != this.device.ip && n.type != this.device.type) {
          this.doHidePopup();
        } else if (n == null) {
          this.doHidePopup();
        }
      },
      deep: true
    },
    _getViewingLocation: {
      handler: function (n, o) {
        if (n != null && n.device_id == this.device.id) {
          this.highlightDevice = true;
        } else {
          this.highlightDevice = false;
        }
      },
      deep: true
    },
    getAlertIDs: {
      handler: function (n, o) {
        this.getAlerts();
        this.updateNotificationState();
      },
      deep: true
    }
  },
  mounted: function () {
    this.getAlerts();
    this.getCases();
    this.updateNotificationState();
    this.updateCoordinates();
    if (this._getViewingLocation.device_id == this.device.id) {
      this.highlightDevice = true;
    }
    let controller_data_array = this.device.id.split("_");
    this.controller = this.$store.getters["device/getController"](
      controller_data_array[0]
    );
    this.deviceModel = this.controller.data.details.controller_model;
  },
  methods: {
    getAlerts: function () {
      var device_alerts =
        this.$store.getters["psim/getDeviceAlerts"][this.device.id];
      if (device_alerts != undefined) {
        this.alert_count = device_alerts.length;
      }
    },
    getCases: function () {
      var device_cases =
        this.$store.getters["psim/getDeviceCases"][this.device.id];
      if (device_cases != undefined) {
        this.case_count = device_cases.length;
      }
    },
    updateNotificationState: function () {
      if (this.alert_count > 0 || this.case_count > 0) {
        this.showNotification = true;

        if (this.alert_count > 0) {
          this.hasAlert = true;
        } else {
          this.hasAlert = false;
        }

        if (this.case_count > 0) {
          this.hasCase = true;
        } else {
          this.hasCase = false;
        }
      } else {
        this.showNotification = false;
      }
    },
    updateCoordinates: function () {
      var $this = this;
      var deviceCoords = $this.device.coords;

      if (deviceCoords == undefined) {
        return null;
      }
      var $floorPlanImageContainer =
        document.getElementsByClassName("floorplan-image")[0];

      if (
        !$floorPlanImageContainer ||
        $floorPlanImageContainer == undefined ||
        $floorPlanImageContainer == null
      ) {
        return null;
      }

      var original_coords = {};
      var current_coords = {};
      current_coords.width =
        $floorPlanImageContainer.getBoundingClientRect().width;
      current_coords.height =
        $floorPlanImageContainer.getBoundingClientRect().height;

      original_coords.width = deviceCoords["width"];
      original_coords.height = deviceCoords["height"];
      original_coords.left = deviceCoords["left"];
      original_coords.top = deviceCoords["top"];
      original_coords.icon_width = deviceCoords["icon_width"];
      original_coords.icon_height = deviceCoords["icon_height"];
      original_coords.rotate = deviceCoords["rotate"];
      current_coords.left =
        (original_coords.left / original_coords.width) * current_coords.width;
      current_coords.top =
        (original_coords.top / original_coords.height) * current_coords.height;
      current_coords.icon_width =
        (original_coords.icon_width / original_coords.width) *
        current_coords.width;
      current_coords.icon_height =
        (original_coords.icon_height / original_coords.height) *
        current_coords.height;

      var rotation = String(original_coords.rotate);
      rotation = rotation.replace("rotate(", "");
      rotation = rotation.replace("deg)", "");

      let posX = 0;
      let posY = 0;
      posX =
        ((current_coords.left + original_coords.icon_width / 2) /
          $this.containerDimensions.currentWidth) *
        100;
      posY =
        ((current_coords.top + original_coords.icon_height / 2) /
          $this.containerDimensions.currentHeight) *
        100;

      var finalCoords = {
        left: current_coords.left,
        top: current_coords.top,
        width: current_coords.icon_width,
        height: current_coords.icon_height,
        icon_width: current_coords.icon_width,
        icon_height: current_coords.icon_height,
        rotate: rotation,
        posX: posX,
        posY: posY
      };

      finalCoords["left"] += "px";
      finalCoords["top"] += "px";
      finalCoords["width"] = finalCoords["icon_width"] + "px";
      finalCoords["height"] = finalCoords["icon_height"] + "px";
      // finalCoords['transform'] = "rotate(" + finalCoords['rotate'] + "deg)";
      finalCoords["position"] = "absolute";

      $this.device_data.current_coords = finalCoords;
      $this.device_data.icon_styles = {
        "font-size": finalCoords["icon_width"] * 0.4 + "px",
        transform: "rotate(" + finalCoords["rotate"] + "deg)"
      };

      $this.updatePopupCoordinates(finalCoords);
      $this.updateNotificationCoordinates(finalCoords);
      // this.$refs.device.style = finalCoords;
      // return finalCoords;
    },
    updatePopupCoordinates: function (finalCoords) {
      var popupStyle = getComputedStyle(this.$refs.devicePopup);
      var popupWidth = popupStyle.getPropertyValue("width");
      var popupWidthValue = parseFloat(popupWidth.replace("px", ""));

      var popupMarginLeft = popupStyle.getPropertyValue("margin-left");
      var popupMarginLeftValue = parseFloat(popupMarginLeft.replace("px", ""));

      var popupTop = popupStyle.getPropertyValue("top");
      var popupTopValue = parseFloat(popupTop.replace("px", ""));

      var popupLeftValue = -(popupWidthValue / 2);
      var newPopupLeftValue =
        popupLeftValue + finalCoords.icon_width / 2 - popupMarginLeftValue;
      var newPopupLeft = newPopupLeftValue + "px";

      var newPopupTopValue = finalCoords.icon_height + 15;
      var newPopupTop = newPopupTopValue + "px";

      this.devicePopupStyle = { left: newPopupLeft, top: newPopupTop };
    },
    updateNotificationCoordinates: function (finalCoords) {
      var notificationStyle = getComputedStyle(this.$refs.deviceNotification);
      var notificationWidth = notificationStyle.getPropertyValue("width");
      var notificationWidthValue = parseFloat(
        notificationWidth.replace("px", "")
      );

      var notificationMarginLeft =
        notificationStyle.getPropertyValue("margin-left");
      var notificationMarginLeftValue = parseFloat(
        notificationMarginLeft.replace("px", "")
      );

      var notificationTop = notificationStyle.getPropertyValue("top");
      var notificationTopValue = parseFloat(notificationTop.replace("px", ""));

      var notificationLeftValue = -(notificationWidthValue / 2);
      var newNotificationLeftValue =
        notificationLeftValue +
        finalCoords.icon_width / 2 -
        notificationMarginLeftValue;
      var newNotificationLeft = newNotificationLeftValue + "px";

      var newNotificationTopValue = notificationTopValue;
      var newNotificationTop = newNotificationTopValue + "px";

      this.deviceNotificationStyle = {
        left: newNotificationLeft,
        top: newNotificationTop
      };
    },
    doTogglePopup: function (device_id) {
      this.showPopup = !this.showPopup;

      if (this.showPopup) {
        this.$store.dispatch("device/setViewingPopup", {
          type: "door",
          id: device_id
        });
      } else {
        this.$store.dispatch("device/setViewingPopup", null);
      }
    },
    doHidePopup: function () {
      this.showPopup = false;
    },
    doShowDetails: function () {
      var currently_viewed = this._getPSIMViewingDetails;
      var url_query_dict = {};
      var url_name = "PSIM";
      if (this.device.building != null) {
        url_query_dict["building"] = this.device.building.id;

        if (this.device.floor != null) {
          url_query_dict["floor"] = this.device.floor.id;

          if (this.device.zone != null) {
            url_query_dict["zone"] = this.device.zone.id;
          }
        }
      }

      if (this.device.current_coords != undefined) {
        if (
          this.device.current_coords.posX != undefined &&
          this.device.current_coords.posX != null
        ) {
          var pos_x = this.device.current_coords.posX;
          url_query_dict["pos_x"] = pos_x;
        }
        if (
          this.device.current_coords.posY != undefined &&
          this.device.current_coords.posY != null
        ) {
          var pos_y = this.device.current_coords.posY;
          url_query_dict["pos_y"] = pos_y;
        }
      }

      if (
        currently_viewed != null &&
        currently_viewed.type == "door" &&
        currently_viewed.data.ip == this.device.ip
      ) {
        // If same device, close details panel
        this.$store.dispatch("psim/setViewingDetails", null);
        // this.$store.dispatch('psim/setViewingLocation', null);
        this.$router
          .push({ name: url_name, query: url_query_dict })
          .catch(() => {});

        this.$store.dispatch("psim/setLocatingAlert", null);
      } else {
        let device_obj = this.device;
        device_obj = this.$store.getters["device/getDoor"](this.device.id);

        // console.log("Device Door - device_obj");
        // console.log(device_obj);

        this.$store.dispatch("psim/setViewingDetails", {
          type: "door",
          data: device_obj
        });

        url_query_dict["device_id"] = encodeURI(this.device.id);
        this.$router
          .push({ name: url_name, query: url_query_dict })
          .catch(() => {});

        this.$store.dispatch("psim/setLocatingAlert", null);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./DeviceBase.scss";
</style>
