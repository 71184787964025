<template>
  <div class="alert-card" :data-priority="priority" v-if="isShow">
    <!--    <div class="lpr-alert-card-label"></div>-->
    <div class="alert-card-content">
      <div class="alert-card-header">
        <div class="pl-2">
          <i class="fa fa-car fa-lg mr-1"></i>
          <strong>{{ alert.metadata.license_plate }}</strong>
        </div>
        <div class="d-flex justify-content-end mr-2">
          <strong>{{ getFormatedDateTime(alert.created) }}</strong>
        </div>
      </div>
      <div class="alert-card-info" @click="viewAlertDetails(alert)">
        <div class="alert-card-image" style="cursor: pointer">
          <div v-if="staffObj != null">
            <img
              v-if="staffObj.person_photo"
              :src="`data:image/png;base64,${staffObj.person_photo}`"
            />
            <img v-else src="@/assets/global/images/icons/profile_sample.jpg" />
          </div>
          <div v-else>
            <img src="@/assets/global/images/icons/profile_sample.jpg" />
          </div>
        </div>
        <div class="alert-card-details">
          <div class="lpr-card-desc mt-3" v-if="staffObj != null">
            <h2>{{ staffObj.person_name | filterUnknownPersonName }}</h2>
            <i class="fa fa-id-badge"></i>
            {{ staffObj.person_no | filterUnknownPersonNo }}<br />
          </div>

          <div
            class="lpr-card-desc"
            v-if="
              alert.metadata.make != null && alert.metadata.make != 'Unknown'
            "
          >
            <i class="fa fa-car fa-lg mr-1"></i>
            <strong>{{ alert.metadata.make }}</strong> <br />
          </div>

          <div class="lpr-card-desc" v-if="alert.building.name != null">
            <i class="fa fa-video" v-if="alert.device_type == 'camera'"></i>
            {{ alert.device_name }}
          </div>
          <div v-else>
            <i class="fa fa-video" v-if="alert.device_type == 'camera'"></i>
            {{ alert.metadata.camera_ip }}<br />
          </div>
        </div>
      </div>

      <div class="alert-card-actions">
        <div class="pl-2">
          <i class="fa fa-clock mr-1"></i>
          <time-since :date="alert.created"></time-since>
        </div>
        <div class="d-flex justify-content-end"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  props: {
    alert: {
      type: Object,
      required: true
    },
    panel: {
      type: String,
      required: true
    }
  },
  data: function () {
    var alert_priority = this.alert.priority;
    return {
      locatingAlert: false,
      isShow: false,
      sopOptions: [],
      slaOptions: [],
      priorityOptions: [
        { value: "1", text: "Low" },
        { value: "2", text: "Normal" },
        { value: "3", text: "High" }
      ],
      form: {
        sop: null,
        sla: null,
        priority: alert_priority
      },
      selectedSOP: null,
      selectedSLA: null,
      staffObj: null,
      priority: 1
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: async function () {
    let $this = this;

    await $this
      .getStaffDetails(this.alert.metadata.license_plate)
      .then((staffDetail) => {
        // console.log("staffDetail in Card");
        // console.log(staffDetail);
        this.staffObj = staffDetail;
        this.isShow = true;
      });
  },
  filters: {
    filterUnknownPersonName(value) {
      return value ? value : "Unknown";
    },
    filterUnknownPersonNo(value) {
      return value ? value : "N/A";
    }
  },
  methods: {
    goToLocation: function (device_type, device_ip) {
      let device_obj = null;
      if (device_type == "camera") {
        const device_obj_list = this.$store.getters["device/filterCameras"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      } else if (device_type == "door") {
        const device_obj_list = this.$store.getters["device/filterDoors"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      }

      var url_name = this.$route.name;
      var url_params = this.$route.params;
      var url_query_dict = this.$route.query;

      if (!this.locatingAlert) {
        if (device_obj != null) {
          url_query_dict = {};
          url_name = "PSIM";
          if (device_obj.building != null) {
            url_query_dict["building"] = device_obj.building.id;

            if (device_obj.floor != null) {
              url_query_dict["floor"] = device_obj.floor.id;

              if (device_obj.zone != null) {
                url_query_dict["zone"] = device_obj.zone.id;
              }
            }
          }

          url_query_dict["device_id"] = encodeURI(device_obj.id);
        }

        this.$store.dispatch("psim/setLocatingAlert", null);
        this.$store.dispatch("psim/setLocatingAlert", this.alert.id);

        this.$router
          .push({ name: url_name, query: url_query_dict })
          .catch(() => {});
      } else {
        this.$store.dispatch("psim/setLocatingAlert", null);
        this.$router
          .push({ name: url_name, params: url_params, query: url_query_dict })
          .catch(() => {});
      }
    },
    goToDevice: function (device_type, device_ip) {
      let device_obj = null;
      if (device_type == "camera") {
        const device_obj_list = this.$store.getters["device/filterCameras"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      } else if (device_type == "door") {
        const device_obj_list = this.$store.getters["device/filterDoors"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      }

      if (device_obj != null) {
        this.$store.dispatch("psim/setViewingDetails", {
          type: device_type,
          data: device_obj
        });
        this.goToLocation(device_type, device_ip);
      }
    },
    viewAlertDetails: function (alert) {
      this.$store.dispatch("psim/setViewingDetails", {
        type: "lpr_alert",
        data: this.alert
      });
      this.goToLocation(alert.device_type, alert.device_ip);
    },
    confirmDiscardAlert: function (alert) {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm discarding this alert?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.$store.dispatch("alertWS/discardAlert", alert.id);
            $this.$store.dispatch("psim/discardAlert", alert.id);
            $this.$emit("discard-alert", alert);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    async getStaffDetails(license_plate) {
      let $this = this;
      let staffObj = null;
      let API_URL =
        $this.getAPIServerURL +
        "/api/acsservice/person/?car_plate_no=" +
        license_plate;
      const client = $this.$root.getAjaxFetchClient();
      await client
        .getRequest(API_URL)
        .then((data) => {
          if (data !== undefined) {
            for (let key in data) {
              if (data[key] != null && data[key] != undefined) {
                for (let value in data[key]) {
                  for (let staffDetail in data[key][value]) {
                    if (typeof data[key][value][staffDetail] === "object") {
                      staffObj = data[key][value][staffDetail];
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });

      return staffObj;
    },

    getFormatedDateTime(datetime) {
      var old_datetime = new Date(datetime);
      return old_datetime.toLocaleTimeString("en-GB");
    }
  }
};
</script>

<style lang="scss">
@import "./LprCardAlert.scss";
</style>
