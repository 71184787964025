<template>
  <div class="panel-content">
    <div
      class="panel-content-header d-flex align-items-center justify-content-between"
    >
      <h2>LPR Real-time Alerts with User Details</h2>
      <div v-if="hasPermission" class="mt-2 mb-2">
        <div class="d-inline-flex p-2">
          <div class="camera-multi-select">
            <multiselect
              v-model="filteredCameraEvent"
              :options="camera_list"
              :multiple="true"
              :taggable="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              placeholder="Choose Camera"
              label="name"
              track-by="name"
              @close="filterCameraForEvent"
            >
              <template slot="tag">{{ "" }}</template>
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} camera(s) selected</span
                >
              </template>
            </multiselect>
          </div>
          <b-button
            size="sm"
            v-b-tooltip.hover.bottom
            title="Save Camera"
            variant="success"
            @click="onSubmitFormAddDevice"
          >
            <i class="fa fa-save mr-1"></i>Save
          </b-button>
        </div>
      </div>
    </div>

    <div class="panel-content-body scroller" v-if="hasPermission">
      <LprCardAlert
        v-for="alert in alerts"
        :key="alert.id"
        :alert="alert"
        :panel="currentPanelName"
        @discard-alert="discardAlertHandler"
      />
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LprCardAlert from "@/components/LprCardAlert/LprCardAlert.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelLprStaffEventCard",
  components: {
    LprCardAlert,
    Multiselect
  },
  props: {
    item: {},
    workspaceId: {}
  },
  data() {
    return {
      panel_info: this.item,
      workspaceID: this.workspaceId,

      alerts: [],
      all_lpr_alerts: [],
      availableFilters: [],
      currentFilters: {},
      selectedFilters: null,
      showFilters: false,
      currentFiltersText: "",
      availableSort: [],
      currentSort: {},
      selectedSort: null,
      showSort: false,
      currentSortText: "",
      getImageQueue: [],
      hasPermission: false,
      currentPanelName: "",
      camera_list: [],
      filteredCameraEvent: null,
      filteredPreferencesCameraEvent: [],
      selectedCameraEvent: null,
      searchType: "camera",
      currentUser: null,
      currentUserWorkspaces: null,
      currentUserWorkspaceID: null,
      currentUserPanel: null,
      currentUserPanelNum: null,
      preferences: {},
      formUpdate: {
        preferences: {}
      },
      merged: {
        workspaces: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      isCamerasInitiated: "psim/isCamerasInitiated",
      isAlertsInitiated: "psim/isAlertsInitiated",
      getAlertIDs: "alertWS/getAlertIDs",
      getDiscardedAlertIDs: "alertWS/getDiscardedAlertIDs",
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    // currentFilters: {
    //   handler: function (n, o) {
    //     // get alerts from store
    //     console.log("Panel Alerts - currentFilters changed");
    //     var alert_list = this.$store.getters["alertWS/filterAlerts"](n);
    //     this.alerts = alert_list;
    //   },
    //   deep: true
    // },
    // isCamerasInitiated: {
    //   handler: function (n, o) {
    //     if (n == true) {
    //       this.setFilters();
    //     }
    //   },
    //   deep: true
    // },
    getAlertIDs: {
      handler: function (n, o) {
        var latest_alert_id = n[n.length - 1];
        var alert_data =
          this.$store.getters["alertWS/getAlert"](latest_alert_id);

        if (alert_data != undefined && alert_data != null) {
          this.createAlert(alert_data);
          this.renderAlerts();
        }
      },
      deep: true
    },
    getDiscardedAlertIDs: {
      handler: function (n, o) {
        // console.log(n, "vs", o)
        this.checkDiscardedAlerts(n);
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;

        if (allowed_panels.indexOf("lpr_staff_alerts") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    /* var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user.panels;

    if (allowed_panels.indexOf("lpr_staff_alerts") >= 0) {
      this.hasPermission = true;
      this.currentPanelName = "lpr_staff_alerts";
      this.setFilters();
    } */

    this.currentUser = this.$store.getters["session/getCurrentUser"];
    if (
      this.currentUser.preferences === null ||
      Object.keys(this.currentUser.preferences).length === 0
    ) {
      //
      if (this.currentUser.panels.indexOf("lpr_staff_alerts") >= 0) {
        this.hasPermission = true;
        this.currentPanelName = "lpr_staff_alerts";
        this.setFilters();
      }
    } else {
      //
      this.getCurrentUserPreferences();
    }
  },
  methods: {
    getCurrentUserPreferences() {
      this.currentUserWorkspaces = this.currentUser.preferences.workspaces;

      for (let workspaceID in this.currentUserWorkspaces) {
        if (workspaceID == this.workspaceID) {
          this.currentUserWorkspaceID = workspaceID;
        }
      }

      if (this.currentUserWorkspaceID != null) {
        for (let panel in this.currentUserWorkspaces[
          this.currentUserWorkspaceID
        ].custom) {
          if (panel == "lpr_staff_alerts") {
            this.currentUserPanel = panel;
          }
        }

        if (this.currentUserPanel != null) {
          for (let num in this.currentUserWorkspaces[
            this.currentUserWorkspaceID
          ].custom[this.currentUserPanel]) {
            if (num == this.panel_info.num) {
              this.currentUserPanelNum = num;
            }
          }
        }

        if (this.currentUserPanelNum != null) {
          for (let key in this.currentUserWorkspaces[
            this.currentUserWorkspaceID
          ].custom[this.currentUserPanel][this.currentUserPanelNum]
            .selected_camera_list) {
            this.filteredPreferencesCameraEvent.push({
              ip: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                .custom[this.currentUserPanel][this.currentUserPanelNum]
                .selected_camera_list[key],
              name: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                .custom[this.currentUserPanel][this.currentUserPanelNum]
                .selected_camera_list[key]
            });
          }

          this.filteredCameraEvent = this.filteredPreferencesCameraEvent;
        }
      }

      if (this.currentUser.panels.indexOf("lpr_staff_alerts") >= 0) {
        this.hasPermission = true;
        this.setFilters();
      }
    },
    searchDevices: function (e) {
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var RESULT_CATEGORY = "";
      var STORE_GETTER = "";
      var DEVICE_TYPE = "";

      $this.camera_list = [];
      // console.log("searchDevices", $this.searchType);
      if ($this.searchType == "camera") {
        RESULT_CATEGORY = "DEV_CAM";
        STORE_GETTER = "psim/getCamera";
        DEVICE_TYPE = "camera";
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((mdata) => {
          let category_result = null;
          let camera_list = [];
          for (var service_url in mdata["result"]) {
            try {
              category_result =
                mdata["result"][service_url]["result"][RESULT_CATEGORY];
            } catch (err) {
              //
            }

            if (category_result != null) {
              for (var i in category_result) {
                var device_url = i;
                var device_data = category_result[i];
                var device_ip = new URL(device_url).host;
                if (device_data.details != undefined) {
                  if (device_data.details.ip != undefined) {
                    device_ip = device_data.details.ip;
                  }
                }

                if ($this.$store.getters[STORE_GETTER](device_ip) == null) {
                  var device_result = {
                    id: device_url,
                    ip: device_ip,
                    type: DEVICE_TYPE,
                    status: device_data["status"],
                    data: {
                      server_url:
                        device_data["monitor_url"] == undefined
                          ? null
                          : device_data["monitor_url"],
                      details:
                        device_data["details"] == undefined
                          ? null
                          : device_data["details"],
                      meta:
                        device_data["meta"] == undefined
                          ? null
                          : device_data["meta"]
                    }
                  };
                  camera_list.push(device_result);
                }
              }
            }
          }

          camera_list.sort(function (a, b) {
            const numA = Number(
              a["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            const numB = Number(
              b["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            return numA - numB;
          });

          for (let key in camera_list) {
            $this.camera_list.push({
              ip: camera_list[key].ip,
              name: camera_list[key].ip
            });
          }
        })
        .catch((err) => {
          // console.log("Error: No ");
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    // ------- Filters --------
    setFilters: function () {
      /* var camera_qs = this.$store.getters["psim/getCameras"]();
      for (var c in camera_qs) {
        this.camera_list.push({ ip: camera_qs[c].ip, name: camera_qs[c].name });
      } */

      this.searchDevices();

      var event_status_list = [
        { id: 1, name: "Access Granted" },
        { id: 2, name: "Access Denied" }
      ];

      this.availableFilters = [
        {
          label: "Start",
          key: "created__gte",
          type: "datetime"
        },
        {
          label: "End",
          key: "created__lte",
          type: "datetime"
        },
        {
          label: "Camera",
          key: "device_list__contains",
          type: "select",
          choices: this.camera_list,
          obj_key: "ip",
          obj_value: "name"
        },
        {
          label: "Event Status",
          key: "event_status_list__contains",
          type: "select",
          choices: event_status_list,
          obj_key: "id",
          obj_value: "name"
        }
      ];

      this.selectedFilters = {
        device_ip: null,
        created__gte: null,
        created__lte: null
      };
    },
    updateFilters: function (filterKey, selectedItem, objectKey, objectValue) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentFilters[filterKey];
      } else {
        if (objectKey == null) {
          this.currentFilters[filterKey] = selectedItem;
        } else {
          this.currentFilters[filterKey] = selectedItem[objectKey];
        }
      }

      var filter_count = Object.keys(this.currentFilters).length;
      if (filter_count > 1) {
        this.currentFiltersText = filter_count + " selected filters";
      } else if (filter_count == 1) {
        if (objectValue == null) {
          this.currentFiltersText = this.selectedFilters[filterKey];
        } else {
          this.currentFiltersText =
            this.selectedFilters[filterKey][objectValue];
        }
      } else if (filter_count < 1) {
        this.currentFiltersText = "";
      }
    },
    startFilter: function () {
      var alert_list = this.$store.getters["alertWS/filterAlerts"](
        this.currentFilters
      );
      this.alerts = alert_list;
      this.showFilters = false;
      this.startSort();
    },
    clearFilter: function () {
      this.currentFilters = {};
      for (var i in this.selectedFilters) {
        this.selectedFilters[i] = null;
      }

      var alert_list = this.$store.getters["alertWS/filterAlerts"](
        this.currentFilters
      );
      this.alerts = alert_list;
      this.showFilters = false;
      this.currentFiltersText = "";
      this.startSort();
    },
    // ------- Sort --------
    fetchSort: function () {
      this.availableSort = [
        {
          label: "Time",
          key: "created",
          type: "select",
          choices: [
            { order: "descending", name: "Latest to oldest" },
            { order: "ascending", name: "Oldest to latest" }
          ],
          value_type: "datetime"
        },
        {
          label: "Priority",
          key: "priority",
          type: "select",
          choices: [
            { order: "descending", name: "Highest priority to lowest" },
            { order: "ascending", name: "Lowest priority to highest" }
          ],
          value_type: "integer"
        }
      ];

      this.selectedSort = { created: null, priority: null };
    },
    updateSort: function (sortType, sortKey, selectedItem) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentSort[sortKey];
      } else {
        this.currentSort[sortKey] = {
          type: sortType,
          order: selectedItem["order"]
        };
      }

      var sort_list = [];
      for (var s in this.currentSort) {
        sort_list.push(this.selectedSort[s]["name"]);
      }
      this.currentSortText = sort_list.join(", ");
    },
    startSort: function () {
      for (var s in this.currentSort) {
        var sort_item = this.currentSort[s];
        var sort_type = sort_item["type"];
        var sort_order = sort_item["order"];
        var sort_key = s;

        this.alerts.sort(function (a, b) {
          if (sort_type == "datetime") {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if (sort_order == "descending") {
              return new Date(b[sort_key]) - new Date(a[sort_key]);
            } else if (sort_order == "ascending") {
              return new Date(a[sort_key]) - new Date(b[sort_key]);
            }
          } else if (sort_type == "integer") {
            if (sort_order == "descending") {
              return parseInt(b[sort_key]) - parseInt(a[sort_key]);
            } else if (sort_order == "ascending") {
              return parseInt(a[sort_key]) - parseInt(b[sort_key]);
            }
          }
        });
      }

      this.showSort = false;
    },
    clearSort: function () {
      this.currentSort = {};
      for (var i in this.selectedSort) {
        this.selectedSort[i] = null;
      }

      this.alerts
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
        .sort(function (a, b) {
          return parseInt(b.priority) - parseInt(a.priority);
        });

      this.showSort = false;
      this.currentSortText = "";
    },
    // --------- Alerts -----------
    createAlert: function (alert) {
      var alert_data = alert;
      var can_add_to_alerts = true;
      if (this.currentFilters != null) {
        for (var key in this.currentFilters) {
          var result = utils.searchInDict(
            alert_data,
            key,
            this.currentFilters[key]
          );
          if (result == false) {
            can_add_to_alerts = false;
          }
        }
      }
      if (
        can_add_to_alerts &&
        alert_data.alert_type == "licence_plate_detected"
      ) {
        this.all_lpr_alerts.push(alert_data);
        this.filterCameraForEvent();
      }
    },
    filterCameraForEvent: function () {
      var $this = this;
      var filtered_event_list = [];

      if ($this.filteredCameraEvent != null) {
        if ($this.filteredCameraEvent.length !== 0) {
          $this.filteredCameraEvent.forEach(function (camera) {
            var filtered = $this.all_lpr_alerts.filter(function (
              lpr_event,
              index,
              arr
            ) {
              return lpr_event.metadata.camera_ip == camera.ip;
            });
            if (filtered != []) {
              filtered_event_list.push.apply(filtered_event_list, filtered);
            }
          });
        } else {
          $this.filteredCameraEvent = null;
          $this.event_list = $this.all_event_list;
        }
      }

      if ($this.filteredCameraEvent == null) {
        $this.alerts = $this.all_lpr_alerts;
      } else if (
        $this.filteredCameraEvent != null &&
        filtered_event_list.length == 0
      ) {
        $this.alerts = [];
      } else {
        $this.alerts = filtered_event_list;
      }
    },
    renderAlerts: function () {
      // Remove old alerts to prevent memory hogging
      this.alerts
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
        .sort(function (a, b) {
          return parseInt(b.priority) - parseInt(a.priority);
        });

      // var max_alerts = 30;
      // if (this.alerts.length > max_alerts) {
      //   while (this.alerts.length > max_alerts) {
      //       this.alerts.pop();
      //   }
      // }

      this.startSort();
    },
    discardAlertHandler: function (alert) {
      let $this = this;
      let alert_index = null;
      for (var i in this.alerts) {
        if (this.alerts[i].id == alert.id) {
          alert_index = i;
          break;
        }
      }
      if (alert_index != null) {
        this.alerts.splice(alert_index, 1);
      }
    },
    checkDiscardedAlerts: function (discardedAlertIDs) {
      let alert_index = null;
      for (var i in this.alerts) {
        let alert_id = String(this.alerts[i].id);
        if (discardedAlertIDs.indexOf(alert_id) >= 0) {
          alert_index = i;
          break;
        }
      }
      if (alert_index != null) {
        this.alerts.splice(alert_index, 1);
      }
    },
    createObjectPreferences: function () {
      let $this = this;
      let camera_list = [];
      let selected_camera_list = {};
      let workspaces = {};
      let id = {};
      let custom = {};
      let panels = {};
      let num = {};

      if ($this.filteredCameraEvent != null) {
        for (let key in $this.filteredCameraEvent) {
          camera_list.push($this.filteredCameraEvent[key].ip);
        }
        selected_camera_list["selected_camera_list"] = camera_list;
        num[$this.panel_info.num] = selected_camera_list;
        panels[$this.panel_info.panel] = num;
        custom["custom"] = panels;
        id[$this.workspaceID] = custom;
        workspaces["workspaces"] = id;

        $this.preferences = {
          ...workspaces
        };
      }
    },
    onSubmitFormAddDevice: function () {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/users/" + $this.currentUser.id + "/";

      if ($this.currentUserWorkspaces != undefined) {
        if ($this.currentUserPanelNum != null) {
          //
          if ($this.filteredCameraEvent != null) {
            //
            $this.selectedCameraEvent = $this.filteredCameraEvent.map(function (
              element
            ) {
              return `${element.ip}`;
            });

            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].selected_camera_list =
              $this.selectedCameraEvent;
          } else {
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].selected_camera_list =
              $this.filteredCameraEvent;
          }

          $this.merged.workspaces = {
            ...$this.currentUserWorkspaces
          };

          $this.formUpdate.preferences = $this.merged;
        } else {
          //
          $this.createObjectPreferences();

          if ($this.currentUserWorkspaceID == null) {
            $this.merged.workspaces = {
              ...$this.currentUserWorkspaces,
              ...$this.preferences.workspaces
            };
          } else {
            if ($this.currentUserPanel == null) {
              $this.currentUserPanel = $this.panel_info.panel;
            }
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ] = {
              ...$this.currentUserWorkspaces[$this.currentUserWorkspaceID]
                .custom[$this.currentUserPanel],
              ...$this.preferences.workspaces[$this.workspaceID].custom[
                $this.panel_info.panel
              ]
            };

            $this.merged.workspaces = {
              ...$this.currentUserWorkspaces
            };
          }

          $this.formUpdate.preferences = $this.merged;
        }
      } else {
        $this.createObjectPreferences();
        $this.formUpdate.preferences = $this.preferences;
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          if (data == null) {
            this.filteredPreferencesCameraEvent = [];
            for (let key in this.currentUserWorkspaces[
              this.currentUserWorkspaceID
            ].custom[this.currentUserPanel][this.currentUserPanelNum]
              .selected_camera_list) {
              $this.filteredPreferencesCameraEvent.push({
                ip: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                  .custom[this.currentUserPanel][this.currentUserPanelNum]
                  .selected_camera_list[key],
                name: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                  .custom[this.currentUserPanel][this.currentUserPanelNum]
                  .selected_camera_list[key]
              });
            }

            $this.filteredCameraEvent = $this.filteredPreferencesCameraEvent;

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated preferences successfully",
              message_type: "success"
            });

            $this.updateFormErrorMessage = null;
          } else {
            $this.updateFormErrorMessage = data.detail;
          }
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss">
@import "./PanelLprStaffEventCard.scss";
</style>
