<template>
  <div class="panel-content">
    <div class="panel-content-header">
      <h2>Maintenance</h2>
    </div>
    <div class="panel-content-body scroller" v-if="hasPermission">
      <b-table
        striped
        hover
        small
        borderless
        :items="table_items"
        :fields="table_fields"
      >
        <template #cell(date)="data">
          {{ data.item.date }}

          <span
            v-if="data.item.tag !== null"
            class="badge mr-2 text-capitalize"
            :class="{
              'badge-success': data.item.tag == 'upcoming',
              'badge-warning': data.item.tag == 'today'
            }"
          >
            {{ data.item.tag }}
          </span>
        </template>
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>
        <template #cell(performed_by)="data">
          {{ data.item.performed_by }}
        </template>
      </b-table>
      <!-- <thead>
                    <tr>
                        <th>Floor</th>
                        <th>Cameras</th>
                        <th>Doors</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="floor in building_devices" :key="floor.id">
                        <td>{{ floor.name }}<br>Offline</td>
                        <td>{{ floor.cameras_total }}<br>{{ floor.cameras_offline }}</td>
                        <td>{{ floor.doors_total }}<br>{{ floor.doors_offline }}</td>
                    </tr>
                </tbody>
            </table> -->
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { getRandomInt } from "../../assets/global/js/utils";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "Maintenance",
  data() {
    return {
      table_fields: [],
      table_items: [],
      building: null,
      hasPermission: false
    };
  },
  computed: {
    ...mapGetters({
      isDevicesInitiated: "psim/isDevicesInitiated",
      _getViewingLocation: "psim/getViewingLocation",
      getCurrentUser: "session/getCurrentUser"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        if (n != null && n.building != null) {
          this.building = n.building;
          if (this.isDevicesInitiated) {
            this.fetchDevices();
          }
        }
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("maintenance") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Panel Maintenance mounted");
    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("maintenance") >= 0) {
      this.hasPermission = true;
    }

    // init data
    if (
      this._getViewingLocation != null &&
      this._getViewingLocation.building != null
    ) {
      this.building = this._getViewingLocation.building;
      this.fetchDevices();
    }
  },
  methods: {
    fetchDevices: function () {
      if (this.building != null) {
        var description_list = [
          "Alarm system checkup",
          "Door system checkup",
          "Upgraded system",
          "Upgraded alarm",
          "Upgraded door"
        ];
        var performed_by_list = [
          "Alarm Supplier",
          "Door supplier",
          "Robert",
          "George"
        ];
        var maintenance_list = [];

        for (var i = 0; i < 9; i++) {
          var data = {
            date: "10/03/2021",
            description:
              description_list[getRandomInt(0, description_list.length - 1)],
            performed_by:
              performed_by_list[getRandomInt(0, performed_by_list.length - 1)],
            tag: null
          };

          if (i == 3) {
            data["tag"] = "upcoming";
          } else if (i == 4) {
            data["tag"] = "today";
          }

          maintenance_list.push(data);
        }

        this.table_items = maintenance_list;
        this.table_fields = [
          {
            key: "date",
            label: "Date",
            sortable: true
          },
          {
            key: "description",
            label: "Description",
            sortable: true
          },
          {
            key: "performed_by",
            label: "Performed By",
            sortable: true
          }
        ];
      }
      // end fetchDevices
    }
  }
};
</script>
