<template>
  <div id="panel_building_list" class="h-100">
    <div class="building-list-wrapper">
      <div class="building-list-card-container scroller">
        <CardBuilding
          v-for="building in buildings"
          :key="building.id"
          :building="building"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardBuilding from "@/components/CardBuilding/CardBuilding.vue";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingListView",
  components: {
    CardBuilding
  },
  props: {
    filters: {
      type: Object,
      required: false
    },
    sorting: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      buildings: []
    };
  },
  computed: {
    ...mapGetters({
      getViewingLocation: "psim/getViewingLocation"
    })
  },
  watch: {
    getViewingLocation: {
      handler: function (n, o) {
        // console.log(n);
        if (n != null && n.building == null) {
          this.initView();
        }
      },
      deep: true
    },
    filters: {
      handler: function (n, o) {
        console.log("Filters:", n);
        this.fetchBuildings();
      }
    },
    sorting: {
      handler: function (n, o) {
        console.log("Sorting:", n);
        this.fetchBuildings();
      }
    }
  },
  mounted: function () {
    console.log("Building List View mounted");

    this.initView();
  },
  methods: {
    initView: function () {
      if (
        this.getViewingLocation != null &&
        this.getViewingLocation.building == null
      ) {
        this.fetchBuildings();
      }
    },
    // --------- Buildings -----------
    fetchBuildings: function () {
      var building_list = this.$store.getters["psim/getBuildings"](
        this.filters
      );

      if (this.sorting != null && Object.keys(this.sorting).length > 0) {
        for (var s in this.sorting) {
          var sort_item = this.sorting[s];
          var sort_type = sort_item["type"];
          var sort_order = sort_item["order"];
          var sort_key = s;

          building_list.sort(function (a, b) {
            if (sort_type == "datetime") {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              if (sort_order == "descending") {
                return new Date(b[sort_key]) - new Date(a[sort_key]);
              } else if (sort_order == "ascending") {
                return new Date(a[sort_key]) - new Date(b[sort_key]);
              }
            } else if (sort_type == "integer") {
              if (sort_order == "descending") {
                return parseInt(b[sort_key]) - parseInt(a[sort_key]);
              } else if (sort_order == "ascending") {
                return parseInt(a[sort_key]) - parseInt(b[sort_key]);
              }
            } else if (sort_type == "string") {
              if (sort_order == "descending") {
                return b[sort_key].localeCompare(a[sort_key]);
              } else if (sort_order == "ascending") {
                return a[sort_key].localeCompare(b[sort_key]);
              }
            }
          });
        }
      }
      this.buildings = building_list;
    }
  }
};
</script>

<style lang="scss">
@import "./BuildingListView.scss";
</style>
