<template>
  <div
    class="panel-content p-0"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      class="panel-content-header p-0 pl-3 d-flex align-items-center justify-content-between"
      v-if="camera_ip !== undefined"
    >
      <h2>Live View - {{ camera_ip }}</h2>
      <div>
        <b-button
          v-if="is_edit"
          @click="openSelectDeviceModal()"
          v-b-tooltip.hover
          v-b-modal="'form_search_device_' + item.num"
          title="Edit Camera Stream"
          size="sm"
        >
          <i class="fas fa-edit"></i>
        </b-button>
        <b-button
          v-if="hover"
          @click="refreshCameraStream()"
          v-b-tooltip.hover
          title="Refresh Camera Stream"
          size="sm"
        >
          <i class="fas fa-sync"></i>
        </b-button>
      </div>
    </div>
    <div class="panel-content-body p-0" v-if="hasPermission">
      <div class="live-video-player-container">
        <div
          id="live_video_container"
          class="bg-dark"
          ref="liveVideoContainer"
          v-if="camera_ip !== undefined"
        ></div>
        <div v-if="camera_ip === undefined" class="pt-3 mt-5">
          <!--          <span class="panel-remove" @click="removeItem(panel_info.i)">-->
          <!--            <i class="fa fa-times"></i>-->
          <!--          </span>-->
          <div class="panel-select-container">
            <b-container class="bv-example-row">
              <b-row class="justify-content-md-center">
                <b-col cols="12" md="auto">
                  <!--                  <b-form-group-->
                  <!--                    :label="'Panel ' + panel_info.num"-->
                  <!--                    :label-for="'panel_select_' + panel_info.i"-->
                  <!--                  >-->
                  <!--                    <b-form-select-->
                  <!--                      v-model="selectedPanels[panel_info.i]"-->
                  <!--                      :options="panelOptions"-->
                  <!--                      :id="'panel_select_' + panel_info.i"-->
                  <!--                      @change="updatePanel"-->
                  <!--                    >-->
                  <!--                    </b-form-select>-->
                  <!--                  </b-form-group>-->
                </b-col>
              </b-row>
              <b-row class="justify-content-md-center">
                <b-col cols="12" md="auto">
                  <div class="d-flex">
                    <b-button
                      v-b-modal="'form_search_device_' + item.num"
                      @click="openSelectDeviceModal(panel_info.i)"
                      variant="primary"
                    >
                      {{ getButtonText(panel_info) }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
      <div
        class="live-video-player-container d-flex align-items-center justify-content-center"
        v-if="liveVideoURL == null && camera_ip !== undefined"
      >
        {{ liveVideoMessage }}
      </div>
    </div>
    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
    <b-modal
      @hide="resetFormAddDevice"
      ref="formAddDevice"
      title="Select a Camera"
      :id="'form_search_device_' + item.num"
      centered
      hide-footer
      v-if="camera_ip === undefined || is_edit"
    >
      <div class="d-flex">
        <b-form-group
          id="input-group-search-ip"
          label="Search by IP:"
          label-for="input-search-ip"
          v-show="searchType != null"
        >
          <b-form-input
            id="input-search-ip"
            v-model="searchIP"
            type="text"
            placeholder="e.g. 192.168.0.123"
            required
            @change="searchDevices"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="device-available-container" v-show="searchType != null">
        <div>Devices available</div>
        <div class="device-options-container mt-2">
          <div
            v-for="device in searchDeviceOptions"
            :key="device.id"
            class="device-option"
            :class="[selectedSearchDeviceOption == device.ip ? 'selected' : '']"
            @click="selectSearchDeviceOption(device)"
          >
            {{ device.ip }}
          </div>
        </div>
        <div v-show="formAddDeviceData.ip != null" class="pr-2">
          <h2>Camera Preview</h2>
          <div class="live-video-player-container">
            <div id="live_video_container" ref="liveVideoContainer"></div>
          </div>
          <div
            class="live-video-player-container d-flex align-items-center justify-content-center"
            v-if="liveVideoURL == null"
          >
            {{ liveVideoMessage }}
          </div>

          <div
            class="video-format-options-selector"
            v-if="formAddDeviceData.ip != null"
          >
            <b-form-select
              v-model="formAddDeviceData.video_format"
              :options="liveVideoFormatOptions"
              @change="getCameraDetails(formAddDeviceData.ip)"
              class="mt-2"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-form
        @submit="onSubmitFormAddDevice"
        :class="[selectedSearchDeviceOption != null ? '' : 'hide']"
        class="mt-2"
      >
        <b-form-group id="input-group-ip" label="IP:" label-for="input-ip">
          <b-form-input
            id="input-ip"
            v-model="formAddDeviceData.ip"
            type="text"
            :disabled="true"
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import Plyr from "plyr";
import Hls from "hls.js";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import CardAlert from "@/components/CardAlert/CardAlert.vue";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelLiveCameraView",
  props: {
    cameraIp: {
      /* type: String,
      required: true */
    },
    videoFormat: {
      /* type: String,
      required: true */
    },
    workspaceId: {},
    item: {}
  },
  data: function () {
    return {
      hasPermission: false,

      camera_ip: this.cameraIp,
      liveVideoFormat: this.videoFormat,
      workspaceID: this.workspaceId,
      panel_info: this.item,

      currentUser: null,
      currentUserWorkspaces: null,
      currentUserWorkspaceID: null,
      currentUserPanel: null,
      currentUserPanelNum: null,
      currentUserCameraIp: null,
      currentUserCameraId: null,
      currentUserVideoFormat: null,

      showliveVideoFormat: null,
      liveVideoFormatOptions: [
        { value: "mp4", text: "MP4" },
        { value: "hls", text: "HLS" },
        { value: "mpjpeg", text: "MPJPEG" },
        { value: "webm", text: "WEBM" }
      ],
      videoURLs: {
        mp4: null,
        hls: null,
        mpjpeg: null,
        webm: null
      },
      videoAuthSession: null,
      videoAuthData: null,
      videoAuth: null,
      liveVideoURL: null,
      liveVideoMessage: null,
      hover: false,
      selectedPanels: {},
      panelOptions: [],
      searchDeviceOptions: [],
      searchType: "camera",
      selectedSearchDeviceOption: null,
      preferences: {},
      formUpdate: {
        preferences: {}
      },
      merged: {
        workspaces: {}
      },
      formAddDeviceData: {
        id: null,
        ip: null,
        video_format: "mpjpeg"
      },
      searchIP: null,
      updateFormErrorMessage: null,
      is_edit: false
    };
  },
  computed: {
    ...mapGetters({
      //isCamerasInitiated: "psim/isCamerasInitiated",
      //isWorkspacesInitiated: "psim/isWorkspacesInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    //let allowed_panels = this.current_user.panels;

    this.currentUser = this.$store.getters["session/getCurrentUser"];

    if (this.cameraIp) {
      this.hasPermission = true;
      this.getCameraDetails();
    } else {
      if (
        this.currentUser.preferences === null ||
        Object.keys(this.currentUser.preferences).length === 0
      ) {
        //
        if (this.currentUser.panels.indexOf("live_camera_view") >= 0) {
          // this.is_edit = false;
          this.hasPermission = true;

          // console.log("getCameraDetails");

          this.getCameraDetails();
        }
      } else {
        //
        // console.log("getCurrentUserPreferences");
        this.getCurrentUserPreferences();
      }
    }
  },
  methods: {
    getCurrentUserPreferences() {
      this.currentUserWorkspaces = this.currentUser.preferences.workspaces;

      for (let workspaceID in this.currentUserWorkspaces) {
        if (workspaceID == this.workspaceID) {
          this.currentUserWorkspaceID = workspaceID;
        }
      }

      if (this.currentUserWorkspaceID != null) {
        for (let panel in this.currentUserWorkspaces[
          this.currentUserWorkspaceID
        ].custom) {
          if (panel == "live_camera_view") {
            this.currentUserPanel = panel;
          }
        }

        if (this.currentUserPanel != null) {
          for (let num in this.currentUserWorkspaces[
            this.currentUserWorkspaceID
          ].custom[this.currentUserPanel]) {
            if (num == this.panel_info.num) {
              this.currentUserPanelNum = num;
              this.currentUserCameraIp =
                this.currentUserWorkspaces[this.currentUserWorkspaceID].custom[
                  this.currentUserPanel
                ][this.currentUserPanelNum].camera_ip;
              this.currentUserCameraId =
                this.currentUserWorkspaces[this.currentUserWorkspaceID].custom[
                  this.currentUserPanel
                ][this.currentUserPanelNum].camera_id;
              this.currentUserVideoFormat =
                this.currentUserWorkspaces[this.currentUserWorkspaceID].custom[
                  this.currentUserPanel
                ][this.currentUserPanelNum].video_format;
            }
          }
        }

        if (this.currentUserPanelNum != null) {
          this.getPanel(
            this.currentUserCameraIp,
            this.currentUserCameraId,
            this.currentUserVideoFormat
          );
          this.is_edit = true;
        }
      }
      if (this.currentUser.panels.indexOf("live_camera_view") >= 0) {
        // this.is_edit = false;
        this.hasPermission = true;
        this.getCameraDetails();
      }
    },
    getPanel: function (camera_ip, camera_id, video_format) {
      this.camera_ip = camera_ip;
      this.is_edit = true;

      this.panel_info["camera_id"] = camera_id;
      this.panel_info["camera_ip"] = camera_ip;
      this.panel_info["video_format"] = video_format;
    },
    getCameraDetails: function (cameraIp) {
      let $this = this;
      let device_search;

      if (cameraIp === undefined) {
        if ($this.currentUserCameraIp != null) {
          device_search = $this.$store.getters["device/filterCameras"]({
            ip: $this.currentUserCameraIp
          });
        } else {
          //
          device_search = $this.$store.getters["device/filterCameras"]({
            ip: $this.camera_ip
          });
        }
      } else {
        device_search = $this.$store.getters["device/filterCameras"]({
          ip: cameraIp
        });
      }

      // console.log($this.camera_ip)
      if (device_search.length > 0) {
        $this.deviceObj = device_search[0];
        // console.log($this.deviceObj)
        $this.getVideoStream();
      } else {
        //
        $this.fetchPanels();
      }
    },
    getVideoStream: function () {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      let stream_url = null;
      let stream_urls = null;
      var mpjpeg_stream = null;
      let has_stream = false;

      if ($this.deviceObj.monitor_type === "DIGIFORT") {
        stream_urls = $this.deviceObj.data.details.stream_url;
        mpjpeg_stream = stream_urls.mpjpeg;

        if (mpjpeg_stream) {
          has_stream = true;
          $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
        }

        if (has_stream == false) {
          $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.deviceObj.ip) {
                      $this.videoAuthSession =
                        camera_data["details"]["authsession"]["AuthSession"];
                      $this.videoAuthData =
                        camera_data["details"]["authsession"]["AuthData"];
                      break;
                    }
                  }
                }
              }
              $this.loadLivePlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        }
      } else {
        //

        if ($this.deviceObj.data.details.stream_url != undefined) {
          stream_url = $this.deviceObj.data.details.stream_url;
        } else {
          stream_urls = $this.deviceObj.data.details.stream_urls;
        }

        // console.log("stream_url");
        // console.log(stream_url);

        if (stream_url != null) {
          let mp4_stream = stream_url.mp4;
          let hls_stream = stream_url.hls;
          let webm_stream = stream_url.webm;
          mpjpeg_stream = stream_url.mpjpeg;

          if (mp4_stream || hls_stream || mpjpeg_stream || webm_stream) {
            has_stream = true;
            $this.videoURLs.mp4 = mp4_stream ? mp4_stream : null;
            $this.videoURLs.hls = hls_stream ? hls_stream : null;
            $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
            $this.videoURLs.webm = webm_stream ? webm_stream : null;
          }
        } else {
          //
          has_stream = false;
        }

        // console.log("has_stream");
        // console.log(has_stream);

        if (has_stream == false) {
          // console.log("No camera stream available");
          // $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                // console.log("camera_result");
                // console.log(camera_result);

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.deviceObj.ip) {
                      camera_auth_key = camera_data["details"]["authkey"];
                      break;
                    }
                  }
                }

                if (camera_auth_key != null) {
                  break;
                }
              }

              if (camera_auth_key != null) {
                $this.videoAuth = camera_auth_key;
              }
              $this.loadLivePlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        }
      }
    },
    resetLivePlayer: function () {
      let $this = this;
      try {
        if ($this.livePlayer != null) {
          $this.livePlayer.destroy();
          $this.livePlayer = null;
        }
      } catch (err) {
        //
      }

      if (
        $this.liveVideoURL != null &&
        $this.$refs.liveVideoContainer != null
      ) {
        while ($this.$refs.liveVideoContainer.hasChildNodes()) {
          $this.$refs.liveVideoContainer.removeChild(
            $this.$refs.liveVideoContainer.lastChild
          );
        }
      }

      $this.liveVideoURL = null;
      $this.liveVideoMessage = "Loading camera stream";
    },
    loadLivePlayer: function () {
      let $this = this;
      let video_url = null;
      if ($this.liveVideoFormat !== undefined) {
        $this.showliveVideoFormat = $this.liveVideoFormat;
      } else {
        $this.showliveVideoFormat = $this.formAddDeviceData.video_format;
      }

      if ($this.showliveVideoFormat == "mp4") {
        video_url = $this.videoURLs.mp4;
      } else if ($this.showliveVideoFormat == "hls") {
        video_url = $this.videoURLs.hls;
      } else if ($this.showliveVideoFormat == "mpjpeg") {
        video_url = $this.videoURLs.mpjpeg;
      } else if ($this.showliveVideoFormat == "webm") {
        video_url = $this.videoURLs.webm;
      }

      $this.resetLivePlayer();

      try {
        let live_stream_url = new URL(video_url);
        if ($this.deviceObj.monitor_type === "DIGIFORT") {
          if ($this.videoAuthSession && $this.videoAuthData) {
            live_stream_url.searchParams.set(
              "AuthSession",
              $this.videoAuthSession
            );
            live_stream_url.searchParams.set("AuthData", $this.videoAuthData);
          }
        } else {
          if ($this.videoAuth) {
            // console.log("$this.videoAuth");
            // console.log($this.videoAuth);
            live_stream_url.searchParams.set("auth", $this.videoAuth);
          }
        }

        $this.liveVideoURL = live_stream_url.toString();

        let live_video;
        let live_video_source;
        let live_player;

        if ($this.showliveVideoFormat === "mp4") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/mp4");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.showliveVideoFormat === "webm") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/webm");
          live_video.setAttribute("autoplay", true);
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.showliveVideoFormat === "hls") {
          // live
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          const hls1 = new Hls();
          // hls1.loadSource("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8");
          hls1.loadSource($this.liveVideoURL);
          hls1.attachMedia(live_video);
          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.showliveVideoFormat === "mpjpeg") {
          // live

          live_video = document.createElement("img");

          // remove img-fluid for cover image
          // live_video.classList.add("img-fluid");

          live_video.setAttribute("id", "camera_stream");

          // // 4: 3 sample image
          // live_video.setAttribute("src", "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Aspect-ratio-4x3.svg/2560px-Aspect-ratio-4x3.svg.png");

          // // 16 : 9 sample image
          // live_video.setAttribute("src", "https://image.shutterstock.com/image-vector/background-unusual-modern-material-design-600w-284162744.jpg");

          if ($this.deviceObj.monitor_type === "DIGIFORT") {
            live_video.setAttribute("src", $this.liveVideoURL);
          } else {
            live_video.setAttribute(
              "src",
              $this.liveVideoURL + "&action=stream"
            );
          }

          live_video.setAttribute("alt", "Loading camera stream...");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          $this.livePlayer = null;
        }
      } catch (err) {
        // console.log(err);
        // $this.liveVideoMessage = "No camera stream available";
      }
    },
    getCameraName: function (camera_ip) {
      let $this = this;
      let device_search;

      if (camera_ip == undefined) {
        if ($this.currentUserCameraIp != null) {
          device_search = $this.$store.getters["device/filterCameras"]({
            ip: $this.currentUserCameraIp
          });
        } else {
          //
          device_search = $this.$store.getters["device/filterCameras"]({
            ip: $this.camera_ip
          });
        }
      } else {
        device_search = $this.$store.getters["device/filterCameras"]({
          ip: camera_ip
        });
      }

      /* device_search = $this.$store.getters["device/filterCameras"]({
        ip: camera_ip
      }); */

      if (device_search.length > 0) {
        $this.cameraObj = device_search[0];

        if ($this.cameraObj.name != undefined || $this.cameraObj.name != null) {
          return $this.cameraObj.name;
        }

        return camera_ip;
      }
    },
    refreshCameraStream: function () {
      let $this = this;
      // console.log($this.camera_ip);
      this.getCameraDetails();
    },
    fetchPanels: function () {
      let $this = this;
      let available_panel_options =
        $this.$store.getters["psim/getPanelOptions"];
      // console.log(current_user);
      let API_URL =
        $this.getAPIServerURL +
        "/permissions/user/" +
        $this.currentUser.id +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          // console.log(data.panels)
          let panels_data = data.panels;
          if (panels_data != null && panels_data != undefined) {
            $this.panelOptions = [
              { value: null, text: "--- Please select an option ---" }
            ];

            for (let d in panels_data) {
              let panel_result = available_panel_options.filter(function (
                item
              ) {
                if (panels_data[d] == item.id) {
                  return true;
                }
                return false;
              });
              // console.log(panel_result);

              if (panel_result.length > 0) {
                let panel_data = {
                  value: panel_result[0].id,
                  text: panel_result[0].name
                };
                $this.panelOptions.push(panel_data);
                for (let key in $this.panelOptions) {
                  if (
                    $this.panelOptions[key].value != null &&
                    $this.panelOptions[key].value == "live_camera_view"
                  ) {
                    $this.selectedPanels[$this.panel_info.i] =
                      $this.panelOptions[key].value;
                  }
                }
              }
            }
          } else {
            $this.panelOptions = [
              { value: null, text: "--- No panels available ---" }
            ];
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    getButtonText: function (item) {
      if (item.camera_ip != null && item.video_format != null) {
        return item.camera_ip + " (" + item.video_format.toUpperCase() + ")";
      } else {
        return "Select Camera";
      }
    },
    updatePanel: function (e) {
      this.$forceUpdate();
    },
    openSelectDeviceModal: function (panel_index) {
      //
      let $this = this;
      $this.searchDevices();
    },
    searchDevices: function (e) {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      let RESULT_CATEGORY = "";
      let STORE_GETTER = "";
      let DEVICE_TYPE = "";

      $this.searchDeviceOptions = [];
      // console.log("searchDevices", $this.searchType);
      if ($this.searchType == "camera") {
        RESULT_CATEGORY = "DEV_CAM";
        STORE_GETTER = "psim/getCamera";
        DEVICE_TYPE = "camera";
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((mdata) => {
          let category_result = null;
          for (let service_url in mdata["result"]) {
            try {
              category_result =
                mdata["result"][service_url]["result"][RESULT_CATEGORY];
            } catch (err) {
              //
            }

            if (category_result != null) {
              for (let i in category_result) {
                let device_url = i;
                let device_data = category_result[i];
                let device_ip = new URL(device_url).host;
                if (device_data.details != undefined) {
                  if (device_data.details.ip != undefined) {
                    device_ip = device_data.details.ip;
                  }
                }

                if ($this.$store.getters[STORE_GETTER](device_ip) == null) {
                  let device_result = {
                    id: device_url,
                    ip: device_ip,
                    type: DEVICE_TYPE,
                    status: device_data["status"],
                    data: {
                      server_url:
                        device_data["monitor_url"] == undefined
                          ? null
                          : device_data["monitor_url"],
                      details:
                        device_data["details"] == undefined
                          ? null
                          : device_data["details"],
                      meta:
                        device_data["meta"] == undefined
                          ? null
                          : device_data["meta"]
                    }
                  };
                  $this.searchDeviceOptions.push(device_result);
                }
              }
            }
          }

          $this.searchDeviceOptions.sort(function (a, b) {
            const numA = Number(
              a["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            const numB = Number(
              b["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            return numA - numB;
          });
        })
        .catch((err) => {
          // console.log("Error: No ");
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    selectSearchDeviceOption: function (device_data) {
      this.selectedSearchDeviceOption = device_data.ip;
      this.formAddDeviceData.id = device_data.id;
      this.formAddDeviceData.ip = device_data.ip;
    },
    createObjectPreferences: function () {
      let $this = this;
      let panel_info = {};
      let preferences = {};
      let id = {};
      let custom = {};
      let panels = {};
      let num = {};

      panel_info["camera_id"] = $this.formAddDeviceData.id;
      panel_info["camera_ip"] = $this.formAddDeviceData.ip;
      panel_info["video_format"] = $this.formAddDeviceData.video_format;

      num[$this.panel_info.num] = panel_info;
      panels[$this.panel_info.panel] = num;
      custom["custom"] = panels;
      id[$this.workspaceID] = custom;
      preferences["workspaces"] = id;

      $this.preferences = {
        ...preferences
      };
    },
    onSubmitFormAddDevice: async function (e) {
      e.preventDefault();

      let $this = this;

      var API_URL2 = $this.getAPIServerURL + "/users/my-user/";
      const client = $this.$root.getAjaxFetchClient();

      this.currentUser = await client.getRequest(API_URL2);

      let API_URL =
        $this.getAPIServerURL + "/users/" + $this.currentUser.id + "/";

      try {
        this.currentUserWorkspaces = this.currentUser.preferences.workspaces;

        if ($this.currentUserWorkspaces != undefined) {
          if ($this.currentUserPanelNum != null) {
            //
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].camera_id = $this.formAddDeviceData.id;
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].camera_ip = $this.formAddDeviceData.ip;
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].video_format =
              $this.formAddDeviceData.video_format;

            $this.merged.workspaces = {
              ...$this.currentUserWorkspaces
            };

            $this.formUpdate.preferences = $this.merged;
          } else {
            //
            $this.createObjectPreferences();
            if ($this.currentUserWorkspaceID == null) {
              $this.merged.workspaces = {
                ...$this.currentUserWorkspaces,
                ...$this.preferences.workspaces
              };
            } else {
              if ($this.currentUserPanel == null) {
                $this.currentUserPanel = $this.panel_info.panel;
              }
              $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
                $this.currentUserPanel
              ] = {
                ...$this.currentUserWorkspaces[$this.currentUserWorkspaceID]
                  .custom[$this.currentUserPanel],
                ...$this.preferences.workspaces[$this.workspaceID].custom[
                  $this.panel_info.panel
                ]
              };

              $this.merged.workspaces = {
                ...$this.currentUserWorkspaces
              };
            }

            $this.formUpdate.preferences = $this.merged;
          }
        } else {
          $this.createObjectPreferences();
          $this.formUpdate.preferences = $this.preferences;
        }
      } catch (e) {
        $this.createObjectPreferences();
        $this.formUpdate.preferences = $this.preferences;
      }

      // console.log("$this.formUpdate.preferences");
      // console.log($this.formUpdate.preferences);

      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          if (data == null) {
            if ($this.panel_info != undefined) {
              $this.camera_ip = $this.formAddDeviceData.ip;
              $this.panel_info["camera_id"] = $this.formAddDeviceData.id;
              $this.panel_info["camera_ip"] = $this.formAddDeviceData.ip;
              $this.panel_info["video_format"] =
                $this.formAddDeviceData.video_format;
            }

            $this.$refs.formAddDevice.hide();

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated preferences successfully",
              message_type: "success"
            });

            $this.updateFormErrorMessage = null;
          } else {
            $this.updateFormErrorMessage = data.detail;
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetFormAddDevice: function (e) {
      this.formAddDeviceData.id = null;
      this.formAddDeviceData.ip = null;
      this.formAddDeviceData.type = null;
      this.formAddDeviceData.name = null;
      this.formAddDeviceData.data = null;

      this.selectedSearchDeviceOption = null;
      this.searchType = "camera";
      this.searchIP = null;
      this.searchDeviceOptions = [];
    },
    removeItem: function (val) {
      delete this.selectedPanels[String(val)];
    }
  }
};
</script>

<style lang="scss">
@import "./PanelLiveCameraView.scss";
</style>
