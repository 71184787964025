<template>
  <div class="panel-content-vms-dashboard">
    <div class="panel-content-header-vms-dashboard">
      <div class="d-flex justify-content-between">
        <h2 class="d-flex align-items-centers">
          {{ showDate() }}
        </h2>
        <div>
          <b-button size="sm" variant="primary" @click="goNewVisit">
            <i class="fas fa-plus mr-1"></i>Register New Visit
          </b-button>
        </div>
      </div>
    </div>
    <b-card-group class="my-4" deck>
      <b-card class="bg-surface-2">
        <div
          class="d-flex justify-content-between"
          v-b-tooltip.hover
          title="Count of visitors who has been marked as checked-in."
        >
          <h6>
            <strong>Checked In Visitors </strong>
          </h6>
          <b-overlay :show="loadCheckIn" rounded="sm"
            ><b-card-text>
              <h6>
                <strong>{{ getCheckInCounter }} </strong>
              </h6>
            </b-card-text></b-overlay
          >
        </div>
      </b-card>

      <b-card class="bg-surface-2">
        <div
          class="d-flex justify-content-between"
          v-b-tooltip.hover
          title="Count of visitors who has been marked as checked-out."
        >
          <h6>
            <strong>Checked Out Visitors</strong>
          </h6>
          <b-overlay :show="loadCheckOut" rounded="sm"
            ><b-card-text>
              <h6>
                <strong>{{ getCheckOutCounter }} </strong>
              </h6>
            </b-card-text></b-overlay
          >
        </div>
      </b-card>

      <b-card class="bg-surface-2">
        <div
          class="d-flex justify-content-between"
          v-b-tooltip.hover
          title="Count of visitors who has visit appointment of the day, but he/she has not checked in yet."
        >
          <h6>
            <strong>Expected Visitors</strong>
          </h6>
          <b-overlay :show="loadExpected" rounded="sm"
            ><b-card-text>
              <h6>
                <strong>{{ getExpectedVisitorCounter }} </strong>
              </h6>
            </b-card-text></b-overlay
          >
        </div>
      </b-card>

      <b-card class="bg-surface-2">
        <div
          class="d-flex justify-content-between"
          v-b-tooltip.hover.topleft
          title="Count of visitors who has checked-in, but he/she has not checked-out yet."
        >
          <h6>
            <strong>Overstayed Visitors </strong>
          </h6>
          <b-overlay :show="loadOverstay" rounded="sm"
            ><b-card-text>
              <h6>
                <strong>{{ getOverstayCounter }} </strong>
              </h6>
            </b-card-text></b-overlay
          >
        </div>
      </b-card>

      <b-card class="bg-surface-2">
        <div
          class="d-flex justify-content-between"
          v-b-tooltip.hover.topleft
          title="The number of visitors expected to arrive tomorrow onwards."
        >
          <h6>
            <strong>Upcoming Visits </strong>
          </h6>
          <b-overlay :show="loadCounter" rounded="sm">
            <b-card-text>
              <h6>
                <strong>{{ getUpcomingVisit }} </strong>
              </h6>
            </b-card-text></b-overlay
          >
        </div>
      </b-card>
    </b-card-group>

    <b-card class="bg-surface-2 my-3">
      <div class="d-flex">
        <b-form-input
          class="mr-3 text-truncate"
          size="md"
          id="input-1"
          v-model="keyword"
          placeholder="Search by visit code, host name or visitor name"
          v-on:keyup.enter="navigate"
        ></b-form-input>
        <b-button @click="navigate" v-model="keyword" size="sm" variant="light">
          Search
        </b-button>
      </div>
    </b-card>

    <b-row>
      <b-col lg="6" class="mb-5">
        <h6 class="checkUpcoming"><strong>Check-in</strong></h6>
        <b-table
          :items="checkInList"
          :fields="visibleFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="checkinListFilter"
          :filter-included-fields="checkinListFilterOn"
          :busy="busy1"
          stacked="md"
          class="tableVMS"
          fixed
          responsive
          show-empty
          small
          striped
          sticky-header
        >
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage1 - 1) * perPage1 }}
          </template>
          <template #cell(visit_code)="row">
            <router-link
              :to="{
                name: 'visit-detail',
                params: { id: row.item.id },
                query: { parents_url: 'PSIM' }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate" class="mr-2">
                <u> {{ row.item.visit_code }}</u></a
              >
            </router-link>
          </template>
          <template #cell(hosts)="row">
            <div
              v-for="host in row.item.hosts"
              :key="'_' + host.company_id"
              class="mb-2"
            >
              <i class="far fa-building mr-1"></i
              ><strong class="company-font">{{
                host.company.name | humanizeText
              }}</strong>
            </div>
            <div v-for="host in row.item.hosts" :key="'_' + host.id">
              <i class="fas fa-user-alt mr-1"></i
              ><strong class="host-font">{{ host.name | humanizeText }}</strong>
            </div>
          </template>
          <template #cell(visitors)="row">
            <div class="">
              <div
                v-for="(visitor, index) in row.item.visitors"
                :key="'expected_' + index + '_' + visitor.id"
                class="mr-1"
              >
                <i class="fas fa-user-alt mr-1"></i
                ><strong style="font-size: var(--font-size-normal)">{{
                  visitor.name | humanizeText
                }}</strong>
              </div>
            </div>
          </template>
          <template #cell(additional_metadata.check_in_time)="row">
            <div>
              {{
                row.item.additional_metadata.check_in_time | unixToTime12Hour
              }}
            </div>
          </template>
          <!-- <template #cell(additional_metadata.check_out_time)="row">
            <div>
              {{
                row.item.additional_metadata.check_out_time | unixToTime12Hour
              }}
            </div>
          </template> -->
          <template #cell(actions)="row">
            <b-button
              :disabled="hasCheckOut(row.item)"
              variant="primary"
              size="sm"
              v-b-tooltip.hover
              title="Check Out"
              @click="handleCheckOut(row.item)"
            >
              <i class="fas fa-sign-out-alt"></i>
            </b-button>
          </template>
        </b-table>
        <!--pagination -->
        <div
          class="d-flex justify-content-between"
          v-if="!busy1 && !searchCheckIn"
        >
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage1"
              :options="pageOptions1"
              size="sm"
              @change="handlePerPageChange1"
            ></b-form-select>
            <div class="d-flex w-100">Per Page</div>
          </div>

          <b-pagination
            size="sm"
            v-if="perPage1 !== 'all'"
            class="d-flex"
            v-model="currentPage1"
            :total-rows="totalRowsCheckIn"
            :per-page="perPage1"
            @change="handlePageChange1"
          ></b-pagination>
        </div>
      </b-col>
      <b-col lg="6" class="mb-5">
        <h6 class="checkUpcoming"><strong>Expected Visitors</strong></h6>
        <b-table
          :items="expectedList"
          :fields="expectedTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="expectedListFilter"
          :filter-included-fields="expectedListFilterOn"
          :busy="busy2"
          stacked="md"
          class="tableVMS"
          fixed
          striped
          responsive
          show-empty
          small
          sticky-header
        >
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage2 - 1) * perPage2 }}
          </template>
          <template #cell(visit_code)="row">
            <router-link
              :to="{
                name: 'visit-detail',
                params: { id: row.item.id },
                query: { parents_url: 'PSIM' }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate" class="mr-2">
                <u> {{ row.item.visit_code }}</u></a
              >
            </router-link>
          </template>

          <template #cell(hosts)="row">
            <div
              v-for="host in row.item.hosts"
              :key="'_' + host.company_id"
              class="mb-2"
            >
              <i class="far fa-building mr-1"></i
              ><strong class="company-font">{{
                host.company.name | humanizeText
              }}</strong>
            </div>
            <div v-for="host in row.item.hosts" :key="'_' + host.id">
              <i class="fas fa-user-alt mr-1"></i
              ><strong class="host-font">{{ host.name | humanizeText }}</strong>
            </div>
          </template>
          <template #cell(visitors)="row">
            <div class="">
              <div
                v-for="(visitor, index) in row.item.visitors"
                :key="'expected_' + index + '_' + visitor.id"
                class="mr-1"
              >
                <i class="fas fa-user-alt mr-1"></i
                ><strong style="font-size: var(--font-size-normal)">{{
                  visitor.name | humanizeText
                }}</strong>
              </div>
            </div>
          </template>
          <template #cell(visit_start_time)="row">
            {{ row.item.visit_start_time | unixToTime12Hours }}
          </template>
        </b-table>
        <!--pagination -->
        <div
          class="d-flex justify-content-between"
          v-if="!busy2 && !searchExpected"
        >
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              size="sm"
              v-model="perPage2"
              :options="pageOptions2"
              @change="handlePerPageChange2"
            ></b-form-select>
            <div class="d-flex w-100">Per Page</div>
          </div>
          <b-pagination
            size="sm"
            v-if="perPage2 !== 'all'"
            class="d-flex"
            v-model="currentPage2"
            :total-rows="totalRowsExpected"
            :per-page="perPage2"
            @change="handlePageChange2"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "VMSDashboard",
  data: function () {
    return {
      pageTitle: "VMS Dashboard",
      keyword: "",

      visitsList: [],
      company: [],
      hosts: null,
      visitors: null,
      visitID: null,

      sortBy: "visit_start_time",
      sortDesc: true,

      errorMessageCheckIn: null,
      errorMessageExpectedVisitor: null,
      errorMessageVisitStatistic: null,

      getUpcomingVisit: null,
      getCheckInCounter: null,
      getOverstayCounter: null,
      getCheckOutCounter: null,
      getExpectedVisitorCounter: null,
      // totalRows: 10,
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],

      loadCheckIn: false,
      loadCheckOut: false,
      loadExpected: false,
      loadOverstay: false,
      loadCounter: false,
      busy1: false,
      searchCheckIn: false,
      checkInList: [],
      checkinListFilter: null,
      checkinListFilterOn: ["visit_code", "hosts"],
      totalRowsCheckIn: 0,
      currentPage1: 1,
      perPage1: 5,
      pageOptions1: [5, 10, 15],
      checkTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "5%" }
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          visible: true,
          thStyle: { width: "20%" }
        },
        {
          key: "hosts",
          label: "Hosts",
          sortable: true,
          visible: true,
          thStyle: { width: "35%" }
        },
        {
          key: "visitors",
          label: "Visitors",
          sortable: true,
          visible: true,
          thStyle: { width: "auto" }
        },
        {
          key: "additional_metadata.check_in_time",
          label: "Check-in",
          sortable: true,
          visible: true,
          thStyle: { width: "auto" }
        },
        /* {
          key: "additional_metadata.check_out_time",
          label: "Check-out",
          sortable: true
        }, */
        {
          key: "actions",
          label: "Actions",
          visible: true,
          thStyle: { width: "auto" }
        }
      ],

      busy2: false,
      searchExpected: false,
      expectedList: [],
      expectedListFilter: null,
      expectedListFilterOn: ["visit_code", "hosts"],
      totalRowsExpected: 0,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15],
      expectedTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "5%" }
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          thStyle: { width: "20%" }
        },
        {
          key: "hosts",
          label: "Hosts",
          sortable: true,
          thStyle: { width: "35%" }
        },
        {
          key: "visitors",
          label: "Visitors",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visit_start_time",
          label: "Time",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ]
    };
  },
  watch: {
    getPreference: {
      deep: true,
      handler: function (newVal) {
        console.log("newVal", newVal);
      }
    }
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL",
      getIntervalId: "visit/getIntervalId"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    },
    visibleFields() {
      let result = null;
      let result2 = null;

      if (this.getPreference.additional_metadata != undefined) {
        console.log(
          "this.getPreference.additional_metadata.vms_check_out_auto",
          this.getPreference.additional_metadata.vms_check_out_auto
        );

        result2 = this.checkTableFields.filter((checkTableFields) =>
          checkTableFields.key == "actions" &&
          this.getPreference.additional_metadata.vms_check_out_auto
            ? (checkTableFields.visible = false)
            : (checkTableFields.visible = true)
        );

        console.log("result2", result2);

        return result2;
      } else {
        result = this.checkTableFields.filter(
          (checkTableFields) => checkTableFields
        );
        return result;
      }
    }
  },
  created() {
    this.getVisitStatistics();
    this.getData();
  },
  mounted() {
    this.timer();
    this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
  },
  /* unmounted() {
    this.$store.dispatch("session/logoutSession");
  }, */
  methods: {
    timer() {
      let that = this;
      let intervalId = window.setInterval(function () {
        console.log("polling...", intervalId);
        that.getData();
      }, 5000);
      this.$store.dispatch("visit/updateIntervalId", intervalId);
    },
    getData() {
      this.resetVisit();
      this.getVisitStatistics();
      this.getCheckInVisit();
      this.getExpectedVisitors();
    },
    resetVisit() {
      this.$store.dispatch("visit/reset");
    },
    getVisitStatistics() {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/statistics/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          //debugger; // eslint-disable-line no-debugger
          //data = { detail: "error" };
          this.errorMessageVisitStatistic = data?.detail;

          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            validUrl = key;
            break outer_loop;
          }

          this.loadCheckIn =
            this.getCheckInCounter ==
            data?.result?.[validUrl]?.checked_in_visitors
              ? false
              : true;

          this.loadCheckOut =
            this.getCheckOutCounter ==
            data?.result?.[validUrl]?.checked_out_visitors
              ? false
              : true;

          this.loadExpected =
            this.getExpectedVisitorCounter ==
            data?.result?.[validUrl]?.expected_visitors
              ? false
              : true;

          this.loadOverstay =
            this.getOverstayCounter ==
            data?.result?.[validUrl]?.overstay_visitors
              ? false
              : true;

          this.loadCounter =
            this.getUpcomingVisit == data?.result?.[validUrl]?.upcoming_visitors
              ? false
              : true;

          this.getCheckInCounter =
            data?.result?.[validUrl]?.checked_in_visitors ?? 0;
          this.getCheckOutCounter =
            data?.result?.[validUrl]?.checked_out_visitors ?? 0;
          this.getExpectedVisitorCounter =
            data?.result?.[validUrl]?.expected_visitors ?? 0;
          this.getOverstayCounter =
            data?.result?.[validUrl]?.overstay_visitors ?? 0;
          this.getUpcomingVisit =
            data?.result?.[validUrl]?.upcoming_visitors ?? 0;
          //}
        })
        .catch((err) => {
          console.log("err", err);
          this.show = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: this.errorMessageVisitStatistic,
            message_type: "danger"
          });
        });
    },
    getCheckInVisit() {
      this.busy1 = false;
      let newCheckInList = [];
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/checked-in/";
      let query1 = {};
      query1 = {
        page_index: this.currentPage1,
        page_size: this.perPage1
      };
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query1)
        .then((data) => {
          this.errorMessageCheckIn = data?.detail;

          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            validUrl = key;
            break outer_loop;
          }

          let value = null;
          outer_loop: for (let key in data?.result?.[validUrl]) {
            value = key;
            break outer_loop;
          }

          for (let key in data?.result?.[validUrl]?.[value]) {
            newCheckInList.push(data?.result?.[validUrl]?.[value]?.[key]);
          }
          this.checkInList = newCheckInList;

          this.totalRowsCheckIn = data?.result?.[validUrl]?.["total"] ?? 0;
          this.currentPage1 = data?.result?.[validUrl]?.["page"] ?? 1;
          this.perPage1 = data?.result?.[validUrl]?.["size"] ?? 5;

          this.busy1 = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: this.errorMessageCheckIn,
            message_type: "danger"
          });
        });
    },
    getExpectedVisitors() {
      this.busy2 = false;
      let newExpectedList = [];
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/expected/";
      let query2 = {};
      query2 = {
        page_index: this.currentPage2,
        page_size: this.perPage2
      };
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query2)
        .then((data) => {
          this.errorMessageExpectedVisitor = data?.detail;

          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            validUrl = key;
            break outer_loop;
          }

          let value = null;
          outer_loop: for (let key in data?.result?.[validUrl]) {
            value = key;
            break outer_loop;
          }

          for (let key in data?.result?.[validUrl]?.[value]) {
            newExpectedList.push(data.result[validUrl][value][key]);
          }
          this.expectedList = newExpectedList;

          this.totalRowsExpected = data?.result?.[validUrl]?.["total"] ?? 0;
          this.currentPage2 = data?.result?.[validUrl]?.["page"] ?? 1;
          this.perPage2 = data?.result?.[validUrl]?.["size"] ?? 5;

          this.busy2 = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: this.errorMessageExpectedVisitor,
            message_type: "danger"
          });
        });
    },

    handleCheckIn(visit_data) {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm('Confirm "Check-In" ?', {
          centered: true,
          okTitle: "Yes",
          cancelTitle: "No"
        })
        .then((value) => {
          if (value) {
            $this.visitID = visit_data.id;

            let API_URL =
              $this.getAPIServerURL +
              "/api/visitorservice/visit/check-in/?visit_id=" +
              $this.visitID;

            let metadata = visit_data.additional_metadata;

            metadata = {
              ...metadata,
              check_in_time: moment().unix()
            };

            let visit_data2 = {
              ...visit_data,
              additional_metadata: metadata
            };

            const client = $this.$root.getAjaxFetchClient();

            client
              .postRequest(API_URL, null)
              .then(() => {
                $this.$store.dispatch(
                  "visit/expectedListAddCheckIn",
                  visit_data2
                );
              })

              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    handleCheckOut(visit_data) {
      let $this = this;

      this.$bvModal
        .msgBoxConfirm(
          "This will remove all the access for this visitor. This action is immutable.",
          {
            title: 'Confirm "Check-Out" ?',
            centered: true,
            okTitle: "Yes",
            cancelTitle: "No"
          }
        )
        .then((value) => {
          if (value) {
            $this.visitID = visit_data.id;

            let API_URL =
              $this.getAPIServerURL +
              "/api/visitorservice/visit/check-out/?visit_id=" +
              $this.visitID;

            let metadata = visit_data.additional_metadata;

            metadata = {
              ...metadata,
              check_out_time: moment().unix()
            };

            let visit_data2 = {
              ...visit_data,
              additional_metadata: metadata,
              status: "COMPLETED"
            };

            const client = $this.$root.getAjaxFetchClient();

            client
              .postRequest(API_URL, null)
              .then(() => {
                $this.$store.dispatch("visit/updateCheckout", visit_data2);
                this.show = true;
                location.reload();
              })

              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    hasCheckOut(visit_data) {
      return Object.prototype.hasOwnProperty.call(
        visit_data["additional_metadata"],
        "check_out_time"
      );
    },

    handlePageChange1(page) {
      this.busy1 = true;
      this.checkInList = [];
      this.currentPage1 = page;
      this.getCheckInVisit();
    },
    handlePerPageChange1(pageSize) {
      this.busy1 = true;
      this.checkInList = [];
      this.perPage1 = pageSize;
      this.getCheckInVisit();
    },
    handlePageChange2(page) {
      this.busy2 = true;
      this.expectedList = [];
      this.currentPage2 = page;
      this.getExpectedVisitors();
    },
    handlePerPageChange2(pageSize) {
      this.busy2 = true;
      this.expectedList = [];
      this.perPage2 = pageSize;
      this.getExpectedVisitors();
    },

    showDate: function () {
      let date = new Date();
      let todayDate = moment(date).format("DD MMMM YYYY");
      return todayDate;
    },
    goNewVisit: function () {
      this.$router.push({ name: "create-visit" }).catch(() => {});
    },
    navigate() {
      this.$router.push({
        name: "visits",
        query: {
          keyword: this.keyword
        }
      });
    }
  },
  filters: {
    momentFormatTime(value) {
      return moment(value).format("HH:mm:ss");
    }
  },
  beforeDestroy() {
    try {
      clearInterval(this.getIntervalId);
    } catch (e) {
      console.log(e);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./VMSDashboard";
</style>
