<template>
  <div id="panel_building" class="h-100">
    <!-- Widgets -->
    <div class="panel-widgets" v-if="hasPermission">
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.ongoing_cases }}</div>
        <div class="text-header"><h2 class="mb-0">Ongoing Cases</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.floors_with_cases }}</div>
        <div class="text-header"><h2 class="mb-0">Floors with Cases</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.devices_offline }}</div>
        <div class="text-header"><h2 class="mb-0">Devices Offline</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.maintenance_today }}</div>
        <div class="text-header"><h2 class="mb-0">Maintenance Today</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.case_assignees }}</div>
        <div class="text-header"><h2 class="mb-0">Case Assignees</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_floors }}</div>
        <div class="text-header"><h2 class="mb-0">Total Floors</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_devices }}</div>
        <div class="text-header"><h2 class="mb-0">Total Devices</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.upcomming_maintenance }}</div>
        <div class="text-header">
          <h2 class="mb-0">Upcoming Maintenance</h2>
        </div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.ongoing_cases }}</div>
        <div class="text-header"><h2 class="mb-0">Ongoing Cases</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.floors_with_cases }}</div>
        <div class="text-header"><h2 class="mb-0">Floors with Cases</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.devices_offline }}</div>
        <div class="text-header"><h2 class="mb-0">Devices Offline</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.maintenance_today }}</div>
        <div class="text-header"><h2 class="mb-0">Maintenance Today</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.case_assignees }}</div>
        <div class="text-header"><h2 class="mb-0">Case Assignees</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_floors }}</div>
        <div class="text-header"><h2 class="mb-0">Total Floors</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_devices }}</div>
        <div class="text-header"><h2 class="mb-0">Total Devices</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.upcomming_maintenance }}</div>
        <div class="text-header">
          <h2 class="mb-0">Upcoming Maintenance</h2>
        </div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.devices_offline }}</div>
        <div class="text-header"><h2 class="mb-0">Devices Offline</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.maintenance_today }}</div>
        <div class="text-header"><h2 class="mb-0">Maintenance Today</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.case_assignees }}</div>
        <div class="text-header"><h2 class="mb-0">Case Assignees</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_floors }}</div>
        <div class="text-header"><h2 class="mb-0">Total Floors</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.total_devices }}</div>
        <div class="text-header"><h2 class="mb-0">Total Devices</h2></div>
      </div>
      <div class="panel-widget">
        <div class="text-counter">{{ widgets.upcomming_maintenance }}</div>
        <div class="text-header">
          <h2 class="mb-0">Upcoming Maintenance</h2>
        </div>
      </div>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Building from "@/components/PanelFloorplan/BuildingView/Building.vue";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingStatsView",
  components: {},
  data() {
    return {
      widgets: {
        ongoing_cases: 0,
        floors_with_cases: 0,
        devices_offline: 0,
        maintenance_today: 0,
        case_assignees: 0,
        total_floors: 0,
        total_devices: 0,
        upcomming_maintenance: 0,
        hasPermission: false
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        this.initView();
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("building_stats") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Building Stats View mounted");
    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("building_stats") >= 0) {
      this.hasPermission = true;
    }

    this.initView();
  },
  methods: {
    initView: function () {
      this.getWidgetData();
    },
    getWidgetData: function () {
      var widgets_data = {
        ongoing_cases: Math.floor(Math.random() * 10),
        floors_with_cases: Math.floor(Math.random() * 10),
        devices_offline: Math.floor(Math.random() * 10),
        maintenance_today: Math.floor(Math.random() * 10),
        case_assignees: Math.floor(Math.random() * 10),
        total_floors: Math.floor(Math.random() * 10),
        total_devices: Math.floor(Math.random() * 10),
        upcomming_maintenance: Math.floor(Math.random() * 10)
      };

      this.widgets = widgets_data;
    }
  }
};
</script>
