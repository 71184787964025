<template>
  <div class="workspace">
    <!--        <div class="bg-white">-->
    <!--          <pre>-->
    <!--            {{ getSelectedWorkspace }}-->
    <!--          </pre>-->
    <!--        </div>-->

    <grid-layout
      :layout.sync="getSelectedWorkspace.layout.panels"
      :col-num="12"
      :row-height="rowHeight"
      :max-rows="maxRows"
      :is-draggable="false"
      :is-resizable="false"
      :vertical-compact="true"
      :use-css-transforms="true"
      :autoSize="true"
    >
      <grid-item
        v-for="item in getSelectedWorkspace.layout.panels"
        :static="true"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="
          'grid_' +
          getSelectedWorkspace.id +
          '_' +
          getSelectedWorkspace.index +
          '_' +
          item.num +
          '_' +
          item.i +
          '_' +
          item.panel
        "
        class="panel"
        :class="[item.panel === 'floorplan' ? 'panel-full' : '']"
        :data-grid-width="item.w"
      >
        <!-- {{ item }} -->
        <PanelFloorplan v-if="item.panel === 'floorplan'"></PanelFloorplan>
        <PanelDevices v-else-if="item.panel === 'devices'"></PanelDevices>
        <PanelAlerts v-else-if="item.panel === 'alerts'"></PanelAlerts>
        <PanelFaceRecognition v-else-if="item.panel === 'face_recognition'">
        </PanelFaceRecognition>
        <PanelFaceSearch
          v-else-if="item.panel === 'face_search'"
        ></PanelFaceSearch>
        <PanelAbnormalStatus
          v-else-if="item.panel === 'abnormal_status'"
        ></PanelAbnormalStatus>
        <PanelMaintenance
          v-else-if="item.panel === 'maintenance'"
        ></PanelMaintenance>
        <PanelBuildingStats
          v-else-if="item.panel === 'building_stats'"
        ></PanelBuildingStats>
        <PanelLiveCameraView
          v-else-if="
            item.panel === 'live_camera_view' // && item.camera_ip !== undefined
          "
          :cameraIp="item.camera_ip"
          :videoFormat="item.video_format"
          :item="item"
          :workspaceId="getSelectedWorkspace.id"
        ></PanelLiveCameraView>
        <PanelLprEventCard
          v-else-if="item.panel === 'lpr_alerts'"
        ></PanelLprEventCard>
        <PanelLprStaffEventCard
          v-else-if="item.panel === 'lpr_staff_alerts'"
          :item="item"
          :workspaceId="getSelectedWorkspace.id"
        ></PanelLprStaffEventCard>
        <PanelLprEventTable
          v-else-if="item.panel === 'lpr_transactions'"
          :item="item"
          :workspaceId="getSelectedWorkspace.id"
        ></PanelLprEventTable>
        <PanelLprSearch
          v-else-if="item.panel === 'lpr_search'"
        ></PanelLprSearch>
        <VMSDashboard
          v-else-if="
            item.panel === 'vms_transaction'
            /* && getCurrentUser.panels.length > 0 &&
            getCurrentUser.pages.length > 0 */
          "
        ></VMSDashboard>
        <VMSHostDashboard
          v-else-if="item.panel === 'vms_host_invite'"
        ></VMSHostDashboard>
        <!-- <Error
          v-else-if="
            getCurrentUser.panels.length == 0 &&
            getCurrentUser.pages.length == 0
          "
        ></Error> -->
      </grid-item>
    </grid-layout>
    <!-- <DeviceDetails></DeviceDetails>-->
    <!-- <AlertDetails /> -->
    <!-- <CaseDetails />-->
    <LprAlertDetails />
  </div>
</template>

<script>
// import PanelCases from "@/components/PanelCases/PanelCases";
// import DeviceDetails from "@/components/FloorplanDevices/DeviceDetails/DeviceDetails";
// import AlertDetails from "@/components/AlertDetails/AlertDetails";
// import CaseDetails from "@/components/CaseDetails/CaseDetails";
import { mapGetters } from "vuex";
import moment from "moment";
import Error from "@/pages/Error/App.vue";
import VueGridLayout from "vue-grid-layout";
import { GridLayout, GridItem } from "vue-grid-layout";
import WebsocketHeartbeatJs from "websocket-heartbeat-js";

import PanelFloorplan from "@/components/PanelFloorplan/PanelFloorplan";
import PanelBuildingStats from "@/components/PanelBuilding/PanelBuildingStats";
import PanelAlerts from "@/components/PanelAlerts/PanelAlerts";
import PanelFaceRecognition from "@/components/PanelFaceRecognition/PanelFaceRecognition";
import PanelFaceSearch from "@/components/PanelFaceSearch/PanelFaceSearch";
import PanelDevices from "@/components/PanelDevices/PanelDevices";
import PanelAbnormalStatus from "@/components/PanelDevices/PanelAbnormalStatus";
import PanelMaintenance from "@/components/PanelDevices/PanelMaintenance";
import PanelLiveCameraView from "@/components/PanelLpr/PanelLiveCameraView";
import PanelLprEventCard from "@/components/PanelLpr/PanelLprEventCard";
import PanelLprStaffEventCard from "@/components/PanelLpr/PanelLprStaffEventCard";
import PanelLprEventTable from "@/components/PanelLpr/PanelLprEventTable";
import PanelLprSearch from "@/components/PanelLpr/PanelLprSearch";
import VMSDashboard from "@/components/PanelVMS/VMSDashboard";
import VMSHostDashboard from "@/components/PanelVMS/VMSHostDashboard";
import LprAlertDetails from "@/components/LprAlertDetails/LprAlertDetails";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PSIM",
  components: {
    PanelFloorplan,
    PanelBuildingStats,
    PanelAlerts,
    PanelFaceRecognition,
    PanelFaceSearch,
    PanelDevices,
    PanelAbnormalStatus,
    PanelMaintenance,
    PanelLiveCameraView,
    PanelLprEventCard,
    PanelLprStaffEventCard,
    PanelLprEventTable,
    PanelLprSearch,
    LprAlertDetails,
    VMSDashboard,
    VMSHostDashboard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
    // DeviceDetails,
    // AlertDetails,
    // Error,
  },
  data() {
    return {
      componentName: "PSIM",
      rowHeight: 50,
      maxRows: 12,
      alertWS: null,
      alertWSToken: "",
      alertTopics: "",
      wsReconnectCount: 0,
      wsReconnectMax: 5,
      buildingID: null,
      isExpandMode: false,
      getImageQueue: {}
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getViewingLocation: "psim/getViewingLocation",
      getViewingDetails: "psim/getViewingDetails",
      getSelectedWorkspace: "psim/getSelectedWorkspace",
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      isCamerasInitiated: "psim/isCamerasInitiated",
      isDoorsInitiated: "psim/isDoorsInitiated",
      isDevicesInitiated: "psim/isDevicesInitiated",
      getAPIServerURL: "session/getAPIServerURL",
      getWSServerURL: "session/getWSServerURL",
      getWSServerKey: "session/getWSServerKey"
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (this.isBuildingsInitiated) {
          this.initView();
        }
      }
    },
    isCamerasInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.connectAlertWS();
        }
      },
      deep: true
    },
    isBuildingsInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.initView();
        }
      },
      deep: true
    },
    isDevicesInitiated: {
      handler: function (n, o) {
        // console.log("isDevicesInitiated:", n);
      }
    },
    getWSServerURL: {
      handler: function (n, o) {
        let msg = "Reconnecting websocket URL ...";
        let msg_dict = { message_text: msg, message_type: "success" };
        this.$store.dispatch("session/addGlobalAlertMessage", msg_dict);
        this.disconnectAlertWS();
        this.connectAlertWS();
      }
    }
    // getCurrentUser: {
    //   handler: function (n, o) {
    //     if (n == null) {
    //       this.disconnectAlertWS();
    //     }
    //   },
    //   deep: true
    //   // immediate: true,
    // },
  },
  created() {
    // console.log("PSIM Created");
    if (this.getCurrentUser != null) {
      this.getAllUdfMapping();
      this.disconnectAlertWS();
      this.connectAlertWS();
      this.getEvents();
      //this.timer();
    }
  },
  unmounted: function () {
    this.disconnectAlertWS();
  },
  methods: {
    async getAllUdfMapping() {
      let API_URL =
        this.getAPIServerURL + "/api/acsservice/udf-field-mapping/all/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);

      let flag = this.checkUdfMapping(res.result);
      if (!flag) {
        await this.setupInitialUdfMapping();
      }
    },
    checkUdfMapping(result) {
      for (const url in result) {
        if (result[url] != null) {
          if (result[url]["result"].length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    async setupInitialUdfMapping() {
      let acsUrl = await this.getAcsUrl();
      // console.log("acsUrl", acsUrl);
      if (acsUrl != null) {
        let data = {
          acs_url: acsUrl,
          data: {
            udF1: "car_plate_no_1",
            udF2: "car_plate_no_2",
            udF3: "car_plate_no_3"
          }
        };
        const client = this.$root.getAjaxFetchClient();
        client
          .postRequest(
            this.getAPIServerURL + "/api/acsservice/udf-field-mapping/",
            data
          )
          .then((res) => {
            // console.log("res udf-field-mapping");
            // console.log(res);
          })
          .catch((err) => {
            // console.log("err udf-field-mapping");
            // console.log(err);
          });
      }
    },
    async getAcsUrl() {
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1"
      );

      let result = res.result;
      for (const url in result) {
        if (result[url] != null) {
          if (result[url]["result"].length > 0) {
            return result[url]["result"][0];
          }
        }
      }
      return null;
    },
    renderPanelClass: function (width, height, full, panel_name) {
      let width_class = "w-" + width;
      let height_class = "h-" + height;

      let panel_class = {};
      panel_class[width_class] = true;
      panel_class[height_class] = true;
      panel_class["panel-full"] = full;
      panel_class["panel-" + panel_name] = true;
      return panel_class;
    },
    calculateRowHeight: function () {
      if (sessionStorage.isExpanded == undefined) {
        sessionStorage.isExpanded = false;
      }
      this.isExpandMode = sessionStorage.isExpanded == "true";
      // console.log("calculateRowHeight - is expanded view: ", this.isExpandMode);
      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      if (this.isExpandMode) {
        this.rowHeight = (height - 130) / this.maxRows;
      } else {
        this.rowHeight = (height - 170) / this.maxRows;
      }
      // console.log("height:", height, "rowHeight:", this.rowHeight);
    },
    connectAlertWS: function () {
      var $this = this;
      if ($this.alertTopics == "") {
        $this.alertTopics = "psim_alerts,lpr_alerts";
      }

      try {
        if ($this.alertWS != null) {
          $this.alertWS.close();
        }
      } catch (err) {
        // console.log(err);
      }
      $this.alertWS = null;
      // console.log(process.env.VUE_APP_WSSERVER)
      var ws_url =
        $this.getWSServerURL + "/events/?key=" + $this.getWSServerKey;
      var ws_ping_param = { action: 0 };

      let camera_ws = new WebsocketHeartbeatJs({
        url: ws_url,
        pingMsg: JSON.stringify(ws_ping_param),
        pingTimeout: 10000,
        pongTimeout: 20000,
        repeatLimit: $this.wsReconnectMax - 1
      });

      // console.log(camera_ws);

      camera_ws.onopen = (e) => {
        console.log("camera_ws: Opening ...");

        var param = {
          action: 1,
          topics: $this.alertTopics
        };

        // Subscribe camera
        camera_ws.send(JSON.stringify(param));
        $this.wsReconnectCount = 0;
        console.log("camera_ws: Ready...");
      };
      camera_ws.onerror = (e) => {
        console.log("camera_ws: Connection Error ... ");
        $this.wsReconnectCount += 1;
      };
      camera_ws.onclose = (e) => {
        console.log("camera_ws: Closing ...");
        if ($this.wsReconnectCount == $this.wsReconnectMax) {
          let err = "Could not connect to " + $this.getWSServerURL + " ...";
          let err_dict = { message_text: err, message_type: "warning" };
          $this.$store.dispatch("session/addGlobalAlertMessage", err_dict);
        }
      };
      camera_ws.onreconnect = (e) => {
        console.log("camera_ws: Reconnecting...");
      };
      camera_ws.onmessage = function (result) {
        // console.log(`camera_ws onmessage: ${result.data}`);

        var result_data = JSON.parse(result.data);
        var result_type = result_data.type;
        let data = null;

        try {
          if (result_type == "close") {
            console.log("camera_ws: Received message to close websocket ...");
            $this.disconnectAlertWS();
          } else if (result_type == "auth") {
            $this.alertWSToken = result_data.session_key;
            // console.log('session_key: ' + cameraws_token);
          } else if (result_type == "error") {
            data = result_data.msg;
            // console.log("error");
            // console.log(data);
          } else if (result_type == "msg") {
            data = result_data.msg;
            // console.log("msg");
            // console.log(data);
          } else if (result_type == "info") {
            data = result_data.msg;
            // console.log("info");
            // console.log(data);
          } else if (result_type == "heartbeat") {
            // console.log("heartbeat");
            // refreshTimesince(null);
            // checkEmptyEventImage();
          } else if (result_type == "events") {
            data = result_data.data;

            try {
              data = utils.canopy_decoder(data, $this.alertWSToken);
              data = JSON.parse(data);
            } catch (err) {
              if (
                $this.alertWSToken == "insecure" ||
                $this.alertWSToken == "" ||
                $this.alertWSToken == null
              ) {
                // do nothing
              } else {
                // console.log("Decrypt error", err);
              }
            }
            //
            // console.log("Decrypted data");
            // console.log(data);

            var alert_id = String(data.data.event_id);
            var alert_data = { id: alert_id, data: data };

            $this.$store.dispatch("alertWS/addRawAlert", alert_data);

            $this.createAlert(data);
          }
        } catch (err) {
          // console.log(err);
        }
      };

      $this.alertWS = camera_ws;
    },
    disconnectAlertWS: function () {
      if (this.alertWS != null) {
        console.log("camera_ws: Unsubscribing ...");

        let param = {
          action: 2,
          topics: this.alertTopics
        };

        // Unsubscribe camera
        this.alertWS.send(JSON.stringify(param));
        console.log("camera_ws: Unsubscribed ...");

        this.alertWS.close();
      }
    },
    fetchEventImage: async function (alert_id, event_image_id) {
      const $this = this;
      let count = 0;

      $this.getImageQueue[String(alert_id)] = false;

      let fetchFunc = function () {
        $this._fetchEventImage(String(alert_id), String(event_image_id));
        count += 1;
      };
      fetchFunc();

      setTimeout(function _fetch() {
        fetchFunc();
        if (count < 5 && $this.getImageQueue[String(alert_id)] == false) {
          setTimeout(_fetch, 1000);
        }
      }, 1000); // stop it after 5 seconds
    },
    _stopFetch: function (alert_id) {
      const $this = this;
      $this.getImageQueue[String(alert_id)] = true;
    },
    _fetchEventImage: function (alert_id, event_image_id) {
      const $this = this;
      const API_URL =
        $this.getAPIServerURL + "/api/event/get-image/?id=" + event_image_id;
      const client = $this.$root.getAjaxFetchClient({
        responseType: "blob"
      });

      client
        .getRequest(API_URL)
        .then((response) => {
          if (response.type == "image/jpeg") {
            let image_src = URL.createObjectURL(response);
            $this._storeEventImage(String(alert_id), image_src);
          }
          return response;
        })
        .catch((err) => {
          let error_msg = err;
          if (err.detail != undefined) {
            error_msg = err.detail;
          }
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: error_msg,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });

      // return image_url;
    },
    _storeEventImage: function (alert_id, result) {
      const $this = this;

      let alert_data = $this.$store.getters["alertWS/getAlert"](
        String(alert_id)
      );
      if (alert_data) {
        alert_data["thumbnail"] = result;
        alert_data["event_images"] = [result];
      }
      let updated_data = {
        thumbnail: result
        // "event_images": [result],
      };
      $this.$store.dispatch("alertWS/addAlert", {
        id: String(alert_id),
        data: alert_data
      });
      $this.$store.dispatch("alertWS/addUpdatedAlert", {
        id: String(alert_id),
        data: updated_data
      });
      $this._stopFetch(String(alert_id));
    },

    initView: function () {
      // console.log("PSIM - InitView =================");
      // console.log("this.$route.query");
      // console.log(this.$route.query);
      let location = {
        building: null,
        floor: null,
        zone: null,
        pos: null,
        device_id: null
      };
      let buildingID = null;
      let floorID = null;
      let zoneID = null;
      let posX = null;
      let posY = null;
      let deviceID = null;

      if (this.$route.query.building != undefined) {
        buildingID = parseInt(this.$route.query.building);
      }
      if (this.$route.query.floor != undefined) {
        floorID = parseInt(this.$route.query.floor);
      }
      if (this.$route.query.zone != undefined) {
        zoneID = parseInt(this.$route.query.zone);
      }
      if (this.$route.query.pos_x != undefined) {
        posX = parseFloat(this.$route.query.pos_x);
      }
      if (this.$route.query.pos_y != undefined) {
        posY = parseFloat(this.$route.query.pos_y);
      }
      if (this.$route.query.device_id != undefined) {
        deviceID = this.$route.query.device_id;
      }

      if (buildingID != null) {
        let buildingObj = this.$store.getters["psim/getBuilding"](buildingID);
        location["building"] = buildingObj;

        if (floorID != null) {
          let floorObj = this.$store.getters["psim/getFloor"](
            buildingID,
            floorID
          );
          location["floor"] = floorObj;

          if (zoneID != null) {
            let zoneObj = this.$store.getters["psim/getZone"](
              buildingID,
              floorID,
              zoneID
            );
            location["zone"] = zoneObj;
          }

          if (posX != null && posY != null) {
            location["pos"] = { x: posX, y: posY };
          }

          if (deviceID != null) {
            location["device_id"] = deviceID;
          }
        }
      }

      this.$store.dispatch("psim/setViewingLocation", location);
    },
    /* timer() {
      let that = this;
      this.intervalId = window.setInterval(function () {
        console.log("polling...", this.intervalId);
        that.getEvents();
      }, 5000);
      this.$store.dispatch("alertWS/updateIntervalId", this.intervalId);
    }, */
    getEvents() {
      this.$store.dispatch("alertWS/resetAlert");
      let $this = this;
      let query = {};
      query = {
        start_date: moment().subtract(1, "days").toISOString(),
        end_date: moment().toISOString(),
        event_type: "face",
        limit: 10
        //subevent_type: $this.queryForm.subevent_type
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((response) => {
          if (response.detail != undefined) {
            //
          } else {
            let result = response.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                validUrl = key;
                break outer_loop;
              }
            }

            console.log(
              "result[validUrl]['result']",
              result[validUrl]["result"].length
            );
            if (result[validUrl]["result"].length > 0) {
              for (let key in result[validUrl]["result"]) {
                let alert_id = String(
                  result[validUrl]["result"][key].data.data.event_id
                );
                let alert_data = {
                  id: alert_id,
                  data: result[validUrl]["result"][key].data
                };

                this.$store.dispatch("alertWS/addRawAlert", alert_data);
                this.createAlert(result[validUrl]["result"][key].data);
              }
            }
          }
        })
        .catch((err) => {
          console.log("error", err);
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Please try again later",
            message_type: "danger"
          });
        });
    },
    createAlert: function (alert) {
      // console.log("Create Alert")
      // console.log(alert);
      let $this = this;
      if (
        alert.data.event_id == undefined ||
        alert.data.event_id == null ||
        alert.data.event_id == "$event_id"
      ) {
        return "";
      }

      let alert_id = alert.data.event_id;
      let alert_device_ip = alert.data.device_ip;
      let alert_device_url = alert.data.device_url;
      let alert_device_type = alert.data.device_type;

      // object_str
      let alert_title_parts = [];
      if (
        alert.data.object_str != "" &&
        alert.data.object_str != null &&
        alert.data.object_str != "None"
      ) {
        alert_title_parts.push(alert.data.object_str);
      }
      // event_type
      let event_str = null;
      if (alert.type != undefined) {
        event_str = alert.type;
      } else {
        alert.type = alert.subevent_type;
      }

      if (alert.type == "camera_line_crossed") {
        event_str = "Crossed Line";
      } else if (alert.type == "camera_area_entered") {
        event_str = "Entered Area";
      } else if (alert.type == "camera_area_exited") {
        event_str = "Exited Area";
      } else if (alert.type == "camera_area_appear") {
        event_str = "Appeared in Area";
      } else if (alert.type == "camera_area_loitering") {
        event_str = "Loitering";
      } else if (alert.type == "camera_area_intrusion") {
        event_str = "Intruded Area";
      } else if (alert.type == "camera_detect_motion") {
        event_str = "Motion Detected";
      } else if (alert.type == "camera_detect_tampering") {
        event_str = "Tampering Detected";
      } else if (alert.type == "camera_detect_defocus") {
        event_str = "Defocus Detected";
      } else if (alert.type == "camera_detect_fog") {
        event_str = "Fog Detected";
      } else if (alert.type == "camera_detect_audio") {
        event_str = "Audio Detected";
      } else if (alert.type == "camera_detect_fallen") {
        event_str = "Fallen";
      } else if (alert.type == "face_recognized") {
        event_str = "Recognized";
      } else if (alert.type == "face_recognized_in") {
        event_str = "Recognized (In frame)";
      } else if (alert.type == "face_recognized_out") {
        event_str = "Recognized (Out of frame)";
      } else if (alert.type == "face_recognized_remove") {
        event_str = "Recognized (Removed from frame)";
      } else if (alert.type == "face_anonymous") {
        event_str = "Detected";
      } else if (alert.type == "face_anonymous_in") {
        event_str = "Detected (In frame)";
      } else if (alert.type == "face_anonymous_out") {
        event_str = "Detected (Out of frame)";
      } else if (alert.type == "face_anonymous_remove") {
        event_str = "Detected (Removed from frame)";
      } else if (alert.type == "face_detected") {
        event_str = "Detected";
      } else if (alert.type == "face_detected_in") {
        event_str = "Detected (In frame)";
      } else if (alert.type == "face_detected_out") {
        event_str = "Detected (Out of frame)";
      } else if (alert.type == "face_detected_remove") {
        event_str = "Detected (Removed from frame)";
      } else if (alert.type == "camera_connected") {
        event_str = "Connected";
      } else if (alert.type == "camera_disconnected") {
        event_str = "Disconnected";
      } else if (alert.type == "controller_connected") {
        event_str = "Connected";
      } else if (alert.type == "controller_disconnected") {
        event_str = "Disconnected";
      } else if (alert.type == "server_connected") {
        event_str = "Connected";
      } else if (alert.type == "server_disconnected") {
        event_str = "Disconnected";
      } else if (alert.type == "door_open") {
        event_str = "Opened";
      } else if (alert.type == "door_event") {
        event_str = "Event";
      } else if (alert.type == "door_status") {
        event_str = "Status";
      } else if (alert.type == "acs_person_added") {
        event_str = "Added";
      } else if (alert.type == "acs_person_updated") {
        event_str = "Updated";
      } else if (alert.type == "acs_person_deleted") {
        event_str = "Deleted";
      } else if (alert.type == "acs_card_reserved") {
        event_str = "Reserved";
      } else if (alert.type == "acs_card_unreserved") {
        event_str = "Unreserved";
      } else if (alert.type == "license_plate_detected") {
        event_str = "Detected";
      } else if (alert.type == "log_added") {
        event_str = "Log Added";
      } else {
        let event_str_list = alert.type.split("_");
        event_str = event_str_list.join(" ");
        event_str = utils.titleCase(event_str, " ");
      }

      if (
        alert.data.metadata != undefined &&
        alert.data.metadata.event_description
      ) {
        event_str = alert.data.metadata.event_description;
      }

      // Construct alert title
      alert_title_parts.push(event_str);
      let alert_title = alert_title_parts.join(" ");
      alert_title = utils.titleCase(alert_title, " ");

      // Extract priority and event images from metadata
      let event_metadata = {};
      let event_images = [];
      let event_priority = 1;
      let event_image_id = null;
      let matched_libraryimage = {};
      if (alert.data.metadata != undefined) {
        for (let k in alert.data.metadata) {
          if (k == "event_image") {
            if (alert.data.metadata[k] != "") {
              event_images = [utils.cleanBase64ImgSrc(alert.data.metadata[k])];
            }
          } else if (k == "event_images") {
            for (let e in alert.data.metadata[k]) {
              event_images.push(
                utils.cleanBase64ImgSrc(alert.data.metadata[k][e])
              );
            }
          } else if (k == "event_image_id") {
            event_image_id = alert.data.metadata[k];
          } else if (k == "event_priority") {
            event_priority = alert.data.metadata[k];
          } else if (k == "library_name") {
            matched_libraryimage["library_name"] = alert.data.metadata[k];
          } else if (k == "library_id") {
            matched_libraryimage["library_id"] = alert.data.metadata[k];
          } else if (k == "library_type") {
            matched_libraryimage["library_type"] = alert.data.metadata[k];
          } else if (k == "libraryimage_id") {
            matched_libraryimage["libraryimage_id"] = alert.data.metadata[k];
          } else if (k == "libraryimage_profile_id") {
            matched_libraryimage["libraryimage_profile_id"] =
              alert.data.metadata[k];
          } else if (k == "libraryimage_profile_name") {
            matched_libraryimage["libraryimage_profile_name"] =
              alert.data.metadata[k];
          } else if (k == "libraryimage_image") {
            matched_libraryimage["libraryimage_image"] =
              utils.cleanBase64ImgSrc(alert.data.metadata[k]);
          } else if (k == "face_info") {
            for (let _k in alert.data.metadata[k]) {
              event_metadata[_k] = alert.data.metadata[k][_k];
            }
          } else {
            if (alert.data.metadata[k] !== "") {
              event_metadata[k] = alert.data.metadata[k];
            }
          }
        }
      }

      // console.log(event_metadata);
      if (matched_libraryimage["libraryimage_image"] != undefined) {
        matched_libraryimage["event_image"] = event_images[0];
      }

      let event_thumbnail = "";
      let isFetchImageAsync = false;
      if (event_images.length > 0) {
        event_thumbnail = utils.cleanBase64ImgSrc(event_images[0]);
      } else {
        if (alert.data.device_type == "camera") {
          if (alert.type == "camera_connected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_camera.jpg");
          } else if (alert.type == "camera_disconnected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_camera_dc.jpg");
          } else {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_camera.jpg");
          }
        } else if (alert.data.device_type == "door") {
          if (alert.type == "controller_connected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_door.jpg");
          } else if (alert.type == "controller_disconnected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_door_dc.jpg");
          } else {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_door.jpg");
          }
        } else if (alert.data.device_type == "server") {
          if (alert.type == "server_connected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_server.jpg");
          } else if (alert.type == "server_disconnected") {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_server_dc.jpg");
          } else {
            event_thumbnail = require("@/assets/global/images/icons/placeholder_server.jpg");
          }
        }

        if (event_image_id != null && event_image_id != "") {
          isFetchImageAsync = true;
        }
      }

      let alert_device = null;
      let device_qs = null;
      let alert_card_info = [];
      // console.log(alert_device_ip);
      if (alert_device_type == "camera") {
        const alert_device_list = $this.$store.getters["psim/getCameras"]({
          ip: alert_device_ip
        });
        if (alert_device_list.length > 0) {
          alert_device = alert_device_list[0];
        }
      } else if (alert_device_type == "door") {
        let door_no = event_metadata["door_no"];
        let alert_device_id = alert_device_url;
        if (door_no != undefined) {
          alert_device_id = alert_device_url + "_door_" + door_no;
        }
        const alert_device_list = $this.$store.getters["psim/getDoors"]({
          id: alert_device_id
        });
        if (alert_device_list.length > 0) {
          alert_device = alert_device_list[0];
        }
      }

      if (alert.type == "door_event") {
        if (event_metadata["card_no"] != undefined) {
          let card_info = {
            key: "card_no",
            name: "Card no",
            value: event_metadata["card_no"]
          };
          alert_card_info.push(card_info);
        }
        if (matched_libraryimage["libraryimage_profile_name"] != undefined) {
          let card_info = {
            key: "libraryimage_profile_name",
            name: "Person",
            value: matched_libraryimage["libraryimage_profile_name"]
          };
          alert_card_info.push(card_info);
        }
      }

      let timestamp = null;
      if (alert.data.timestamp != undefined) {
        timestamp = alert.data.timestamp;
      } else {
        timestamp = alert.timestamp;
      }
      let alert_data = {
        id: alert_id,
        title: alert_title,
        alert_type: alert.type,
        created: moment(timestamp * 1000).toISOString(),
        event_images: event_images,
        event_image_id: event_image_id,
        priority: event_priority,
        thumbnail: event_thumbnail,
        matched_libraryimage: matched_libraryimage,
        building: { id: null, name: null },
        floor: { id: null, name: null },
        zone: { id: null, name: null },
        device_name: alert_device_type + " " + alert_device_ip,
        device_ip: alert_device_ip,
        device_type: alert_device_type,
        metadata: event_metadata,
        card_info: alert_card_info,
        is_discarded: false
      };

      if (alert_device != null && alert_device != undefined) {
        alert_data["building"] = alert_device.building;
        alert_data["floor"] = alert_device.floor;
        alert_data["zone"] = alert_device.zone;
        alert_data["device_name"] = alert_device.name;

        this.$store.dispatch("psim/addAlertToDevice", {
          device_type: alert_device_type,
          device_id: alert_device.id,
          alert_id: alert_id
        });
      }
      //console.log(alert_data.id, alert_data.alert_type, alert_data)

      this.$store.dispatch("alertWS/addAlert", {
        id: alert_id,
        data: alert_data
      });

      if (isFetchImageAsync) {
        $this.fetchEventImage(alert_id, event_image_id);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
