<template>
  <div class="d-flex">
    <div class="panel-zoom-container" v-show="display">
      <div class="panel-zoom-out mr-2" @click="zoomOut">
        <i class="fa fa-search-minus"></i>
      </div>
      <b-form-input
        id="zoom_slider"
        ref="zoomSlider"
        class="panel-zoom-slider mr-2"
        v-model="dimensions.zoomLevel"
        type="range"
        min="1"
        max="100"
        @change="dragZoom"
      ></b-form-input>
      <div class="panel-zoom-in mr-2" @click="zoomIn">
        <i class="fa fa-search-plus"></i>
      </div>
      <b-button size="sm" variant="outline-light" @click="resetFloorplan"
        >Reset</b-button
      >
    </div>
    <div class="ml-2" v-show="display">
      <b-dropdown id="dropdown-form" ref="dropdown" size="sm" right>
        <template #button-content>
          <i class="fa fa-tools"></i>
        </template>
        <b-dropdown-form>
          <b-form-checkbox
            class="mb-1"
            v-model="viewCameras"
            :value="true"
            :unchecked-value="false"
            switch
            >Cameras</b-form-checkbox
          >
          <b-form-checkbox
            v-model="viewDoors"
            :value="true"
            :unchecked-value="false"
            switch
            >Doors</b-form-checkbox
          >
        </b-dropdown-form>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "FloorplanViewToolbar",
  props: {
    display: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      dimensions: {
        zoomLevel: 0
      },
      viewCameras: true,
      viewDoors: true
    };
  },
  watch: {
    viewCameras: {
      handler: function (n, o) {
        this.toggleViewCameras(n);
      }
    },
    viewDoors: {
      handler: function (n, o) {
        this.toggleViewDoors(n);
      }
    }
  },
  mounted: function () {
    //
  },
  methods: {
    dragZoom: function (value) {
      this.$emit("drag-zoom", parseInt(value));
    },
    zoomOut: function () {
      this.$emit("zoom-out");
    },
    zoomIn: function () {
      this.$emit("zoom-in");
    },
    resetFloorplan: function () {
      this.$emit("reset-floorplan");
    },
    changeZoomLevel: function (value) {
      this.dimensions.zoomLevel = parseInt(value);
    },
    toggleViewCameras: function (value) {
      this.$emit("toggle-view-cameras", value);
    },
    toggleViewDoors: function (value) {
      this.$emit("toggle-view-doors", value);
    }
  }
};
</script>
