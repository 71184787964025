<template>
  <div class="panel-content">
    <div class="panel-content-header">
      <h2>Abnormal Status</h2>
    </div>
    <div v-if="hasPermission">
      <div
        class="panel-content-body h-45 scroller mb-4 text-black p-2 border-radius-card"
        v-for="device in table_items"
        :key="device.type"
        :class="[device.stats.count > 0 ? 'bg-danger' : 'bg-secondary']"
      >
        <div class="d-flex align-items-start">
          <div class="device-abnormal-counter-section">
            <div class="mb-2 d-inline-flex">
              <img
                :src="
                  require('@/assets/global/images/icons/camera_offline.png')
                "
                class="stats-icon m-auto"
                v-if="device.type == 'camera'"
              />
              <img
                :src="require('@/assets/global/images/icons/door.png')"
                class="stats-icon m-auto"
                v-if="device.type == 'door'"
              />
              <span class="stats-counter font-weight-bold ml-2">{{
                device.stats.count
              }}</span>
            </div>
            <h3>{{ device.label }}</h3>
          </div>
          <div class="device-abnormal-table-section">
            <b-table
              striped
              small
              borderless
              :items="device.data"
              :fields="table_fields"
              :class="[
                device.stats.count > 0
                  ? 'table-striped-danger'
                  : 'table-striped-secondary'
              ]"
            >
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelDevices",
  data() {
    return {
      table_fields: [],
      table_items: [],
      building: null,
      hasPermission: false
    };
  },
  computed: {
    ...mapGetters({
      isDevicesInitiated: "device/isDevicesInitiated",
      getCameras: "device/getCameras",
      getDoors: "device/getDoors",
      getCurrentUser: "session/getCurrentUser"
    })
  },
  watch: {
    getCameras: {
      handler: function (n, o) {
        this.fetchDevices();
      },
      deep: true
    },
    getDoors: {
      handler: function (n, o) {
        this.fetchDevices();
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("abnormal_status") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Panel Abnormal Status mounted");
    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("abnormal_status") >= 0) {
      this.hasPermission = true;
    }

    // init data
    if (this.isDevicesInitiated) {
      this.fetchDevices();
    }
  },
  methods: {
    fetchDevices: function () {
      // console.log("Checking for offline devices...");
      var data_list = [];
      var devices_offline_filter = { status: 0 };
      var cameras_offline = this.$store.getters["device/filterCameras"](
        devices_offline_filter
      );
      var doors_offline = this.$store.getters["device/filterDoors"](
        devices_offline_filter
      );

      for (var c in cameras_offline) {
        if (cameras_offline[c].location == undefined) {
          cameras_offline[c].location = "n/a";
        }
      }

      for (var d in doors_offline) {
        if (doors_offline[d].location == undefined) {
          doors_offline[d].location = "n/a";
        }
      }
      // console.log("test");

      data_list[0] = {
        type: "camera",
        label: "Cameras Offline",
        data: cameras_offline,
        stats: {
          count: cameras_offline.length
        }
      };
      data_list[1] = {
        type: "door",
        label: "Doors Offline",
        data: doors_offline,
        stats: {
          count: doors_offline.length
        }
      };

      this.table_items = data_list;

      this.table_fields = [
        {
          key: "ip",
          label: "IP",
          sortable: true,
          thStyle: "width: 120px;"
        },
        {
          key: "location",
          label: "Location",
          sortable: true
        }
      ];
      // end fetchDevices
    }
  }
};
</script>

<style lang="scss">
@import "./PanelAbnormalStatus.scss";
</style>
