<template>
  <div class="" ref="navBar">
    <b-button
      size="sm"
      class="mr-2 mb-1"
      v-show="shownBuilding.id != null"
      @click="goBack"
    >
      <i class="fa fa-chevron-left mr-1"></i>{{ goBackText }}
    </b-button>

    <b-dropdown
      split
      left
      dropdown
      size="sm"
      class="mr-2 mb-1"
      @click="goToBuilding(shownBuilding.id)"
    >
      <template #button-content v-if="shownBuilding.name == null">
        Choose building
      </template>
      <template #button-content v-else>
        {{ shownBuilding.name }}
      </template>

      <b-dropdown-header> Go to building </b-dropdown-header>
      <b-dropdown-item-button
        v-for="building in buildingOptions"
        :key="building.id"
        @click="selectBuilding(building.id)"
      >
        {{ building.name }}
      </b-dropdown-item-button>
    </b-dropdown>

    <b-dropdown
      split
      left
      dropdown
      size="sm"
      class="mr-2 mb-1"
      @click="goToFloor(shownBuilding.id, shownFloor.id)"
      v-if="isViewingBuilding || isViewingFloor"
    >
      <template #button-content v-if="shownFloor.name == null">
        Choose floor
      </template>
      <template #button-content v-else>
        {{ shownFloor.name }}
      </template>

      <b-dropdown-header> Go to floor </b-dropdown-header>
      <b-dropdown-item-button
        v-for="floor in floorOptions"
        :key="floor.id"
        @click="selectFloor(shownBuilding.id, floor.id)"
      >
        {{ floor.name }}
      </b-dropdown-item-button>
    </b-dropdown>
    <b-dropdown
      left
      dropdown
      size="sm"
      class="mb-1"
      v-if="zoneOptions.length > 0 && isViewingFloor"
    >
      <template #button-content v-if="shownZone.name == null">
        Choose zone
      </template>
      <template #button-content v-else>
        {{ shownZone.name }}
      </template>

      <b-dropdown-header> Go to zone </b-dropdown-header>
      <b-dropdown-item-button
        v-for="zone in zoneOptions"
        :key="zone.id"
        @click="selectZone(shownBuilding.id, shownFloor.id, zone.id)"
      >
        {{ zone.name }}
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "FloorNavigator",
  data() {
    return {
      // TOCLEAN
      isViewingFloor: false,
      isViewingBuilding: false,
      isViewingBuildingList: false,
      // shownCompany: {"id": null, "name": null},
      shownBuilding: { id: null, name: null },
      shownFloor: { id: null, name: null },
      // selectedCompany: {"id": null, "name": null},
      selectedBuilding: { id: null, name: null },
      selectedFloor: { id: null, name: null },
      selectedZone: { id: null, name: null },
      // companyOptions: [],
      buildingOptions: [],
      floorOptions: [],
      zoneOptions: [],
      goBackText: "Back"
    };
  },
  computed: {
    ...mapGetters({
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      getViewingLocation: "psim/getViewingLocation"
    })
  },
  watch: {
    isBuildingsInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.fetchBuildingOptions();
          // console.log("fetchBuildingOptions");
        }
      },
      deep: true
    },
    selectedBuilding: {
      handler: function (n, o) {
        this.goToBuilding(n.id);
      },
      deep: true
    },
    selectedFloor: {
      handler: function (n, o) {
        this.goToFloor(this.shownBuilding.id, n.id);
      },
      deep: true
    },
    selectedZone: {
      handler: function (n, o) {
        // console.log(n)
        this.goToZone(this.shownBuilding.id, this.shownFloor.id, n.id);
      },
      deep: true
    },
    getViewingLocation: {
      handler: function (n, o) {
        this.setupComponent();
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("FloorNavigator:", this.shownBuilding);
    this.setupComponent();

    if (this.isBuildingsInitiated) {
      this.fetchBuildingOptions();
    }

    if (this.shownBuilding.id != null) {
      this.fetchFloorOptions(this.shownBuilding.id);
    }

    if (this.shownFloor.id != null) {
      this.fetchZoneOptions(this.shownBuilding.id, this.shownFloor.id);
    }
  },
  methods: {
    setupComponent: function () {
      if (this.getViewingLocation != null) {
        // TOCLEAN
        // this.companyOptions = [{"id": this.getViewingLocation.company.id, "name": this.getViewingLocation.company.name}];

        if (
          this.getViewingLocation.building != null &&
          this.getViewingLocation.floor != null
        ) {
          this.isViewingFloor = true;
          this.isViewingBuilding = false;
          this.isViewingBuildingList = false;

          // this.shownCompany = {"id": this.getViewingLocation.company.id, "name": this.getViewingLocation.company.name};
          this.shownBuilding = {
            id: this.getViewingLocation.building.id,
            name: this.getViewingLocation.building.name
          };
          this.shownFloor = {
            id: this.getViewingLocation.floor.id,
            name: this.getViewingLocation.floor.name
          };

          if (this.getViewingLocation.zone != null) {
            this.shownZone = {
              id: this.getViewingLocation.zone.id,
              name: this.getViewingLocation.zone.name
            };
          } else {
            this.shownZone = { id: null, name: null };
          }

          this.fetchFloorOptions(this.shownBuilding.id);
          this.fetchZoneOptions(this.shownBuilding.id, this.shownFloor.id);

          this.goBackText = "Back to " + this.shownBuilding.name;
        } else if (
          this.getViewingLocation.building != null &&
          this.getViewingLocation.floor == null
        ) {
          // console.log("Init Building view");
          this.isViewingFloor = false;
          this.isViewingBuilding = true;
          this.isViewingBuildingList = false;

          // this.shownCompany = {"id": this.getViewingLocation.company.id, "name": this.getViewingLocation.company.name};
          this.shownBuilding = {
            id: this.getViewingLocation.building.id,
            name: this.getViewingLocation.building.name
          };
          this.shownFloor = { id: null, name: null };
          this.shownZone = { id: null, name: null };

          this.fetchFloorOptions(this.shownBuilding.id);

          this.goBackText = "Back to All Buildings";
        } else if (this.getViewingLocation.building == null) {
          this.isViewingFloor = false;
          this.isViewingBuilding = false;
          this.isViewingBuildingList = true;

          // this.shownCompany = {"id": this.getViewingLocation.company.id, "name": this.getViewingLocation.company.name};
          this.shownBuilding = { id: null, name: null };
          this.shownFloor = { id: null, name: null };
          this.shownZone = { id: null, name: null };

          this.goBackText = "";
        }
      }

      // console.log(this.getViewingLocation);
      // console.log("isViewingBuildingList", this.isViewingBuildingList, "isViewingBuilding", this.isViewingBuilding, "isViewingFloor", this.isViewingFloor);
    },
    fetchBuildingOptions: function () {
      this.buildingOptions = [];
      var buildings = this.$store.getters["psim/getBuildings"]();
      for (var b in buildings) {
        var b_data = { id: buildings[b].id, name: buildings[b].name };
        this.buildingOptions.push(b_data);
      }
    },
    fetchFloorOptions: function (building_id) {
      // console.log("fetchFloorOptions")
      this.floorOptions = [];
      if (building_id != null) {
        var building_obj = this.$store.getters["psim/getBuilding"](building_id);
        var building_floors = [...building_obj.floors];
        building_floors.sort(function (a, b) {
          return b.index - a.index;
        });
        for (var f in building_floors) {
          var f_data = {
            id: building_floors[f].id,
            name: building_floors[f].name
          };
          this.floorOptions.push(f_data);
        }
      }
    },
    fetchZoneOptions: function (building_id, floor_id) {
      this.zoneOptions = [];
      if (building_id != null && floor_id != null) {
        var floor_obj = this.$store.getters["psim/getFloor"](
          building_id,
          floor_id
        );
        for (var z in floor_obj.zones) {
          var z_data = {
            id: floor_obj.zones[z].id,
            name: floor_obj.zones[z].name
          };
          this.zoneOptions.push(z_data);
        }
      }
    },
    goToBuildingList: function () {
      if (
        this.$route.name == "PSIM" &&
        Object.keys(this.$route.query).length == 0
      ) {
        //
      } else {
        this.$router.push({ name: "PSIM" }).catch(() => {});
      }

      this.selectedBuilding = { id: null, name: null };
      this.shownBuilding = { id: null, name: null };

      this.selectedFloor = { id: null, name: null };
      this.shownFloor = { id: null, name: null };

      this.selectedZone = { id: null, name: null };
      this.shownZone = { id: null, name: null };

      this.$store.dispatch("psim/setLocatingAlert", null);
    },
    selectBuilding: function (building_id) {
      // console.log("SELECT BUILDING", building_id)
      var building_obj = this.$store.getters["psim/getBuilding"](building_id);
      this.selectedBuilding = { id: building_obj.id, name: building_obj.name };
      this.shownBuilding = { id: building_obj.id, name: building_obj.name };

      this.fetchFloorOptions(this.shownBuilding.id);
    },
    goToBuilding: function (building_id) {
      // console.log("GO TO BUILDING", building_id)
      if (building_id != null) {
        this.$router
          .push({ name: "PSIM", query: { building: building_id } })
          .catch(() => {});

        this.selectedFloor = { id: null, name: null };
        this.shownFloor = { id: null, name: null };

        this.selectedZone = { id: null, name: null };
        this.shownZone = { id: null, name: null };

        this.$store.dispatch("psim/setLocatingAlert", null);
      }
    },
    changeBuilding: function (building_id) {
      // console.log("CHANGE BUILDING");
      if (building_id != null) {
        var building_obj = this.$store.getters["psim/getBuilding"](building_id);
        this.shownBuilding = { id: building_obj.id, name: building_obj.name };

        this.fetchFloorOptions(this.shownBuilding.id);
      } else {
        this.shownBuilding = { id: null, name: null };
        this.floorOptions = [];
      }
    },
    selectFloor: function (building_id, floor_id) {
      var floor_obj = this.$store.getters["psim/getFloor"](
        building_id,
        floor_id
      );
      this.selectedFloor = { id: floor_obj.id, name: floor_obj.name };
      this.shownFloor = { id: floor_obj.id, name: floor_obj.name };

      this.fetchZoneOptions(this.shownBuilding.id, this.shownFloor.id);
    },
    goToFloor: function (building_id, floor_id) {
      if (building_id != null && floor_id != null) {
        this.$router
          .push({
            name: "PSIM",
            query: { building: building_id, floor: floor_id }
          })
          .catch(() => {});

        this.selectedZone = { id: null, name: null };
        this.shownZone = { id: null, name: null };

        this.$store.dispatch("psim/setLocatingAlert", null);
      }
    },
    changeFloor: function (floor_id) {
      if (floor_id != null) {
        var floor_obj = this.$store.getters["psim/getFloor"](
          this.shownBuilding.id,
          floor_id
        );
        this.shownFloor = { id: floor_obj.id, name: floor_obj.name };

        this.fetchZoneOptions(this.shownBuilding.id, this.shownFloor.id);
      } else {
        this.shownFloor = { id: null, name: null };
        this.zoneOptions = [];
      }
    },
    selectZone: function (building_id, floor_id, zone_id) {
      var zone_obj = this.$store.getters["psim/getZone"](
        building_id,
        floor_id,
        zone_id
      );
      this.selectedZone = { id: zone_obj.id, name: zone_obj.name };
      this.shownZone = { id: zone_obj.id, name: zone_obj.name };
    },
    goToZone: function (building_id, floor_id, zone_id) {
      if (building_id != null && floor_id != null && zone_id != null) {
        this.$router
          .push({
            name: "PSIM",
            params: { building: building_id, floor: floor_id, zone: zone_id }
          })
          .catch(() => {});

        this.$store.dispatch("psim/setLocatingAlert", null);
      }
    },
    changeZone: function (zone_id) {
      if (zone_id != null) {
        var zone_obj = this.$store.getters["psim/getZone"](
          this.shownBuilding.id,
          this.shownFloor.id,
          zone_id
        );
        this.shownZone = { id: zone_obj.id, name: zone_obj.name };
      } else {
        this.shownZone = { id: null, name: null };
      }
    },
    goBack: function (event) {
      event.preventDefault();
      var url_name = "PSIM";
      var url_query_dict = JSON.parse(JSON.stringify(this.$route.query));

      if (url_query_dict.zone != undefined) {
        delete url_query_dict["zone"];
      } else if (url_query_dict.floor != undefined) {
        delete url_query_dict["floor"];
      } else if (url_query_dict.building != undefined) {
        delete url_query_dict["building"];
      }

      this.$router
        .push({ name: url_name, query: url_query_dict })
        .catch(() => {});
      this.$store.dispatch("psim/setLocatingAlert", null);
    }
  }
};
</script>
