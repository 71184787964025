<template>
  <div id="panel_building" class="panel-wrapper">
    <div class="building-wrapper">
      <!-- Widgets -->
      <div class="building-widgets">
        <!-- <div class="building-widget">
                    <div class="building-widget-content">
                        <div class="text-counter">{{ widgets.ongoing_cases }}</div>
                        <div class="text-header"><h2 class="mb-0">Ongoing Cases</h2></div>
                    </div>
                </div> -->
        <!-- <div class="building-widget">
                    <div class="building-widget-content">
                        <div class="text-counter">{{ widgets.floors_with_cases }}</div>
                        <div class="text-header"><h2 class="mb-0">Floors with Cases</h2></div>
                    </div>
                </div> -->
        <!-- <div class="building-widget">
                    <div class="building-widget-content">
                        <div class="text-counter">{{ widgets.maintenance_today }}</div>
                        <div class="text-header"><h2 class="mb-0">Maintenance Today</h2></div>
                    </div>
                </div> -->
        <!-- <div class="building-widget">
                    <div class="building-widget-content">
                        <div class="text-counter">{{ widgets.case_assignees }}</div>
                        <div class="text-header"><h2 class="mb-0">Case Assignees</h2></div>
                    </div>
                </div> -->
        <div class="building-widget">
          <div class="building-widget-content">
            <div class="text-counter">{{ widgets.total_floors }}</div>
            <div class="text-header"><h2 class="mb-0">Total Floors</h2></div>
          </div>
        </div>
        <div class="building-widget">
          <div class="building-widget-content">
            <div class="text-counter">{{ widgets.total_devices }}</div>
            <div class="text-header"><h2 class="mb-0">Total Devices</h2></div>
          </div>
        </div>
        <div class="building-widget">
          <div class="building-widget-content">
            <div class="text-counter">{{ widgets.devices_offline }}</div>
            <div class="text-header"><h2 class="mb-0">Devices Offline</h2></div>
          </div>
        </div>
        <!-- <div class="building-widget">
                    <div class="building-widget-content">
                        <div class="text-counter">{{ widgets.upcomming_maintenance }}</div>
                        <div class="text-header"><h2 class="mb-0">Upcoming Maintenance</h2></div>
                    </div>
                </div> -->
      </div>

      <!-- Building -->
      <Building :building="building" :key="building.id"> </Building>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Building from "@/components/PanelFloorplan/BuildingView/Building.vue";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingView",
  components: {
    Building
  },
  data() {
    return {
      header: "",
      building: {},
      widgets: {
        ongoing_cases: 0,
        floors_with_cases: 0,
        devices_offline: 0,
        maintenance_today: 0,
        case_assignees: 0,
        total_floors: 0,
        total_devices: 0,
        upcomming_maintenance: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        this.initView();
      },
      deep: true
    }
  },
  mounted: function () {
    console.log("Building View mounted");
    this.initView();
  },
  methods: {
    initView: function () {
      if (
        this._getViewingLocation != null &&
        this._getViewingLocation.building != null &&
        this._getViewingLocation.floor == null
      ) {
        var building_obj = this.$store.getters["psim/getBuilding"](
          this._getViewingLocation.building.id
        );
        this.building = building_obj;

        this.getWidgetData();
      }
    },
    getWidgetData: function () {
      var floors_with_cases = 0;
      for (var f in this.building.floors) {
        var floor = this.building.floors[f];
        var case_filter = { floor_list__contains: floor.id };
        var cases = this.$store.getters["caseWS/filterCases"](case_filter);
        if (cases.length > 0) {
          floors_with_cases += 1;
        }
      }
      var cases_all = this.$store.getters["caseWS/getCases"];
      var total_cases = cases_all.length;
      var cameras_total = this.$store.getters["psim/getCameras"]({
        building__id: this.building.id
      });
      var cameras_offline = this.$store.getters["psim/getCameras"]({
        building__id: this.building.id,
        status: 0
      });
      var doors_total = this.$store.getters["psim/getDoors"]({
        building__id: this.building.id
      });
      var doors_offline = this.$store.getters["psim/getDoors"]({
        building__id: this.building.id,
        status: 0
      });

      var widgets_data = {
        ongoing_cases: total_cases,
        floors_with_cases: floors_with_cases,
        devices_offline: cameras_offline.length + doors_offline.length,
        maintenance_today: Math.floor(Math.random() * 10),
        case_assignees: Math.floor(Math.random() * 10),
        total_floors: this.building.floors.length,
        total_devices: cameras_total.length + doors_total.length,
        upcomming_maintenance: Math.floor(Math.random() * 10)
      };

      this.widgets = widgets_data;
    }
  }
};
</script>
