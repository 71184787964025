<template>
  <div
    class="alert-card"
    :data-priority="alert.priority"
    v-show="alert.is_discarded == false"
  >
    <div class="alert-card-label"></div>
    <div class="alert-card-content">
      <div class="alert-card-info" @click="viewAlertDetails(alert)">
        <div class="alert-card-thumbnail" style="cursor: pointer">
          <img v-lazy="thumbnail" />
        </div>
        <div class="alert-card-details">
          <!-- {{ alert.id }} -->
          <div class="alert-card-title">{{ alert.title }}</div>
          <div class="alert-card-desc" v-if="alert.building.name != null">
            <strong>Location:</strong> {{ alert.building.name }} -
            {{ alert.floor.name }}<br />
            <strong>Device:</strong>
            <i
              class="fa fa-video ml-1"
              v-if="alert.device_type == 'camera'"
            ></i>
            <i
              class="fa fa-door-closed ml-1"
              v-else-if="alert.device_type == 'door'"
            ></i>
            {{ alert.device_name }}
          </div>
          <div v-else>
            <strong>Device:</strong>
            <i
              class="fa fa-video ml-1"
              v-if="alert.device_type == 'camera'"
            ></i>
            <i
              class="fa fa-door-closed ml-1"
              v-else-if="alert.device_type == 'door'"
            ></i>
            {{ alert.device_ip }}<br />
          </div>

          <div class="alert-card-desc" v-if="alert.card_info.length > 0">
            <div v-for="card_info in alert.card_info" :key="card_info.key">
              <strong>{{ card_info.name }}:</strong> {{ card_info.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="alert-card-actions">
        <div class="pl-2">
          <i class="fa fa-clock mr-1"></i>
          <time-since :date="alert.created"></time-since>
        </div>
        <div class="d-flex justify-content-end">
          <div class="alert-card-action-btn" v-if="alert.building.id != null">
            <b-button
              variant="secondary"
              :class="{ active: locatingAlert }"
              v-b-tooltip.hover.bottom
              title="Zoom to location"
              @click="goToDevice(alert.device_type, alert.device_ip)"
            >
              <i class="fas fa-crosshairs"></i>
            </b-button>
          </div>

          <div class="alert-card-action-btn">
            <b-button
              variant="secondary"
              v-b-tooltip.hover.bottom
              title="View details"
              @click="viewAlertDetails(alert)"
            >
              <i class="far fa-eye"></i>
            </b-button>
          </div>

          <!-- <div class="alert-card-action-btn">
                        <b-button variant="secondary"
                                  v-b-tooltip.hover.bottom title="Create case"
                                  @click="showCreateCaseForm('modal_create_case-' + alert.id)">
                            <i class="fa fa-clipboard"></i>
                        </b-button>
                    </div> -->

          <div class="alert-card-action-btn">
            <b-button
              variant="secondary"
              v-b-tooltip.hover.bottom
              title="Discard alert"
              @click="confirmDiscardAlert(alert)"
            >
              <i class="far fa-trash-alt"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal body-class="modal-create-case" :ref="'modal_create_case-' + alert.id" hide-footer title="Create a case">
            <h3>Alert Information</h3>
            <div class="d-flex mb-4">
                <div class="alert-image-container"><img v-lazy="alert.image_src"></div>
                <div class="ml-2">
                    <div>{{ alert.title }}</div>
                    <div>
                        {{ alert.building.name }} - {{ alert.floor.name }}<br>
                        {{ alert.device_name }}
                    </div>
                    <div>
                        <time-since :date="alert.created"></time-since>
                    </div>
                </div>
            </div>

            <b-form @submit="doCreateCase">
                <b-form-group
                    id="input-group-sop"
                    label="Select SOP:"
                    label-for="input-sop">
                    <b-form-select v-model="form.sop" :options="sopOptions" @change="selectSOPChanged" required>
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <div v-if="selectedSOP !== null" class="selected-sop-container">
                    <h3>{{ selectedSOP.title }}</h3>
                    <div v-for="(sopItem, index) in selectedSOP.items" :key="'sop_'+index">{{ index + 1 }}. {{ sopItem }}</div>
                </div>

                <b-form-group
                    id="input-group-sla"
                    label="Select SLA:"
                    label-for="input-sla">
                    <b-form-select v-model="form.sla" :options="slaOptions" @change="selectSLAChanged" required>
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    id="input-group-priority"
                    label="Select Priority:"
                    label-for="input-priority">
                    <b-form-select v-model="form.priority" :options="priorityOptions"></b-form-select>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit" variant="success">Create</b-button>
                </div>
            </b-form>
        </b-modal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  data: function () {
    var alert_priority = this.alert.priority;
    var alert_thumbnail = this.alert.thumbnail;
    var alert_event_images = this.alert.event_images;
    return {
      locatingAlert: false,
      isShow: true,
      sopOptions: [],
      slaOptions: [],
      priorityOptions: [
        { value: "1", text: "Low" },
        { value: "2", text: "Normal" },
        { value: "3", text: "High" }
      ],
      form: {
        sop: null,
        sla: null,
        priority: alert_priority
      },
      selectedSOP: null,
      selectedSLA: null,
      thumbnail: alert_thumbnail,
      event_images: alert_event_images
    };
  },
  computed: {
    ...mapGetters({
      _getLocatingAlert: "psim/getLocatingAlert",
      _getViewingLocation: "psim/getViewingLocation",
      getUpdatedAlertsDict: "alertWS/getUpdatedAlertsDict"
    })
  },
  watch: {
    _getLocatingAlert: {
      handler: function (n, o) {
        if (n == this.alert.id) {
          this.locatingAlert = true;
        } else {
          this.locatingAlert = false;
        }
      },
      deep: true
    },
    getUpdatedAlertsDict: {
      handler: function (n, o) {
        if (n[String(this.alert.id)] != undefined) {
          var alert_data = n[String(this.alert.id)];
          for (var k in alert_data) {
            let v = alert_data[k];
            this[k] = v;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    goToLocation: function (device_type, device_ip) {
      // var location = this._getViewingLocation;
      /*
            var location = {
                "building": {"id": "building_1", "name": "Main Block"},
                "floor": {"id": "floor_1", "name": "Ground Floor", "index": 0},
                "zone": {"id": "zone_a", "name": "Zone A", "box": [[20, 0], [90, 0], [90, 50], [20, 50]], "colour": "#2478d3"},
                "pos": {"x": "", "y": ""},
                "device_ip": "",
                "device_name": "",
                "device_type": ""
            }
            */

      let device_obj = null;
      if (device_type == "camera") {
        const device_obj_list = this.$store.getters["device/filterCameras"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      } else if (device_type == "door") {
        const device_obj_list = this.$store.getters["device/filterDoors"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      }

      var url_name = this.$route.name;
      var url_params = this.$route.params;
      var url_query_dict = this.$route.query;

      if (!this.locatingAlert) {
        if (device_obj != null) {
          url_query_dict = {};
          url_name = "PSIM";
          if (device_obj.building != null) {
            url_query_dict["building"] = device_obj.building.id;

            if (device_obj.floor != null) {
              url_query_dict["floor"] = device_obj.floor.id;

              if (device_obj.zone != null) {
                url_query_dict["zone"] = device_obj.zone.id;
              }
            }
          }

          url_query_dict["device_id"] = encodeURI(device_obj.id);
        }

        this.$store.dispatch("psim/setLocatingAlert", null);
        this.$store.dispatch("psim/setLocatingAlert", this.alert.id);

        this.$router
          .push({ name: url_name, query: url_query_dict })
          .catch(() => {});
      } else {
        this.$store.dispatch("psim/setLocatingAlert", null);
        this.$router
          .push({ name: url_name, params: url_params, query: url_query_dict })
          .catch(() => {});
      }
    },
    goToDevice: function (device_type, device_ip) {
      let device_obj = null;
      if (device_type == "camera") {
        const device_obj_list = this.$store.getters["device/filterCameras"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      } else if (device_type == "door") {
        const device_obj_list = this.$store.getters["device/filterDoors"]({
          ip: device_ip
        });
        if (device_obj_list.length > 0) {
          device_obj = device_obj_list[0];
        }
      }

      if (device_obj != null) {
        this.$store.dispatch("psim/setViewingDetails", {
          type: device_type,
          data: device_obj
        });
        this.goToLocation(device_type, device_ip);
      }
    },
    viewAlertDetails: function (alert) {
      let alert_data = this.$store.getters["alertWS/getAlert"](this.alert.id);
      this.$store.dispatch("psim/setViewingDetails", {
        type: "alert",
        data: alert_data
      });
      this.goToLocation(alert.device_type, alert.device_ip);
    },
    confirmDiscardAlert: function (alert) {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm discarding this alert?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.$store.dispatch("alertWS/discardAlert", alert.id);
            $this.$store.dispatch("psim/discardAlert", alert.id);
            $this.$emit("discard-alert", alert);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    }
    // Unused
    // showCreateCaseForm: function (modal_id) {
    //     var sop_list = [
    //         { value: "1", text: 'Loitering SOP' },
    //         { value: "2", text: 'Blacklist detected SOP' },
    //         { value: "3", text: 'Fire SOP' },
    //         { value: "4", text: 'Camera down SOP' },
    //         { value: "5", text: 'Door malfunction SOP' },
    //     ];
    //     var sla_list = [
    //         { value: "1", text: '10 minutes' },
    //         { value: "2", text: '30 minutes' },
    //         { value: "3", text: '1 hour' },
    //         { value: "4", text: '2 hours' },
    //     ];
    //     this.sopOptions = sop_list;
    //     this.slaOptions = sla_list;
    //     this.$refs[modal_id].show();
    // },
    // selectSOPChanged: function (value) {
    //     var sopList = [
    //         { id: "1", title: 'Loitering SOP', items: ["Locate person", "Question suspect", "Warn suspect", "Email superior"] },
    //         { id: "2", title: 'Blacklist detected SOP', items: ["Locate person", "Question suspect", "Detain suspect", "Inform surveillance team", "Email superior"] },
    //         { id: "3", title: 'Fire SOP', items: ["Open all doors", "Evacuate building", "Break down blocked doors", "Rollcall"] },
    //         { id: "4", title: 'Camera down SOP', items: ["Check camera connection", "Check camera condition", "Inform technical team", "Email superior"] },
    //         { id: "5", title: 'Door malfunction SOP', items: ["Check door access", "Check door condition", "Inform technical team", "Email superior"] },
    //     ]
    //     for (var i in sopList) {
    //         if (sopList[i].id == value) {
    //             this.selectedSOP = sopList[i];
    //             break;
    //         }
    //     }
    // },
    // selectSLAChanged: function (value) {
    //     var slaList = [
    //         { id: "1", title: '10 minutes', total_time: 10 * 60 },
    //         { id: "2", title: '30 minutes', total_time: 30 * 60 },
    //         { id: "3", title: '1 hour', total_time: 60 * 60 },
    //         { id: "4", title: '2 hours', total_time: 2 * 60 * 60 },
    //     ]
    //     for (var i in slaList) {
    //         if (slaList[i].id == value) {
    //             this.selectedSLA = slaList[i];
    //             break;
    //         }
    //     }
    // },
    // doCreateCase: function (event) {
    //     event.preventDefault();
    //     this.isShow = false;
    //     this.$store.dispatch('alertWS/discardAlert', this.alert.id);
    //     this.$store.dispatch('psim/discardAlert', this.alert.id);

    //     var sop_obj = this.selectedSOP;
    //     var sop_done = 0
    //     var sop_total = sop_obj.items.length;

    //     var sla_obj = this.selectedSLA;
    //     var sla_time_left = sla_obj.total_time;
    //     var sla_time_left_str = utils.secondsToHms(sla_obj.total_time, false);
    //     var sla_time_total = sla_obj.total_time;
    //     var sla_time_total_str = utils.secondsToHms(sla_obj.total_time, false);

    //     var cases_count = this.$store.getters['caseWS/getCaseIDs'].length;
    //     var case_id = 1 + cases_count;
    //     var case_num = case_id.toString().padStart(6, "0");
    //     var case_created = moment();

    //     var case_obj = {
    //         id: case_id,
    //         title: "#" + case_num + " - " + this.alert.title,
    //         priority: this.alert.priority,
    //         sop_done: sop_done,
    //         sop_total: sop_total,
    //         sop_progress: sop_done/sop_total*100,
    //         sla_time_left: sla_time_left_str,
    //         sla_time_total: sla_time_total_str,
    //         sla_progress: sla_time_left/sla_time_total*100,
    //         created: case_created.toISOString(),
    //         poi_list: [],
    //         team_list: [],
    //         devices_list: [this.alert.device_ip],
    //     }

    //     // this.$store.dispatch('psim/addCaseToDevice', { "device_ip": this.alert.device_ip, "device_type": this.alert.device_type, "case_id": case_obj.id });
    //     this.$store.dispatch("caseWS/addRawCase", {"id": case_obj.id, "data": case_obj});

    //     var modal_id = 'modal_create_case-' + this.alert.id;
    //     this.$refs[modal_id].hide();
    // },
  }
};
</script>

<style lang="scss">
@import "CardAlert.scss";
</style>
