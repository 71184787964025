<template>
  <div class="panel-content">
    <div
      class="panel-content-header d-flex align-items-center justify-content-between"
    >
      <h2>LPR Search</h2>
    </div>
    <div class="panel-content-body scroller" v-if="hasPermission">
      <b-container fluid>
        <b-row class="d-flex p-2 justify-content-center">
          <b-form @submit="onSubmitFormAddDevice">
            <b-col cols="12" class="d-flex p-2 justify-content-center">
              <b-form-group
                id="input-group-search-type"
                label="Type:"
                label-for="input-search-type"
              >
                <b-form-select
                  id="input-search-type"
                  v-model="searchType"
                  :options="searchTypeOptions"
                  @change="searchTypeChanged"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-search"
                label="Search by:"
                label-for="input-search"
              >
                <b-form-input
                  id="input-search"
                  v-model="search_text"
                  type="text"
                  placeholder="e.g. ABC 1234"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form>
        </b-row>

        <b-row class="my-1">
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              tclass="mr-1"
              variant="secondary"
              size="sm"
              @click="resetForm"
              >Reset</b-button
            >
            <b-button type="submit" variant="primary" size="sm"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelLprSearch",
  data: function () {
    return {
      hasPermission: false,
      searchType: "license_plate",
      searchTypeOptions: [
        { value: null, text: "Please select an option" },
        { value: "license_plate", text: "License Plate" },
        { value: "make", text: "Make" },
        { value: "model", text: "Model" }
      ],
      searchDeviceOptions: [],
      selectedSearchDeviceOption: null,
      search_text: "",
      placeholderText: ""
    };
  },
  computed: {},
  watch: {
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;

        if (allowed_panels.indexOf("lpr_search") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user.panels;

    if (allowed_panels.indexOf("lpr_search") >= 0) {
      this.hasPermission = true;
    }
  },
  methods: {
    onSubmitFormAddDevice: function (e) {
      var $this = this;
      // console.log($this.license_plate);
    },
    resetForm: function (e) {},
    searchTypeChanged: function (e) {
      switch (this.searchType) {
        case "license_plate":
          this.placeholderText = "e.g. ABC 1234";
          break;
        case "make":
          this.placeholderText = "e.g. Toyota";
          break;
        case "model":
          this.placeholderText = "e.g. Vios";
          break;
      }
      document.getElementById("input-search").placeholder =
        this.placeholderText;
    }
  }
};
</script>

<style lang="scss">
@import "./PanelLprSearch.scss";
</style>
