<template>
  <div class="panel-content">
    <FloorplanView
      v-show="isViewingFloor"
      @fetched-floorplan="floorplanHandler"
      @change-toolbar-zoom-level="changeToolbarZoomLevelHandler"
      ref="floorplanView"
      v-if="hasPermission"
    />
    <BuildingView v-show="isViewingBuilding" v-if="hasPermission" />
    <BuildingListView
      v-show="isViewingBuildingList"
      :filters="buildingListFilters"
      :sorting="buildingListSorting"
      v-if="hasPermission"
    />

    <!-- Header bar -->
    <div class="panel-header" v-if="hasPermission">
      <div class="d-flex align-items-start flex-column">
        <h1 class="mb-0">Dashboard</h1>

        <div class="panel-header-text">
          <div>{{ header }}</div>

          <div @click="bookmarkLocation" class="bookmark-btn ml-1">
            <i class="fa fa-star" v-if="hasBookmark"></i>
            <i class="far fa-star" v-else></i>
          </div>
        </div>
      </div>

      <div class="d-flex mt-1 mt-md-0">
        <BuildingListViewToolbar
          v-if="isViewingBuildingList"
          @sort-objects="buildingListSortHandler"
          @filter-objects="buildingListFiltersHandler"
        />
        <FloorplanViewToolbar
          v-else-if="isViewingFloor"
          :display="hasFloorplanImage"
          @reset-floorplan="resetFloorplanHandler"
          @zoom-in="zoomInHandler"
          @zoom-out="zoomOutHandler"
          @drag-zoom="dragZoomHandler"
          @toggle-view-cameras="viewCamerasHandler"
          @toggle-view-doors="viewDoorsHandler"
          ref="floorplanViewToolbar"
        />

        <div class="ml-1" v-if="isViewingFloor">
          <b-dropdown size="sm" id="nav_bookmarks" right>
            <template #button-content>
              <i class="fa fa-layer-group"></i>
            </template>

            <b-dropdown-header> Go to floor plan </b-dropdown-header>
            <b-dropdown-item-button
              v-for="floorplan in floorplanOptions"
              :key="floorplan.id"
              @click="
                selectFloorplan(
                  _getViewingLocation.building.id,
                  _getViewingLocation.floor.id,
                  floorplan.id
                )
              "
            >
              {{ floorplan.name }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>

        <div ref="bookmarkBar" class="ml-1">
          <b-dropdown size="sm" id="nav_bookmarks" right>
            <template #button-content>
              <i class="fa fa-star"></i>
            </template>

            <b-dropdown-header id="dropdown-header-label">
              Bookmarks
            </b-dropdown-header>

            <b-dropdown-text v-if="bookmarks.length <= 0">
              No bookmarks created
            </b-dropdown-text>

            <div class="scroller bookmarks-container">
              <div v-for="(bookmark, b_index) in bookmarks" :key="bookmark.id">
                <router-link
                  :to="bookmark.url"
                  v-slot="{ href, route, navigate }"
                  custom
                >
                  <b-dropdown-item
                    :href="href"
                    :class="getBookmarkActiveClass(bookmark)"
                    @click="navigate"
                  >
                    {{ b_index + 1 }}. {{ bookmark.name }}
                  </b-dropdown-item>
                </router-link>
              </div>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>

    <!-- Navigation bar -->
    <div class="panel-navbar">
      <FloorNavigator></FloorNavigator>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import FloorplanView from "@/components/PanelFloorplan/FloorplanView/FloorplanView";
import FloorplanViewToolbar from "@/components/PanelFloorplan/FloorplanView/FloorplanViewToolbar";
import BuildingView from "@/components/PanelFloorplan/BuildingView/BuildingView";
import BuildingListView from "@/components/PanelFloorplan/BuildingListView/BuildingListView";
import BuildingListViewToolbar from "@/components/PanelFloorplan/BuildingListView/BuildingListViewToolbar";
import FloorNavigator from "@/components/FloorNavigator/FloorNavigator";
let utils = require("@/assets/global/js/utils.js");

export default {
  components: {
    FloorplanView,
    FloorplanViewToolbar,
    BuildingView,
    BuildingListView,
    BuildingListViewToolbar,
    FloorNavigator
  },
  data() {
    return {
      isViewingFloor: false,
      isViewingBuilding: false,
      isViewingBuildingList: false,
      floorplanOptions: [],
      header: "",
      navMode: true,
      bookmarkMode: false,
      bookmarks: [],
      hasBookmark: false,
      bookmarkID: null,
      bookmarkName: null,
      bookmarkForm: {
        name: null,
        url: null
      },
      buildingListFilters: null,
      buildingListSorting: null,
      hasFloorplanImage: false,
      resetFloorplanEvent: false,
      hasPermission: false
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation",
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      getBookmarks: "psim/getBookmarks",
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getBookmarksList() {
      return this.getBookmarks();
    }
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        if (n != o) {
          this.initPanel();
          this.updateHeader();
          this.updateBookmark();
        }
      },
      deep: true
    },
    getBookmarksList: {
      handler: function (n, o) {
        this.bookmarks = n;
      },
      deep: true
    },
    navMode: {
      handler: function (n, o) {
        if (n == true) {
          this.bookmarkMode = false;
        }
      }
    },
    bookmarkMode: {
      handler: function (n, o) {
        if (n == true) {
          this.navMode = false;
        }
      }
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("floorplan") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Panel Floorplan mounted");
    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("floorplan") >= 0) {
      this.hasPermission = true;
    }
    // console.log(this.hasPermission)

    this.initPanel();
    this.updateHeader();
    this.updateBookmark();
    this.bookmarks = this.getBookmarksList;
  },
  methods: {
    initPanel: function () {
      // console.log("Init PanelFloorplan");
      if (this._getViewingLocation != null) {
        if (
          this._getViewingLocation.building != null &&
          this._getViewingLocation.floor != null
        ) {
          this.isViewingFloor = true;
          this.isViewingBuilding = false;
          this.isViewingBuildingList = false;

          this.fetchFloorplanOptions(
            this._getViewingLocation.building.id,
            this._getViewingLocation.floor.id,
            this._getViewingLocation.device_id
          );

          this.goBackText = "Back to " + this._getViewingLocation.building.name;
        } else if (
          this._getViewingLocation.building != null &&
          this._getViewingLocation.floor == null
        ) {
          // console.log("Init Building view");
          this.isViewingFloor = false;
          this.isViewingBuilding = true;
          this.isViewingBuildingList = false;
        } else if (this._getViewingLocation.building == null) {
          this.isViewingFloor = false;
          this.isViewingBuilding = false;
          this.isViewingBuildingList = true;
        }
      }
    },
    fetchFloorplanOptions: function (building_id, floor_id, device_id) {
      let $this = this;
      var building_obj = $this.$store.getters["psim/getBuilding"](building_id);
      var floor_obj = $this.$store.getters["psim/getFloor"](
        building_id,
        floor_id
      );
      let activeFloorplans = $this.$store.getters["psim/getActiveFloorplan"];
      // console.log("activeFloorplans", activeFloorplans);

      $this.floorplanOptions = [];
      if (building_id != null && floor_id != null) {
        var API_URL =
          $this.getAPIServerURL +
          "/floorplans/buildings/" +
          building_id +
          "/floors/" +
          floor_id +
          "/floorplans/";
        const client = $this.$root.getAjaxFetchClient();
        // console.log('fetching')
        client
          .getRequest(API_URL)
          .then((data) => {
            if (data.result != undefined) {
              $this.floorplanOptions = data.result;

              var floorplanObj = {
                id: null,
                name: null,
                devices: null,
                image: null
              };
              var currentActiveFloorplan = activeFloorplans[String(floor_id)];

              $this.hasFloorplanImage = false;
              if ($this.floorplanOptions.length > 0) {
                $this.hasFloorplanImage = true;

                for (var i in $this.floorplanOptions) {
                  let floorplanData = $this.floorplanOptions[i];
                  let image_base64 = utils.cleanBase64ImgSrc(
                    floorplanData.image_b64,
                    floorplanData.mime_type
                  );
                  for (var d in floorplanData.devices) {
                    var device_data = floorplanData.devices[d];
                    device_data["building"] = {
                      id: building_obj.id,
                      name: building_obj.name
                    };
                    device_data["floor"] = {
                      id: floor_obj.id,
                      name: floor_obj.name
                    };
                  }

                  floorplanObj = {
                    id: floorplanData.id,
                    name: floorplanData.name,
                    devices: floorplanData.devices,
                    image: image_base64
                  };
                  // console.log(device_id)
                  // console.log(currentActiveFloorplan, "vs", floorplanData);

                  // find device in floorplans
                  if (device_id != undefined) {
                    if (
                      currentActiveFloorplan != undefined &&
                      currentActiveFloorplan.id == floorplanData.id
                    ) {
                      if (
                        currentActiveFloorplan.devices[device_id] != undefined
                      ) {
                        // if current active floorplan has device, use this instead
                        $this.$store.dispatch("psim/setActiveFloorplan", {
                          floor: floor_id,
                          floorplan: floorplanObj
                        });
                        break;
                      }
                    } else {
                      if (floorplanData.devices[device_id] != undefined) {
                        // device is in this floorplan
                        $this.$store.dispatch("psim/setActiveFloorplan", {
                          floor: floor_id,
                          floorplan: floorplanObj
                        });
                        break;
                      } else {
                        // device is not in floorplan, continue loop
                      }
                    }
                  } else {
                    if (currentActiveFloorplan == undefined) {
                      $this.$store.dispatch("psim/setActiveFloorplan", {
                        floor: floor_id,
                        floorplan: floorplanObj
                      });
                      break;
                    }
                  }
                }
              }
            }

            // let currentActiveFloorplanUpdated = $this.$store.getters["psim/getActiveFloorplan"];
            // console.log("currentActiveFloorplanUpdated", currentActiveFloorplanUpdated);
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    },
    selectFloorplan: function (building_id, floor_id, floorplan_id) {
      let $this = this;
      var building_obj = $this.$store.getters["psim/getBuilding"](building_id);
      var floor_obj = $this.$store.getters["psim/getFloor"](
        building_id,
        floor_id
      );
      let activeFloorplans = $this.$store.getters["psim/getActiveFloorplan"];

      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        building_id +
        "/floors/" +
        floor_id +
        "/floorplans/" +
        floorplan_id +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var floorplan_image_b64 = utils.cleanBase64ImgSrc(
            data.image_b64,
            data.mime_type
          );
          $this.floorplan_image = floorplan_image_b64;

          for (var d in data.devices) {
            var device_id = d;
            var device_data = data.devices[d];
            device_data["building"] = {
              id: building_obj.id,
              name: building_obj.name
            };
            device_data["floor"] = { id: floor_obj.id, name: floor_obj.name };
          }

          var floorplanObj = {
            id: data.id,
            name: data.name,
            devices: data.devices,
            image: floorplan_image_b64
          };
          // activeFloorplans[String(floor_id)] = floorplanObj
          // this.$store.dispatch("psim/setActiveFloorplan", activeFloorplans);
          $this.$store.dispatch("psim/setActiveFloorplan", {
            floor: floor_id,
            floorplan: floorplanObj
          });
          // let currentActiveFloorplan = $this.$store.getters["psim/getActiveFloorplan"];
          // console.log(currentActiveFloorplan);
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    updateHeader: function () {
      if (this._getViewingLocation != null) {
        var building = this._getViewingLocation.building;
        var floor = this._getViewingLocation.floor;
        var zone = this._getViewingLocation.zone;
        var location_array = ["All Buildings"];

        if (building != null && building.name != undefined) {
          location_array.push(building.name);
        }
        if (floor != null && floor.name != undefined) {
          location_array.push(floor.name);
        }
        if (zone != null && zone.name != undefined) {
          location_array.push(zone.name);
        }
        var floorplan_header = location_array.join(" - ");
        this.header = floorplan_header;
      }
    },
    // --------- Bookmarks -----------
    getBookmarkActiveClass: function (bookmark) {
      if (this.$route.fullPath == bookmark.url) {
        return "active";
      }
      return "";
    },
    updateBookmark: function () {
      var this_url = this.$route.fullPath;
      var exist_bookmark = this.$store.getters["psim/getBookmarks"]({
        url: this_url
      });
      if (exist_bookmark.length > 0) {
        this.hasBookmark = true;
        this.bookmarkID = exist_bookmark[0].id;
        this.bookmarkName = exist_bookmark[0].name;
      } else {
        this.hasBookmark = false;
        this.bookmarkID = null;
        this.bookmarkName = null;
      }
    },
    bookmarkLocation: function () {
      var $this = this;
      if (this.bookmarkID != null) {
        this.unbookmarkLocation();
      } else {
        this.bookmarkForm.name = this.header;
        this.bookmarkForm.url = this.$route.fullPath;
        var API_URL = $this.getAPIServerURL + "/bookmarks/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, {
            name: this.bookmarkForm.name,
            url: this.bookmarkForm.url
          })
          .then((data) => {
            if (data.id != undefined) {
              $this.$store.dispatch("psim/addBookmark", data);
              $this.updateBookmark();

              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Added Bookmark successfully",
                message_type: "success"
              });
            } else if (data.detail != undefined) {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: data.detail,
                message_type: "danger"
              });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    },
    unbookmarkLocation: function () {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL + "/bookmarks/" + this.bookmarkID + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then((data) => {
          $this.$store.dispatch("psim/deleteBookmark", this.bookmarkID);
          $this.updateBookmark();

          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Removed Bookmark successfully",
            message_type: "success"
          });

          // TOCLEAN
          // } else {
          //     $this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
          // }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    // Toolbar handlers
    buildingListFiltersHandler: function (filters) {
      this.buildingListFilters = filters;
    },
    buildingListSortHandler: function (sorting) {
      this.buildingListSorting = sorting;
    },
    floorplanHandler: function (floorplan_image) {
      // if (floorplan_image != null) {
      //     this.hasFloorplanImage = true;
      // } else {
      //     this.hasFloorplanImage = false;
      // }
    },
    resetFloorplanHandler: function () {
      this.$refs.floorplanView.resetFloorplan();
    },
    zoomInHandler: function () {
      this.$refs.floorplanView.zoomIn();
    },
    zoomOutHandler: function () {
      this.$refs.floorplanView.zoomOut();
    },
    dragZoomHandler: function (value) {
      this.$refs.floorplanView.dragZoom(value);
    },
    changeToolbarZoomLevelHandler: function (value) {
      if (this.$refs.floorplanViewToolbar != undefined) {
        this.$refs.floorplanViewToolbar.changeZoomLevel(value);
      }
    },
    viewCamerasHandler: function (value) {
      this.$refs.floorplanView.toggleCameras(value);
    },
    viewDoorsHandler: function (value) {
      this.$refs.floorplanView.toggleDoors(value);
    }
  }
};
</script>

<style lang="scss">
@import "./PanelFloorplan.scss";
</style>
