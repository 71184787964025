<template>
  <div>
    <b-dropdown
      @click="toggleMenu('sort')"
      size="sm"
      ref="sortMenu"
      right
      class="mr-1"
    >
      <template #button-content>
        <i class="fa fa-sort-amount-up"></i
        ><span class="ml-1" v-show="currentSortText != ''">{{
          currentSortText
        }}</span>
      </template>

      <b-dropdown-form>
        <div style="width: 400px">
          <b-container fluid>
            <b-row>
              <div class="col-12">
                <div class="dropdown-header toolbar-menu-header">Sort</div>
              </div>
            </b-row>
            <b-row>
              <div
                class="col-6 mb-2"
                v-for="sort in availableSort"
                :key="'sort_' + sort.key"
              >
                <div>{{ sort.label }}</div>
                <v-select
                  class="sort-select"
                  v-model="selectedSort[sort.key]"
                  label="name"
                  :options="sort.choices"
                  @input="(item) => updateSort(sort.value_type, sort.key, item)"
                >
                </v-select>
              </div>
            </b-row>

            <b-row>
              <b-col cols="12" class="text-right mt-2">
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="clearSort"
                  class="mr-1"
                  ><i class="fa fa-times mr-1"></i>Clear</b-button
                >
                <b-button size="sm" variant="success" @click="startSort"
                  ><i class="fa fa-sort-amount-up mr-1"></i>Sort</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-dropdown-form>
    </b-dropdown>

    <b-dropdown
      @click="toggleMenu('filter')"
      size="sm"
      ref="filterMenu"
      right
      :class="{ show: showFilters }"
    >
      <template #button-content>
        <i class="fa fa-filter"></i
        ><span class="ml-1" v-show="currentFiltersText != ''">{{
          currentFiltersText
        }}</span>
      </template>

      <b-dropdown-form>
        <div style="width: 400px">
          <b-container fluid>
            <b-row>
              <div class="col-12">
                <div class="dropdown-header toolbar-menu-header">Filters</div>
              </div>
            </b-row>
            <b-row>
              <div
                class="col-6 mb-2"
                v-for="filter in availableFilters"
                :key="'filter_' + filter.key"
              >
                <div>{{ filter.label }}</div>
                <div v-if="filter.type == 'select'">
                  <v-select
                    class="filter-select"
                    v-model="selectedFilters[filter.key]"
                    :label="filter.obj_value"
                    :options="filter.choices"
                    @input="
                      (item) =>
                        updateFilters(
                          filter.key,
                          item,
                          filter.obj_key,
                          filter.obj_value
                        )
                    "
                  >
                  </v-select>
                </div>
                <div v-else-if="filter.type == 'datetime'">
                  <v-datetime
                    type="datetime"
                    v-model="selectedFilters[filter.key]"
                    format="dd/MM/yyyy HH:mm"
                    @input="updateFilters(filter.key, $event, null, null)"
                  >
                  </v-datetime>
                </div>
                <div v-else>
                  <input
                    v-model="selectedFilters[filter.key]"
                    placeholder="Select date and time"
                    @input="updateFilters(filter.key, $event, null, null)"
                  />
                </div>
              </div>
            </b-row>

            <b-row>
              <b-col cols="12" class="text-right mt-2">
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="clearFilter"
                  class="mr-1"
                  ><i class="fa fa-times mr-1"></i>Clear</b-button
                >
                <b-button size="sm" variant="success" @click="startFilter"
                  ><i class="fa fa-filter mr-1"></i>Filter</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingListViewToolbar",
  data() {
    return {
      buildings: [],
      availableFilters: [],
      currentFilters: {},
      selectedFilters: null,
      showFilters: false,
      currentFiltersText: "",
      availableSort: [],
      currentSort: {},
      selectedSort: null,
      showSort: true,
      currentSortText: ""
    };
  },
  mounted: function () {
    // init data
    this.fetchFilters();
    this.fetchSort();
  },
  methods: {
    // ------- Filters --------
    fetchFilters: function () {
      this.availableFilters = [
        {
          label: "Created Date from",
          key: "created__gte",
          type: "datetime"
        },
        {
          label: "Created Date end",
          key: "created__lte",
          type: "datetime"
        }
      ];

      this.selectedFilters = { created__gte: null, created__lte: null };
    },
    updateFilters: function (filterKey, selectedItem, objectKey, objectValue) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentFilters[filterKey];
      } else {
        if (objectKey == null) {
          this.currentFilters[filterKey] = selectedItem;
        } else {
          this.currentFilters[filterKey] = selectedItem[objectKey];
        }
      }

      var filter_count = Object.keys(this.currentFilters).length;
      if (filter_count >= 1) {
        this.currentFiltersText = filter_count + " selected filter(s)";
      } else if (filter_count < 1) {
        this.currentFiltersText = "";
      }
    },
    startFilter: function (e) {
      this.$refs.filterMenu.hide();
      this.$emit("filter-objects", this.currentFilters);
    },
    clearFilter: function () {
      this.currentFilters = {};
      for (var i in this.selectedFilters) {
        this.selectedFilters[i] = null;
      }
      this.currentFiltersText = "";
      this.$refs.filterMenu.hide();

      this.$emit("filter-objects", this.currentFilters);
    },
    // ------- Sort --------
    fetchSort: function () {
      this.availableSort = [
        {
          label: "Name",
          key: "name",
          type: "select",
          choices: [
            { order: "ascending", name: "A to Z" },
            { order: "descending", name: "Z to A" }
          ],
          value_type: "string"
        }
      ];

      this.selectedSort = { name: null };
    },
    updateSort: function (sortType, sortKey, selectedItem) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentSort[sortKey];
      } else {
        this.currentSort[sortKey] = {
          type: sortType,
          order: selectedItem["order"]
        };
      }
    },
    startSort: function () {
      var sort_list = [];

      for (var s in this.currentSort) {
        var sort_item = this.currentSort[s];
        var sort_type = sort_item["type"];
        var sort_order = sort_item["order"];
        var sort_key = s;
        sort_list.push(this.selectedSort[s]["name"]);
      }

      this.currentSortText = sort_list.join(", ");
      this.$refs.sortMenu.hide();

      this.$emit("sort-objects", this.currentSort);
    },
    clearSort: function () {
      this.currentSort = {};
      for (var i in this.selectedSort) {
        this.selectedSort[i] = null;
      }

      this.currentSortText = "";
      this.$refs.sortMenu.hide();

      this.$emit("sort-objects", this.currentSort);
    },
    toggleMenu: function (menu) {
      var $this = this;
      if (menu == "sort") {
        $this.$refs.filterMenu.hide();
      } else if (menu == "filter") {
        $this.$refs.sortMenu.hide();
      }
    }
  }
};
</script>
