<template>
  <div class="panel-content">
    <div
      class="panel-content-header d-flex align-items-center justify-content-between"
    >
      <h2>Face Search</h2>
    </div>
    <div
      class="panel-content-body scroller"
      id="search-image-panel-content-body"
      v-if="hasPermission"
    >
      <!-- Face search form -->
      <div class="search-form-container" v-show="showEnlargedImage == false">
        <b-form @submit="submitForm" class="search-form">
          <div id="drop-area" ref="dropArea">
            <div>
              <small>Choose an image or drop it here...</small>
            </div>
            <b-form-group
              :validations="$v.form.image"
              class="search-form-field mb-0"
            >
              <b-form-file
                id="input-image"
                accept="image/jpeg, image/png, image/gif"
                v-model="$v.form.image.$model"
                :state="Boolean($v.form.image.$model)"
                placeholder="Upload..."
                drop-placeholder="Drop file here..."
                @input="handleFile"
              ></b-form-file>
            </b-form-group>

            <div
              class="search-preview mt-2 mx-auto"
              v-show="searchImageBase64 != null"
            >
              <img ref="imageElement" />
            </div>
          </div>

          <div>
            <b-container fluid>
              <b-row class="my-1">
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-align="right"
                    content-cols="8"
                    label="Min similarity"
                    label-for="input-threshold"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-threshold"
                        v-model.number="$v.form.threshold.$model"
                        type="range"
                        min="30"
                        max="100"
                        step="0.1"
                      ></b-form-input>
                      <b-input-group-append style="width: 60px">
                        <b-form-input
                          id="input-threshold-number"
                          v-model.number="$v.form.threshold.$model"
                          type="number"
                          min="30"
                          max="100"
                          step="0.1"
                          size="sm"
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-align="right"
                    content-cols="8"
                    label="No. of results"
                    label-for="input-max-results"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-max-results"
                        v-model.number="$v.form.maxResults.$model"
                        type="range"
                        min="10"
                        max="100"
                        step="1"
                      ></b-form-input>
                      <b-input-group-append style="width: 60px">
                        <b-form-input
                          id="input-max-results-number"
                          v-model.number="$v.form.maxResults.$model"
                          type="number"
                          min="10"
                          max="100"
                          step="1"
                          size="sm"
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col
                  sm="12"
                  class="d-flex justify-content-end text-danger mb-2"
                  v-if="formError != null"
                >
                  {{ formError }}
                </b-col>

                <b-col sm="12" class="d-flex justify-content-end">
                  <b-button
                    class="mr-1"
                    variant="secondary"
                    size="sm"
                    @click="resetForm"
                    >Reset</b-button
                  >
                  <b-button type="submit" variant="primary" size="sm"
                    >Search</b-button
                  >
                </b-col>

                <b-col
                  sm="12"
                  class="d-flex justify-content-end text-success mt-2"
                  v-if="formSuccess != null"
                >
                  {{ formSuccess }}
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-form>
      </div>

      <!-- Face search results -->
      <div
        class="search-result-container mt-2"
        v-if="searchResultsList.length > 0 && showEnlargedImage == false"
      >
        <div class="d-flex align-items-center justify-content-between">
          <h2>Search Results</h2>
          <div class="d-flex align-items-center">
            <b-button
              size="sm"
              variant="secondary"
              v-if="hasCollapsed"
              @click="uncollapseAll"
              ><i class="fa fa-chevron-down mr-1"></i>Show all</b-button
            >
            <b-button size="sm" variant="secondary" v-else @click="collapseAll"
              ><i class="fa fa-chevron-right mr-1"></i>Collapse all</b-button
            >
          </div>
        </div>
        <div
          v-for="result in searchResultsList"
          :key="result.key"
          class="search-result-section"
        >
          <div
            class="search-result-section-date"
            :class="
              collapseIdList['collapse-' + result.key].visible
                ? 'not-collapsed'
                : 'collapsed'
            "
            :aria-expanded="
              collapseIdList['collapse-' + result.key].visible
                ? 'true'
                : 'false'
            "
            :aria-controls="'collapse-' + result.key"
            @click="toggleCollapse('collapse-' + result.key)"
          >
            <!-- v-b-toggle="'collapse-' + result.key" -->
            <h2>
              <i class="fa fa-chevron-down mr-1 when-open"></i
              ><i class="fa fa-chevron-right mr-1 when-closed"></i>
              {{ result.items[0].date }}
            </h2>
          </div>
          <b-collapse
            :id="'collapse-' + result.key"
            v-model="collapseIdList['collapse-' + result.key].visible"
          >
            <div class="search-result-section-items-container">
              <div
                class="search-result-card"
                v-for="item in result.items"
                :key="item.id"
                :data-id="item.id"
                @mouseover="item.hover = true"
                @mouseleave="item.hover = false"
                @click="openEnlargedImage(item)"
              >
                <div class="search-result-thumbnail">
                  <img
                    class="search-result-image"
                    :data-id="item.id"
                    :src="getImage(item.id)"
                  />
                </div>

                <div class="search-result-desc" v-if="item.hover">
                  <!-- <div><i class="fa fa-calendar mr-1"></i>{{ item.date }}</div> -->
                  <div><i class="fa fa-clock mr-1"></i>{{ item.time }}</div>
                  <!-- <div><i class="fa fa-video mr-1"></i>{{ item.camera }}</div> -->
                  <div v-if="item.unknownPersonId > 0">
                    <i class="fa fa-user-circle mr-1"></i
                    >{{ item.unknownPersonId }}
                  </div>
                  <div v-else>
                    <i class="fa fa-user-circle mr-1"></i>{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <b-button
          class=""
          variant="secondary"
          @click="loadNextSearch"
          v-if="showLoadMoreButton"
          >Load more</b-button
        >
        <div class="text-muted" v-else>End of search results</div>
      </div>

      <!-- Enlarged image window -->
      <transition name="fade">
        <div class="enlarged-image-section" v-if="showEnlargedImage">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h2>Comparison of Face Search Result</h2>
            <b-button size="sm" variant="secondary" @click="closeEnlargedImage"
              ><i class="fa fa-times"></i
            ></b-button>
          </div>
          <div class="d-flex align-items-top mb-2">
            <div class="searched-image">
              <div class="image-container">
                <img :src="searchImageBase64" />
              </div>
              <div class="image-label">Searched image</div>
            </div>
            <div class="result-image">
              <div class="image-container">
                <img :src="enlargedImage" />
              </div>
              <div class="image-label">Face search result</div>
            </div>
          </div>
          <h2 class="text-success">
            <strong>Similarity: {{ enlargedImageDetails.score }}</strong>
          </h2>
          <div>{{ enlargedImageDetails.title }}</div>
          <div>
            <i class="fa fa-calendar mr-1"></i>{{ enlargedImageDetails.date }}
          </div>
          <div>
            <i class="fa fa-clock mr-1"></i>{{ enlargedImageDetails.time }}
          </div>
          <div>
            <i class="fa fa-video mr-1"></i>{{ enlargedImageDetails.camera }}
          </div>
          <!-- <div><i class="fa fa-server mr-1"></i>{{ enlargedImageDetails.serverUrl }}</div> -->
        </div>
      </transition>
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, numeric, between, helpers } from "vuelidate/lib/validators";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import moment from "moment";
import { uuid } from "vue-uuid";
// import CoolLightBox from 'vue-cool-lightbox'
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelFaceSearch",
  // components: {
  //     CoolLightBox
  // },
  data() {
    return {
      searchImageBase64: null,
      searchLastResultID: {},
      searchResults: {},
      searchResultsList: [],
      imageResults: {},
      showEnlargedImage: false,
      enlargedImage: null,
      enlargedImageDetails: {
        id: null,
        title: null,
        date: null,
        time: null,
        camera: null,
        score: null
      },
      lastElementTopPos: null,
      hasCollapsed: false,
      collapseIdList: {},
      searchApiClient: null,
      imageApiClient: null,
      form: {
        image: null,
        threshold: 70,
        maxResults: 10
      },
      formError: null,
      formSuccess: null,
      showLoadMoreButton: true,
      hasPermission: false
    };
  },
  validations: {
    form: {
      image: {
        required
      },
      threshold: {
        required,
        numeric,
        minValue: 30,
        maxValue: 100
      },
      maxResults: {
        required,
        numeric,
        minValue: 10,
        maxValue: 100
      }
    }
  },
  computed: {
    ...mapGetters({
      getRawAlertIDs: "alertWS/getRawAlertIDs",
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    getRawAlertIDs: {
      handler: function (n, o) {
        var latest_alert_id = n[n.length - 1];
        var alert_data =
          this.$store.getters["alertWS/getRawAlert"](latest_alert_id);

        if (alert_data != undefined && alert_data != null) {
          //
        }
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;
        if (allowed_panels.indexOf("face_search") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    // console.log("Panel Face Search mounted");
    let $this = this;
    this.hasPermission = false;
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user != null ? current_user.panels : [];
    if (allowed_panels.indexOf("face_search") >= 0) {
      this.hasPermission = true;
    }

    $this.$nextTick(() => {
      $this.initForm();
    });
  },
  methods: {
    initForm: function () {
      let dropArea = this.$refs.dropArea;

      // Prevent default drag behaviors
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, this.preventDefaults, false);
        document.body.addEventListener(eventName, this.preventDefaults, false);
      });

      // Highlight drop area when item is dragged over it
      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.addEventListener(eventName, this.highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, this.unhighlight, false);
      });

      // Handle dropped files
      dropArea.addEventListener("drop", this.handleDrop, false);

      this.searchImageBase64 = null;
      this.form.image = null;
    },
    preventDefaults: function (e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight: function (e) {
      let dropArea = this.$refs.dropArea;
      dropArea.classList.add("highlight");
    },
    unhighlight: function (e) {
      let dropArea = this.$refs.dropArea;
      dropArea.classList.remove("active");
    },
    handleDrop: function (e) {
      var dt = e.dataTransfer;
      if (dt.files.length > 0) {
        var file = dt.files[0];
        this.$v.form.image.$model = file;
        this.handleFile();
      }
    },
    handleFile: function () {
      this.resetResults();

      var file = this.$v.form.image.$model;
      this.previewFile(file);
    },
    previewFile: function (file) {
      var $this = this;
      let img;

      if (file != null) {
        this.getBase64(file).then((data) => {
          $this.searchImageBase64 = data;
          img = $this.$refs.imageElement;
          img.src = data;
        });
      } else {
        img = $this.$refs.imageElement;
        img.src = "";
        $this.searchImageBase64 = null;
      }
    },
    getBase64: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    submitForm: function (event) {
      if (event != undefined) {
        event.preventDefault();
      }

      if (this.form.image == null) {
        this.formError = "Please upload an image to search";
        return;
      }

      var $this = this;
      var show_form_success_msg = true;
      var show_load_more_msg = false;
      $this.resetResults();
      $this.searchImage(show_form_success_msg, show_load_more_msg);
    },
    resetForm: function (event) {
      if (event != undefined) {
        event.preventDefault();
      }

      this.form.image = null;
      this.form.threshold = 70;
      this.formError = null;
      this.searchImageBase64 = null;
      this.searchLastResultID = {};
      this.searchResults = {};
      this.imageResults = {};
      this.searchResultsList = [];
    },
    resetResults: function () {
      this.searchLastResultID = {};
      this.searchResults = {};
      this.imageResults = {};
      this.searchResultsList = [];
      this.formSuccess = null;
      this.formError = null;
    },
    searchImage: function (show_form_success_msg, show_load_more_msg) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/face/search/";
      var post_data = {
        image_base64: this.searchImageBase64,
        max_count: this.form.maxResults,
        threshold: this.form.threshold / 100,
        start_ids: this.searchLastResultID
      };

      if ($this.searchApiClient == null) {
        const client = $this.$root.getAjaxFetchClient();
        $this.searchApiClient = client;
      }

      $this.searchApiClient.postRequest(API_URL, post_data).then((response) => {
        if (response != null && response["result"] != undefined) {
          $this.formError = null;
          var logs_total = 0;

          for (var server_url in response["result"]) {
            var results = response["result"][server_url];
            if (results == null) {
              // do nothing
            } else {
              var log_results = results.logs;
              var log_next_start_id = results.nextStartId;
              this.searchLastResultID[server_url] = log_next_start_id;

              logs_total += log_results.length;

              for (var i in log_results) {
                const log_datetime = moment(
                  log_results[i].time,
                  "YYYY-MM-DD hh:mm:ss"
                );
                var result_date_str = log_datetime.format("YYYYMMDD");
                const result_score =
                  log_results[i].calibratedScore != undefined
                    ? log_results[i].calibratedScore
                    : (log_results[i].score * 100).toFixed(2);

                var data = {
                  id: uuid.v4(),
                  serverUrl: server_url,
                  log: log_results[i].log,
                  date: log_datetime.format("DD/MM/YYYY"),
                  time: log_datetime.format("hh:mm:ss"),
                  timestamp: log_datetime.unix(),
                  camera: log_results[i].camera,
                  zone: log_results[i].zone,
                  person: log_results[i].person,
                  name: log_results[i].name,
                  details: log_results[i].details,
                  score: result_score,
                  gender: log_results[i].gender,
                  age: log_results[i].age,
                  outtime: log_results[i].outtime,
                  unknownPersonId: log_results[i].unknownPersonId,
                  hover: false
                };
                if ($this.searchResults[result_date_str] == undefined) {
                  $this.$set($this.searchResults, result_date_str, [data]);
                } else {
                  $this.searchResults[result_date_str].push(data);
                }
              }
            }
          }

          if (show_form_success_msg == true) {
            if (logs_total > 0) {
              $this.formSuccess = "Search returned " + logs_total + " results";
            } else {
              $this.formSuccess = "No results found";
            }
          } else {
            $this.formSuccess = null;
          }

          $this.showLoadMoreButton = true;
          if (show_load_more_msg == true) {
            if (logs_total < this.form.maxResults) {
              $this.showLoadMoreButton = false;
            }
          }

          var sorted_results = [];
          var keys = Object.keys($this.searchResults);
          keys.sort(function (a, b) {
            return b - a;
          });
          for (var k = 0; k < keys.length; k++) {
            var key = keys[k];
            var result_items = $this.searchResults[key];
            result_items.sort(function (c, d) {
              return d.timestamp - c.timestamp;
            });

            var result_data = { key: key, items: result_items };
            sorted_results.push(result_data);

            $this.$set($this.collapseIdList, "collapse-" + key, {
              visible: true
            });
            $this.hasCollapsed = false;
          }

          $this.searchResultsList = sorted_results;

          $this.$nextTick(function () {
            for (var r in $this.searchResults) {
              var result_list = $this.searchResults[r];
              for (var d in result_list) {
                $this.fetchImage(
                  result_list[d]["serverUrl"],
                  result_list[d]["id"],
                  result_list[d]["log"]
                );
              }
            }
          });
        }
      });
    },
    fetchImage: function (server_url, item_id, log_id) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/face/get-image/";
      if ($this.imageApiClient == null) {
        const client = $this.$root.getAjaxFetchClient({ responseType: "blob" });
        $this.imageApiClient = client;
      }

      var post_data = {
        server_url: server_url,
        log_id: log_id
      };

      $this.imageApiClient
        .postRequest(API_URL, post_data)
        .then((image_response) => {
          if (image_response != null) {
            var image_src = URL.createObjectURL(image_response);
            $this.$set($this.imageResults, String(item_id), image_src);
          }
        });
    },
    getImage: function (item_id) {
      return this.imageResults[item_id];
    },
    loadNextSearch: function (event) {
      event.preventDefault();
      var show_form_success_msg = false;
      var show_load_more_msg = true;
      this.searchImage(show_form_success_msg, show_load_more_msg);
    },
    openEnlargedImage: function (item) {
      // set scroll top before opening enlarged image section
      // var lastElement = document.querySelector(".search-result-card[data-id='" + item.id + "']");
      // var topPos = lastElement.offsetTop;
      var topPos = document.getElementById(
        "search-image-panel-content-body"
      ).scrollTop;
      // console.log(topPos);
      this.lastElementTopPos = topPos;

      // obtain image src
      var image_src = this.imageResults[String(item.id)];
      this.enlargedImage = image_src;

      // form image title
      let image_title;
      if (item.person == -1) {
        image_title = "Unknown Person ID: " + item.unknownPersonId;
      } else {
        image_title = "Person Name: " + item.name;
      }
      // console.log(item.score)
      // set enlargedImageDetails
      this.enlargedImageDetails.title = image_title;
      this.enlargedImageDetails.date = item.date;
      this.enlargedImageDetails.time = item.time;
      this.enlargedImageDetails.camera = item.camera;
      this.enlargedImageDetails.score = item.score + "%";
      this.enlargedImageDetails.id = item.id;
      this.enlargedImageDetails.serverUrl = item.serverUrl;

      // open enlarged image section
      this.showEnlargedImage = true;
    },
    closeEnlargedImage: function () {
      // close enlarged image section
      this.showEnlargedImage = false;

      // reset data
      this.enlargedImage = null;
      this.enlargedImageDetails.id = null;
      this.enlargedImageDetails.title = null;
      this.enlargedImageDetails.date = null;
      this.enlargedImageDetails.time = null;
      this.enlargedImageDetails.camera = null;
      this.enlargedImageDetails.score = null;
      this.enlargedImageDetails.serverUrl = null;

      this.$nextTick(function () {
        // set scroll top
        document.getElementById("search-image-panel-content-body").scrollTop =
          this.lastElementTopPos;
      });
    },
    toggleCollapse: function (collapse_id) {
      if (this.collapseIdList[collapse_id] != undefined) {
        var new_value = !this.collapseIdList[collapse_id].visible;
        this.$set(this.collapseIdList, collapse_id, { visible: new_value });
      }

      var has_collapsed_sections = false;
      for (var i in this.collapseIdList) {
        if (this.collapseIdList[i].visible == false) {
          has_collapsed_sections = true;
          break;
        }
      }
      this.hasCollapsed = has_collapsed_sections;
    },
    collapseAll: function (event) {
      event.preventDefault();
      this.hasCollapsed = true;

      for (var i in this.collapseIdList) {
        this.$set(this.collapseIdList, i, { visible: false });
      }
    },
    uncollapseAll: function (event) {
      event.preventDefault();
      this.hasCollapsed = false;

      for (var i in this.collapseIdList) {
        this.$set(this.collapseIdList, i, { visible: true });
      }
    }
  }
};
</script>

<style lang="scss">
@import "./PanelFaceSearch.scss";
</style>
