<template>
  <div class="panel-content">
    <div
      class="panel-content-header d-flex align-items-center justify-content-between"
    >
      <h2>LPR Real-time Alerts</h2>
    </div>

    <div class="panel-content-body scroller" v-if="hasPermission">
      <LprCardAlert
        v-for="alert in alerts"
        :key="alert.id"
        :alert="alert"
        :panel="currentPanelName"
        @discard-alert="discardAlertHandler"
      />
    </div>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LprCardAlert from "@/components/LprCardAlert/LprCardAlert.vue";
import moment from "moment";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelLprEventCard",
  components: {
    LprCardAlert
  },
  props: {
    item: {},
    workspaceId: {}
  },
  data() {
    return {
      panel_info: this.item,
      workspaceIDs: this.workspaceId,

      alerts: [],
      availableFilters: [],
      currentFilters: {},
      selectedFilters: null,
      showFilters: false,
      currentFiltersText: "",
      availableSort: [],
      currentSort: {},
      selectedSort: null,
      showSort: false,
      currentSortText: "",
      getImageQueue: [],
      hasPermission: false,
      currentPanelName: ""
    };
  },
  computed: {
    ...mapGetters({
      isCamerasInitiated: "psim/isCamerasInitiated",
      isAlertsInitiated: "psim/isAlertsInitiated",
      getAlertIDs: "alertWS/getAlertIDs",
      getDiscardedAlertIDs: "alertWS/getDiscardedAlertIDs",
      getCurrentUser: "session/getCurrentUser"
    })
  },
  watch: {
    currentFilters: {
      handler: function (n, o) {
        // get alerts from store
        // console.log("Panel Alerts - currentFilters changed");
        var alert_list = this.$store.getters["alertWS/filterAlerts"](n);
        this.alerts = alert_list;
      },
      deep: true
    },
    isCamerasInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.fetchFilters();
          this.fetchSort();
          // this.fetchAlerts();
        }
      },
      deep: true
    },
    getAlertIDs: {
      handler: function (n, o) {
        var latest_alert_id = n[n.length - 1];
        var alert_data =
          this.$store.getters["alertWS/getAlert"](latest_alert_id);

        if (alert_data != undefined && alert_data != null) {
          // console.log(alert_data);
          this.createAlert(alert_data);
          this.renderAlerts();
        }
      },
      deep: true
    },
    getDiscardedAlertIDs: {
      handler: function (n, o) {
        // console.log(n, "vs", o)
        this.checkDiscardedAlerts(n);
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        var allowed_panels = n.panels;

        if (allowed_panels.indexOf("lpr_alerts") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    }
  },
  mounted: function () {
    var current_user = this.$store.getters["session/getCurrentUser"];
    var allowed_panels = current_user.panels;

    if (allowed_panels.indexOf("lpr_alerts") >= 0) {
      this.hasPermission = true;
      this.currentPanelName = "lpr_alerts";
    }
  },
  methods: {
    // ------- Filters --------
    fetchFilters: function () {
      var building_list = [];
      var building_qs = this.$store.getters["psim/getBuildings"]();
      for (var b in building_qs) {
        building_list.push({
          id: building_qs[b].id,
          name: building_qs[b].name
        });
      }

      var floor_list = [];
      var floor_qs = this.$store.getters["psim/getFloors"]();
      for (var f in floor_qs) {
        floor_list.push({
          id: floor_qs[f].id,
          name: floor_qs[f].building + " - " + floor_qs[f].name
        });
      }

      var zone_list = [];
      var zone_qs = this.$store.getters["psim/getZones"]();
      for (var z in zone_qs) {
        zone_list.push({
          id: zone_qs[z].id,
          name:
            zone_qs[z].building +
            " - " +
            zone_qs[z].floor +
            " - " +
            zone_qs[z].name
        });
      }

      var camera_list = [];
      var camera_qs = this.$store.getters["psim/getCameras"]();
      for (var c in camera_qs) {
        camera_list.push({ ip: camera_qs[c].ip, name: camera_qs[c].name });
      }

      var priority_list = [
        { id: 1, name: "Low" },
        { id: 2, name: "Normal" },
        { id: 3, name: "High" }
      ];

      this.availableFilters = [
        {
          label: "Building",
          key: "building__id",
          type: "select",
          choices: building_list,
          obj_key: "id",
          obj_value: "name"
        },
        {
          label: "Floor",
          key: "floor__id",
          type: "select",
          choices: floor_list,
          obj_key: "id",
          obj_value: "name"
        },
        {
          label: "Zone",
          key: "zone__id",
          type: "select",
          choices: zone_list,
          obj_key: "id",
          obj_value: "name"
        },
        {
          label: "Device",
          key: "device_ip",
          type: "select",
          choices: camera_list,
          obj_key: "ip",
          obj_value: "name"
        },
        {
          label: "Priority",
          key: "priority",
          type: "select",
          choices: priority_list,
          obj_key: "id",
          obj_value: "name"
        },
        {
          label: "Start",
          key: "created__gte",
          type: "datetime"
        },
        {
          label: "End",
          key: "created__lte",
          type: "datetime"
        }
      ];

      this.selectedFilters = {
        device_ip: null,
        priority: null,
        created__gte: null,
        created__lte: null
      };
    },
    updateFilters: function (filterKey, selectedItem, objectKey, objectValue) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentFilters[filterKey];
      } else {
        if (objectKey == null) {
          this.currentFilters[filterKey] = selectedItem;
        } else {
          this.currentFilters[filterKey] = selectedItem[objectKey];
        }
      }

      var filter_count = Object.keys(this.currentFilters).length;
      if (filter_count > 1) {
        this.currentFiltersText = filter_count + " selected filters";
      } else if (filter_count == 1) {
        if (objectValue == null) {
          this.currentFiltersText = this.selectedFilters[filterKey];
        } else {
          this.currentFiltersText =
            this.selectedFilters[filterKey][objectValue];
        }
      } else if (filter_count < 1) {
        this.currentFiltersText = "";
      }
    },
    startFilter: function () {
      var alert_list = this.$store.getters["alertWS/filterAlerts"](
        this.currentFilters
      );
      this.alerts = alert_list;
      this.showFilters = false;
      this.startSort();
    },
    clearFilter: function () {
      this.currentFilters = {};
      for (var i in this.selectedFilters) {
        this.selectedFilters[i] = null;
      }

      var alert_list = this.$store.getters["alertWS/filterAlerts"](
        this.currentFilters
      );
      this.alerts = alert_list;
      this.showFilters = false;
      this.currentFiltersText = "";
      this.startSort();
    },
    // ------- Sort --------
    fetchSort: function () {
      this.availableSort = [
        {
          label: "Time",
          key: "created",
          type: "select",
          choices: [
            { order: "descending", name: "Latest to oldest" },
            { order: "ascending", name: "Oldest to latest" }
          ],
          value_type: "datetime"
        },
        {
          label: "Priority",
          key: "priority",
          type: "select",
          choices: [
            { order: "descending", name: "Highest priority to lowest" },
            { order: "ascending", name: "Lowest priority to highest" }
          ],
          value_type: "integer"
        }
      ];

      this.selectedSort = { created: null, priority: null };
    },
    updateSort: function (sortType, sortKey, selectedItem) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentSort[sortKey];
      } else {
        this.currentSort[sortKey] = {
          type: sortType,
          order: selectedItem["order"]
        };
      }

      var sort_list = [];
      for (var s in this.currentSort) {
        sort_list.push(this.selectedSort[s]["name"]);
      }
      this.currentSortText = sort_list.join(", ");
    },
    startSort: function () {
      for (var s in this.currentSort) {
        var sort_item = this.currentSort[s];
        var sort_type = sort_item["type"];
        var sort_order = sort_item["order"];
        var sort_key = s;

        this.alerts.sort(function (a, b) {
          if (sort_type == "datetime") {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if (sort_order == "descending") {
              return new Date(b[sort_key]) - new Date(a[sort_key]);
            } else if (sort_order == "ascending") {
              return new Date(a[sort_key]) - new Date(b[sort_key]);
            }
          } else if (sort_type == "integer") {
            if (sort_order == "descending") {
              return parseInt(b[sort_key]) - parseInt(a[sort_key]);
            } else if (sort_order == "ascending") {
              return parseInt(a[sort_key]) - parseInt(b[sort_key]);
            }
          }
        });
      }

      this.showSort = false;
    },
    clearSort: function () {
      this.currentSort = {};
      for (var i in this.selectedSort) {
        this.selectedSort[i] = null;
      }

      this.alerts
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
        .sort(function (a, b) {
          return parseInt(b.priority) - parseInt(a.priority);
        });

      this.showSort = false;
      this.currentSortText = "";
    },
    // --------- Alerts -----------
    createAlert: function (alert) {
      var alert_data = alert;
      var can_add_to_alerts = true;
      if (this.currentFilters != null) {
        for (var key in this.currentFilters) {
          var result = utils.searchInDict(
            alert_data,
            key,
            this.currentFilters[key]
          );
          if (result == false) {
            can_add_to_alerts = false;
          }
        }
      }
      if (
        can_add_to_alerts &&
        alert_data.alert_type == "licence_plate_detected"
      ) {
        this.alerts.push(alert_data);
      }
    },
    renderAlerts: function () {
      // Remove old alerts to prevent memory hogging
      this.alerts.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });

      // var max_alerts = 30;
      // if (this.alerts.length > max_alerts) {
      //   while (this.alerts.length > max_alerts) {
      //       this.alerts.pop();
      //   }
      // }

      this.startSort();
    },
    discardAlertHandler: function (alert) {
      let $this = this;
      let alert_index = null;
      for (var i in this.alerts) {
        if (this.alerts[i].id == alert.id) {
          alert_index = i;
          break;
        }
      }
      if (alert_index != null) {
        this.alerts.splice(alert_index, 1);
      }
    },
    checkDiscardedAlerts: function (discardedAlertIDs) {
      let alert_index = null;
      for (var i in this.alerts) {
        let alert_id = String(this.alerts[i].id);
        if (discardedAlertIDs.indexOf(alert_id) >= 0) {
          alert_index = i;
          break;
        }
      }
      if (alert_index != null) {
        this.alerts.splice(alert_index, 1);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./PanelLprEventCard.scss";
</style>
