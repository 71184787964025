<template>
  <div
    class="building-card cursor-pointer"
    @click="viewBuildingDetails($event, building.id)"
    :data-building-id="building.id"
  >
    <div class="building-card-inner">
      <div class="overlay-card"></div>
      <div class="building-card-content">
        <div class="building-card-details">
          <div class="building-card-title">{{ building.name }}</div>
        </div>
      </div>
      <div class="building-card-actions">
        <b-button variant="danger" class="mr-2 stats-button" v-if="alerts > 0">
          <i class="fa fa-exclamation-circle"></i>
          <div>{{ alerts }}</div>
        </b-button>
        <b-button variant="success" class="mr-2 stats-button" v-else>
          <i class="fa fa-exclamation-circle"></i>
          <div>{{ alerts }}</div>
        </b-button>

        <b-button
          variant="warning"
          class="cursor-pointer stats-button"
          v-if="cases > 0"
        >
          <i class="fa fa-clipboard"></i>
          <div>{{ cases }}</div>
        </b-button>
        <b-button variant="success" class="mr-2 stats-button" v-else>
          <i class="fa fa-clipboard"></i>
          <div>{{ cases }}</div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      alerts: 0,
      cases: 0
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation",
      getAlertIDs: "alertWS/getAlertIDs",
      getCaseIDs: "caseWS/getCaseIDs",
      getBuildingAlerts: "psim/getBuildingAlerts"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        // this.initPanel();
      },
      deep: true
    },
    getBuildingAlerts: {
      handler: function (n, o) {
        // console.log("Building Alerts")
        this.getAlerts();
      },
      deep: true
    },
    getCaseIDs: {
      handler: function (n, o) {
        this.getCases();
      },
      deep: true
    }
  },
  mounted: function () {
    this.getAlerts();
    this.getCases();
  },
  methods: {
    viewBuildingDetails: function (event) {
      // console.log(this.building.id)
      this.initView(this.building);
      // this.$store.dispatch('psim/setViewingDetails', {"type": "building_details", "data": this.building});
    },
    initView: function () {
      var building_obj = this.$store.getters["psim/getBuilding"](
        this.building.id
      );
      // var location = this._getViewingLocation;
      // location["building"] = building_obj;
      // location["floor"] = null;
      // location["zone"] = null;
      // this.$store.dispatch('psim/setViewingLocation', location);

      this.$router
        .push({ name: "PSIM", query: { building: this.building.id } })
        .catch(() => {});
    },
    getAlerts: function () {
      var building_alerts =
        this.$store.getters["psim/getBuildingAlerts"][this.building.id];
      if (building_alerts != undefined) {
        this.alerts = building_alerts.length;
      }
      // console.log("Getting Alerts", building_alerts);
    },
    getCases: function () {
      var building_cases =
        this.$store.getters["psim/getBuildingCases"][this.building.id];
      if (building_cases != undefined) {
        this.cases = building_cases.length;
      }
    }
  }
};
</script>

<style lang="scss">
@import "CardBuilding.scss";
</style>
