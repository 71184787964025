<template>
  <div class="panel-content vh-100" ref="panelLprEventTableComponent">
    <div class="panel-content-header d-flex justify-content-between">
      <h2 class="my-4 pt-1">LPR Events</h2>
      <b-form-checkbox
        v-if="getScMode === 'SC_DASHBOARD'"
        class="ml-3"
        size="lg"
        name="check-button"
        switch
        v-b-tooltip.hover
        title="Change to advanced mode"
        v-model="advanceMode"
      >
        <!--        Advance Mode-->
      </b-form-checkbox>
      <div class="ml-auto p-2">
        <div v-if="hasPermission">
          <div class="d-inline-flex p-2">
            <div class="camera-multi-select">
              <multiselect
                v-model="filteredCameraEvent"
                :options="camera_list"
                :multiple="true"
                :taggable="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                placeholder="Choose Camera"
                label="name"
                track-by="name"
                @close="filterCameraForEvent"
              >
                <template slot="tag">{{ "" }}</template>
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} camera(s) selected</span
                  >
                </template>
              </multiselect>
            </div>
            <b-button
              size="sm"
              v-b-tooltip.hover.bottom
              title="Save Camera"
              variant="success"
              @click="onSubmitFormAddDevice"
            >
              <i class="fa fa-save mr-1"></i>Save
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.hover.bottom
              title="Search LPR Events"
              @click="showSearchPanel = true"
            >
              <i class="fa fa-search"></i>
            </b-button>
            <!-- <b-button
              size="sm"
              class=""
              title="Reset LPR Events"
              @click="clearFilter('reset')"
              :disabled="showClear == false"
              ><i class="fa fa-refresh"></i
            ></b-button> -->
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showSearchPanel"
      class=""
      style="background-color: var(--colour-surface2)"
    >
      <div class="d-flex justify-content-between p-2">
        <h2>LPR Search</h2>
        <div class="d-flex justify-content-end">
          <router-link
            :to="{
              name: '/reports/vms/report-on-demand'
            }"
            v-slot="{ href, route, navigate }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              class="mr-2"
              v-b-tooltip.hover.bottom
              title="Report On Demand"
            >
              <u>Historical</u></a
            >
          </router-link>
          <b-button @click="showSearchPanel = false" size="sm"
            ><i class="fa fa-times"></i
          ></b-button>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between p-2">
        <div class="col-6">
          <h2>Start Datetime - End Datetime</h2>
          <date-range-picker
            ref="picker"
            :opens="'right'"
            class="w-100"
            v-model="queryForm.dateRange"
            :showDropdowns="true"
            :locale-data="{
              firstDay: 1,
              format: 'yyyy-mm-dd HH:MM:ss'
            }"
            :time-picker-increment="1"
            :time-picker="true"
            :time-picker-seconds="true"
            :single-date-picker="false"
          >
          </date-range-picker>
        </div>
      </div>
      <div class="d-flex justify-content-end p-2">
        <b-button
          size="sm"
          variant="secondary"
          @click="clearFilter"
          class="mr-1"
          ><i class="fa fa-times mr-1"></i>Clear</b-button
        >
        <b-button size="sm" variant="success" @click="startFilter"
          ><i class="fa fa-filter mr-1"></i>Filter</b-button
        >
      </div>
    </div>

    <div class="panel-content-body">
      <!-- normal mode-->
      <div v-if="hasPermission && advanceMode === false">
        <b-table
          :stickyColumn="true"
          :responsive="true"
          :no-border-collapse="true"
          :items="event_list"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-clicked="goToEventDetail"
          :busy="busy"
          stacked="md"
          sticky-header
          striped
          small
          borderless
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(created)="data">
            {{ getFormatedDateTime(data.item.created) }}
          </template>

          <template #cell(camera_ip)="data">
            <!-- {{ getCameraName(data.item.camera_ip) }} -->
            {{ data.item.camera_ip }}
          </template>

          <template #cell(license_plate)="data">
            {{ data.item.license_plate }}
          </template>

          <template #cell(data.metadata.person_data.person_no)="data">
            <div v-if="data.item.data.metadata.person_data != undefined">
              {{ data.item.data.metadata.person_data.person_no }}
            </div>
            <div v-else>
              {{ "N/A" }}
            </div>
          </template>

          <template #cell(data.metadata.person_data.person_name)="data">
            <div v-if="data.item.data.metadata.person_data != undefined">
              {{ data.item.data.metadata.person_data.person_name }}
            </div>
            <div v-else>
              {{ "N/A" }}
            </div>
          </template>

          <template #cell(data.metadata.person_data)="data">
            <div
              class="text-center text-success"
              v-if="data.item.data.metadata.person_data != undefined"
            >
              <i class="fas fa-check fa-2x"></i>
            </div>
            <div
              class="text-center text-danger"
              v-if="data.item.data.metadata.person_data == undefined"
            >
              <i class="fas fa-times fa-2x"></i>
            </div>
          </template>
        </b-table>
      </div>

      <!-- advance mode-->
      <div v-if="hasPermission && advanceMode === true">
        <b-table
          :responsive="true"
          :no-border-collapse="true"
          :items="event_list"
          :fields="fieldsAdvanced"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          stacked="md"
          head-variant="dark"
          striped
          small
          borderless
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(created)="data">
            {{ getFormatedDateTime(data.item.created) }}
          </template>

          <template #cell(description)="data">
            <b-row class="mb-2">
              <b-col>Car Plate: {{ data.item.license_plate }}</b-col>
              <b-col>Name: {{ data.item.user_name }}</b-col>
              <b-col>User ID: {{ data.item.user_id }}</b-col>
              <!-- <b-col v-if="data.item.recognized_plate !== 'No'">Card Info.: {{ data.item.card_info }}</b-col> -->
              <b-col>Floor: {{ data.item.floor || "-" }}</b-col>
              <b-col>Building: {{ data.item.building || "-" }} </b-col>
              <!-- <b-col>Zone: {{ data.item.zone || "-" }} </b-col> -->
            </b-row>
            <!-- <b-row class="mb-2" v-if="data.item.door_info.length !== 0"> -->
            <b-row
              class="mb-2"
              v-for="door in data.item.door_info"
              :key="door.id"
            >
              <b-col
                >TIme: {{ getFormatedDateTime(door.created_door_open) }}
              </b-col>
              <b-col>Door Status: {{ door.door_status || "-" }} </b-col>
              <b-col>Door No.: {{ door.door_no || "-" }} </b-col>
              <b-col>Card No.: {{ door.card_no || "-" }} </b-col>
              <b-col>Group Name: {{ door.library_name || "-" }} </b-col>
              <b-col
                >Profile Name:
                {{ door.libraryimage_profile_name || "-" }}</b-col
              >
              <b-col
                >Image Profile :
                <img :src="door.libraryimage_image" width="50" height="50" />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </div>

      <!-- event details-->
      <transition name="event-detail-slide" mode="out-in">
        <div
          v-if="showEventDetails"
          class="panel-event-detail"
          ref="eventDetailPanel"
        >
          <div class="d-flex justify-content-between p-2">
            <div class="mt-3 ml-3 mb-2">
              <h2>Event Detail</h2>
            </div>
            <b-button @click="showEventDetails = false" size="sm" class="mr-1"
              ><i class="fa fa-times"></i
            ></b-button>
          </div>
          <div class="d-flex justify-content-center">
            <div class="d-flex">
              <div class="d-flex justify-content-end mr-2">
                <div class="alert-card-image mb-2">
                  <img
                    v-if="eventObj.image !== undefined"
                    :src="`data:image/png;base64,${eventObj.image}`"
                    @click="showPhoto('Vehicle Image', eventObj.image)"
                  />
                  <img
                    v-else
                    src="@/assets/global/images/icons/profile_sample.jpg"
                    @click="showPhoto('Vehicle Image', 'image')"
                  />
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="alert-card-image-small mb-2">
                  <img
                    v-if="eventObj.person_photo !== ''"
                    :src="`data:image/png;base64,${eventObj.person_photo}`"
                    @click="showPhoto('User Image', eventObj.person_photo)"
                  />
                  <img
                    v-else
                    src="@/assets/global/images/icons/profile_sample.jpg"
                    @click="showPhoto('User Image', 'image')"
                  />
                </div>
                <div class="alert-card-image-small">
                  <img
                    v-if="eventObj.cropped_image !== ''"
                    :src="`data:image/png;base64,${eventObj.cropped_image}`"
                    @click="
                      showPhoto('License Plate Image', eventObj.cropped_image)
                    "
                  />
                  <img
                    v-else
                    src="@/assets/global/images/icons/profile_sample.jpg"
                    @click="showPhoto('License Plate Image', 'image')"
                  />
                </div>
              </div>
            </div>
            <div class="alert-card-details">
              <div class="event-alert-card-desc">
                <br />
                <strong>Event Time:</strong>
                {{ getFormatedDateTime(eventObj.created) }}<br />
                <strong>Event Status:</strong> N/A <br />
                <strong>Camera Name: </strong>
                {{ getCameraName(eventObj.camera_ip) }}<br />
                <strong>Vehicle Plate Number:</strong>
                {{ eventObj.license_plate }}<br />
                <strong>User ID:</strong> {{ eventObj.user_id }}<br />
                <strong>User Name:</strong> {{ eventObj.user_name }}<br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- search details-->
      <!-- <transition name="search-detail-slide" mode="out-in">
        <div v-if="showSearchPanel" class="panel-search-detail">
          <div class="d-flex justify-content-between p-2">
            <h2>LPR Search</h2>

            <div class="d-flex justify-content-end">
              <router-link
                :to="{
                  name: '/reports/vms/report-on-demand'
                }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <a :href="href" @click="navigate" class="mr-2">
                  <u>Historical</u></a
                >
              </router-link>
              <b-button @click="showSearchPanel = false" size="sm"
                ><i class="fa fa-times"></i
              ></b-button>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between p-2">
            <div class="col-6">
              <h2>Start Datetime - End Datetime</h2>
              <date-range-picker
                ref="picker"
                :opens="'right'"
                class="w-100"
                v-model="queryForm.dateRange"
                :showDropdowns="true"
                :locale-data="{
                  firstDay: 1,
                  format: 'yyyy-mm-dd HH:MM:ss'
                }"
                :time-picker-increment="1"
                :time-picker="true"
                :time-picker-seconds="true"
                :single-date-picker="false"
              >
              </date-range-picker>
            </div>
          </div>
          <div class="d-flex justify-content-end p-2">
            <b-button
              size="sm"
              variant="secondary"
              @click="clearFilter"
              class="mr-1"
              ><i class="fa fa-times mr-1"></i>Clear</b-button
            >
            <b-button size="sm" variant="success" @click="startFilter"
              ><i class="fa fa-filter mr-1"></i>Filter</b-button
            >
          </div>
        </div>
      </transition> -->

      <!--<transition name="search-detail-slide" mode="out-in">
      <div v-if="showSearchPanel" class="panel-search-detail">
        <div class="d-flex justify-content-between p-2">
          <h1>LPR Search</h1>
          <b-button @click="showSearchPanel = false" size="sm"
            ><i class="fa fa-times"></i
          ></b-button>
        </div>
        <div class="row justify-content-between px-2">
          <div
            class="col-6"
            v-for="filter in availableFilters"
            :key="'filter_' + filter.key"
          >
            <h2>{{ filter.label }}</h2>
            <div v-if="filter.type == 'select'">
              <v-select
                class="filter-select"
                v-model="selectedFilters[filter.key]"
                :label="filter.obj_value"
                :options="filter.choices"
                @input="
                  (item) =>
                    updateFilters(
                      filter.key,
                      item,
                      filter.obj_key,
                      filter.obj_value
                    )
                "
              />
            </div>
            <div v-else-if="filter.type == 'multiple'">
              <v-select
                class="filter-select"
                v-model="selectedFilters[filter.key]"
                :label="filter.obj_value"
                :options="filter.choices"
                @input="
                  (item) =>
                    updateFilters(
                      filter.key,
                      item,
                      filter.obj_key,
                      filter.obj_value
                    )
                "
              />
            </div>
            <div v-else-if="filter.type == 'datetime'">
              <v-datetime
                type="datetime"
                v-model="selectedFilters[filter.key]"
                format="dd/MM/yyyy HH:mm:ss"
                @input="updateFilters(filter.key, $event, null, null)"
              />
            </div>
            <div v-else>
              <input
                v-model="selectedFilters[filter.key]"
                placeholder="Select date and time"
                @input="updateFilters(filter.key, $event, null, null)"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-end px-4 my-4">
          <b-button
            size="sm"
            variant="secondary"
            @click="clearFilter"
            class="mr-1"
            ><i class="fa fa-times mr-1"></i>Clear</b-button
          >
          <b-button size="sm" variant="success" @click="startFilter"
            ><i class="fa fa-filter mr-1"></i>Filter</b-button
          >
        </div>
      </div>
    </transition> -->
    </div>

    <b-modal
      content-class="showImageModal"
      v-model="showModal"
      :title="selectedImageTitle"
      @close="showModal = false"
      :hide-footer="true"
      centered
    >
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="alert-card-image-large mb-2">
            <img :src="`data:image/png;base64,${selectedImage}`" />
          </div>
        </div>
      </div>
    </b-modal>

    <div v-if="!hasPermission" class="text-center">
      You don't have permission to view this panel.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "PanelLprEventTable",
  components: { Multiselect, DateRangePicker },
  props: {
    item: {},
    workspaceId: {}
  },
  data: function () {
    return {
      panel_info: this.item,
      workspaceID: this.workspaceId,
      busy: false,
      fields: [
        {
          key: "created",
          label: "Datetime",
          sortable: true,
          formatter: "getFormatedDateTime"
        },
        { key: "data.metadata.camera_ip", label: "Camera", sortable: true },
        {
          key: "data.metadata.license_plate",
          label: "Vehicle",
          sortable: true
        },
        {
          key: "data.metadata.person_data.person_no",
          label: "User ID",
          sortable: true
        },
        {
          key: "data.metadata.person_data.person_name",
          label: "Name",
          sortable: true
        },
        {
          key: "data.metadata.person_data",
          label: "Recognized",
          sortable: true
        }
      ],
      fieldsAdvanced: [
        {
          key: "created",
          label: "Datetime",
          sortable: true,
          formatter: "getFormatedDateTime"
        },
        { key: "description", label: "Description", sortable: true }
      ],
      availableFilters: [],
      camera_list: [],
      event_list: [],
      old_event_list: [],
      all_event_list: [],
      old_all_event_list: [],
      event_staff_dict: {},
      cameraObj: null,
      userId: null,
      userName: null,
      eventObj: null,
      filteredCameraEvent: null,
      filteredPreferencesCameraEvent: [],
      selectedCameraEvent: null,
      selectedImage: null,
      showModal: false,
      advanceMode: false,
      hasPermission: false,
      showSearchPanel: false,
      //showClear: false,
      showEventDetails: false,
      sortDesc: true,
      sortBy: "created",
      selectedImageTitle: ",",
      panel_event_detail_top: "-50%",
      image: "@/assets/global/images/icons/profile_sample.jpg",
      searchType: "camera",
      selectedFilters: null,
      currentFilters: {},
      alerts: [],
      currentFiltersText: "",

      currentUser: null,
      currentUserWorkspaces: null,
      currentUserWorkspaceID: null,
      currentUserPanel: null,
      currentUserPanelNum: null,
      currentUserCameraIp: null,
      currentUserCameraId: null,
      currentUserVideoFormat: null,
      preferences: {},
      formUpdate: {
        preferences: {}
      },
      merged: {
        workspaces: {}
      },
      queryForm: {
        dateRange: {
          startDate: null,
          endDate: null
        }
      },
      search: false,
      intervalId: null
    };
  },
  created() {
    window.addEventListener("resize", this.handleDynamicPanelEventDetail);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleDynamicPanelEventDetail);
  },
  computed: {
    ...mapGetters({
      isCamerasInitiated: "psim/isCamerasInitiated",
      getAPIServerURL: "session/getAPIServerURL",
      getAlertIDs: "alertWS/getAlertIDs",
      getScMode: "session/getScMode"
    }),
    eventDetailPanelHeight() {
      if (this.$refs.eventDetailPanel !== undefined) {
        return this.$refs.eventDetailPanel.clientHeight;
      } else {
        return null;
      }
    }
  },
  watch: {
    currentFilters: {
      handler: function (n, o) {
        // get alerts from store
        // console.log("Panel Alerts - currentFilters changed");
        /* let alert_list = this.$store.getters["alertWS/filterAlerts"](n);
           this.alerts = alert_list; */
        // let camera_qs = this.$store.getters["psim/getCameras"]();
        // for (let c in camera_qs) {
        //     this.camera_list.push({"ip": camera_qs[c].ip, "name": camera_qs[c].name})
        // }
        // this.setFilters()
      },
      deep: true
    },
    isCamerasInitiated: {
      handler: function (n, o) {
        if (n == true) {
          console.log("isCamerasInitiated", n);
          this.setFilters();
        }
      },
      deep: true
    },
    getCurrentUser: {
      handler: function (n, o) {
        let allowed_panels = n.panels;
        if (allowed_panels.indexOf("lpr_transactions") >= 0) {
          this.hasPermission = true;
        } else {
          this.hasPermission = false;
        }
      },
      deep: true
    },
    getAlertIDs: {
      async handler(n, o) {
        let $this = this;
        let latest_alert_id = n[n.length - 1];
        let alert_data =
          $this.$store.getters["alertWS/getAlert"](latest_alert_id);
        let all_event_list = [];
        if (
          alert_data != undefined &&
          alert_data != null &&
          alert_data.alert_type == "licence_plate_detected"
        ) {
          //1. fetchStaffDetail(event_id) --> api call
          let alert_data_info = {
            cropped_image: alert_data.metadata.cropped_image,
            image: alert_data.metadata.image,
            camera_ip: alert_data.metadata.camera_ip,
            license_plate: alert_data.metadata.license_plate,
            created: alert_data.created,
            user_id: null,
            user_name: null,
            recognized_plate: null,
            person_photo: null,
            floor: null,
            building: null,
            door_info: []
          };

          $this
            .getStaffDetails(alert_data.metadata.license_plate)
            .then((staffDetail) => {
              // console.log("staffDetail in PanelLPREventTable");
              // console.log(staffDetail);

              try {
                if (staffDetail.person_no != null) {
                  alert_data_info.user_id = staffDetail.person_no;
                  alert_data_info.user_name = staffDetail.person_name;
                  alert_data_info.recognized_plate = "Yes";
                  alert_data_info.person_photo = staffDetail.person_photo;
                  alert_data_info.floor = alert_data.floor.name;
                  alert_data_info.building = alert_data.building.name;
                  /* zone: null, */
                  //card_info: staffDetail.cards,
                  alert_data_info.door_info = [];

                  all_event_list.push(alert_data_info);
                } else {
                  alert_data_info.user_id = "N/A";
                  alert_data_info.user_name = "N/A";
                  alert_data_info.recognized_plate = "No";
                  alert_data_info.person_photo = "";
                  alert_data_info.floor = alert_data.floor.name;
                  alert_data_info.building = alert_data.building.name;
                  /* zone: null, */
                  alert_data_info.door_info = [];

                  all_event_list.push(alert_data_info);
                }
              } catch (e) {
                alert_data_info.user_id = "N/A";
                alert_data_info.user_name = "N/A";
                alert_data_info.recognized_plate = "No";
                alert_data_info.person_photo = "";
                alert_data_info.floor = alert_data.floor.name;
                alert_data_info.building = alert_data.building.name;
                /* zone: null, */
                alert_data_info.door_info = [];

                all_event_list.push(alert_data_info);
              }

              $this.filterCameraForEvent();
            });
        } else if (
          alert_data != undefined &&
          alert_data != null &&
          (alert_data.alert_type == "door_open" ||
            alert_data.alert_type == "door_event")
        ) {
          // console.log("door open : " + alert_data);
          // console.log("door event : " + alert_data);
          // debugger; // eslint-disable-line
          let door_info = {
            id: alert_data.id,
            created_door_open: alert_data.created,
            door_status: alert_data.title,
            door_no: alert_data.metadata.door_no,
            card_no: alert_data.metadata.card_no,
            library_name: alert_data.matched_libraryimage.library_name,
            libraryimage_image:
              alert_data.matched_libraryimage.libraryimage_image,
            libraryimage_profile_name:
              alert_data.matched_libraryimage.libraryimage_profile_name
          };

          for (var key in all_event_list) {
            all_event_list[key].door_info.push(door_info);
          }

          $this.filterCameraForEvent();
        }
      },
      deep: true
    },
    eventDetailPanelHeight: {
      handler: function (n, o) {
        // console.log("watch eventDetailPanel");
        // console.log(n);
        //this.handleDynamicPanelEventDetail();
      },
      deep: true
    }
  },
  mounted: function () {
    //let allowed_panels = this.currentUser.panels;
    this.currentUser = this.$store.getters["session/getCurrentUser"];
    if (
      this.currentUser.preferences === null ||
      Object.keys(this.currentUser.preferences).length === 0
    ) {
      //
      if (this.currentUser.panels.indexOf("lpr_transactions") >= 0) {
        this.hasPermission = true;
        this.setFilters();
      }

      if (this.$refs.eventDetailPanel != undefined) {
        // console.log(this.$refs.eventDetailPanel);
      }

      if (this.$refs.panelLprEventTableComponent != undefined) {
        //this.handleDynamicPanelEventDetail();
      }
    } else {
      //
      this.getCurrentUserPreferences();
    }
    this.getEvents();
    this.timer();
  },
  methods: {
    getCurrentUserPreferences() {
      this.currentUserWorkspaces = this.currentUser.preferences.workspaces;

      for (let workspaceID in this.currentUserWorkspaces) {
        if (workspaceID == this.workspaceID) {
          this.currentUserWorkspaceID = workspaceID;
        }
      }

      if (this.currentUserWorkspaceID != null) {
        for (let panel in this.currentUserWorkspaces[
          this.currentUserWorkspaceID
        ].custom) {
          if (panel == "lpr_transactions") {
            this.currentUserPanel = panel;
          }
        }

        if (this.currentUserPanel != null) {
          for (let num in this.currentUserWorkspaces[
            this.currentUserWorkspaceID
          ].custom[this.currentUserPanel]) {
            if (num == this.panel_info.num) {
              this.currentUserPanelNum = num;
            }
          }
        }

        if (this.currentUserPanelNum != null) {
          for (let key in this.currentUserWorkspaces[
            this.currentUserWorkspaceID
          ].custom[this.currentUserPanel][this.currentUserPanelNum]
            .selected_camera_list) {
            this.filteredPreferencesCameraEvent.push({
              ip: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                .custom[this.currentUserPanel][this.currentUserPanelNum]
                .selected_camera_list[key],
              name: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                .custom[this.currentUserPanel][this.currentUserPanelNum]
                .selected_camera_list[key]
            });
          }

          /* this.filteredCameraEvent =
            this.currentUserWorkspaces[this.currentUserWorkspaceID].custom[
              this.currentUserPanel
            ][this.currentUserPanelNum].selected_camera_list; */

          this.filteredCameraEvent = this.filteredPreferencesCameraEvent;
        }
      }

      if (this.currentUser.panels.indexOf("lpr_transactions") >= 0) {
        this.hasPermission = true;
        this.setFilters();
      }

      if (this.$refs.eventDetailPanel != undefined) {
        // console.log(this.$refs.eventDetailPanel);
      }

      if (this.$refs.panelLprEventTableComponent != undefined) {
        //this.handleDynamicPanelEventDetail();
      }
    },
    timer() {
      let that = this;

      this.intervalId = window.setInterval(function () {
        console.log("polling...", this.intervalId);
        if (that.search == false) {
          that.getEvents();
        }
      }, 8000);
      this.$store.dispatch("alertWS/updateIntervalId", this.intervalId);
    },
    getEvents() {
      let $this = this;
      this.busy = true;

      this.all_event_list = [];

      let startDate = moment().subtract(1, "days");
      let endDate = moment();

      let query = {
        start_date: moment(startDate).toISOString(),
        end_date: moment(endDate).toISOString(),
        event_type: "lpr"
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (result[validUrl].result.length > 0) {
            //console.log("result[validUrl].result", result[validUrl].result);
            for (let key2 in result[validUrl].result) {
              if (
                result[validUrl].result[key2].subevent_type ==
                "licence_plate_recognized"
              )
                this.all_event_list.push(result[validUrl].result[key2]);
            }

            if (this.all_event_list.length == 0) {
              this.all_event_list = result[validUrl].result;
            }

            //this.$store.getters["alertWS/filterAlerts"](this.currentFilters);
            this.busy = false;

            //this.startSort();
          } else {
            this.event_list = [];
            this.busy = false;
          }

          $this.filterCameraForEvent();
        })
        .catch((err) => {
          console.log("err", err);
          this.event_list = [];
          this.busy = false;
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    startFilter: function (e) {
      //clearInterval(this.intervalId);
      let $this = this;
      this.search = true;

      this.all_event_list = [];
      this.event_list = [];

      let query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
        event_type: "lpr"
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (result[validUrl].result.length > 0) {
            console.log("data filter", result[validUrl].result);
            for (let key2 in result[validUrl].result) {
              if (
                result[validUrl].result[key2].subevent_type ==
                "licence_plate_recognized"
              )
                this.all_event_list.push(result[validUrl].result[key2]);
            }

            if (this.all_event_list.length == 0) {
              this.all_event_list = result[validUrl].result;
            }

            this.filterCameraForEvent();
            //this.$store.getters["alertWS/filterAlerts"](this.currentFilters);
            //let result_created = null;
            /* for (let key2 in result[validUrl].result) {
              result_created =
                Date.parse(result[validUrl].result[key2].created) / 1000;

              let eventlist = $this.event_list;
              let eventlist_created = null;
              $this.event_list = [];

              for (let key3 in eventlist) {
                eventlist_created = Date.parse(eventlist[key3].created) / 1000;
                if (eventlist_created == result_created) {
                  $this.event_list.push(eventlist[key3]);
                }
              }
            } */

            //this.showSearchPanel = false;
            //this.showClear = true;
            this.busy = false;

            //this.startSort();
          } else {
            //this.showSearchPanel = false;
            this.event_list = [];
            //this.showClear = true;
            this.busy = false;
          }
        })
        .catch((err) => {
          console.log("err", err);
          //this.showSearchPanel = false;
          this.event_list = [];
          //this.showClear = true;
          this.busy = false;
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
      /* var alert_list = this.$store.getters["alertWS/filterAlerts"](
          this.currentFilters
        );
        this.alerts = alert_list; */
      /* for (var key in this.event_list) {
        //
        //if(this.currentFilters.device_list__contains == this.event_list[key])
      } */
      /* this.event_list;
      this.showSearchPanel = false;
      this.startSort(); */
    },
    clearFilter: function () {
      this.search = false;
      this.showSearchPanel = false;
      this.getEvents();

      /* if (type == "reset") {
        $this.event_list = [];
        $this.event_list = $this.all_event_list;

        this.queryForm.dateRange.startDate = null;

        this.showSearchPanel = false;
        this.showClear = false;
      } else {
        this.queryForm.dateRange.startDate = null;
      } */

      //this.startSort();

      /* this.currentFilters = {};
        for (var i in this.selectedFilters) {
          this.selectedFilters[i] = null;
        }
  
        var alert_list = this.$store.getters["alertWS/filterAlerts"](
          this.currentFilters
        );
        this.alerts = alert_list;
        this.showSearchPanel = false;
        this.currentFiltersText = ""; */
      //this.startSort();
    },
    filterCameraForEvent: function () {
      var $this = this;
      var filtered_event_list = [];

      if ($this.filteredCameraEvent != null) {
        if ($this.filteredCameraEvent.length !== 0) {
          $this.filteredCameraEvent.forEach(function (camera) {
            var filtered = $this.all_event_list.filter(function (
              lpr_event,
              index,
              arr
            ) {
              return lpr_event.data.metadata.camera_ip == camera.ip;
            });
            if (filtered != []) {
              filtered_event_list.push.apply(filtered_event_list, filtered);
            }
          });
        } else {
          $this.filteredCameraEvent = null;
          $this.event_list = $this.all_event_list;
        }
      }

      if ($this.filteredCameraEvent == null) {
        $this.event_list = $this.all_event_list;
      } else if (
        $this.filteredCameraEvent != null &&
        filtered_event_list.length == 0
      ) {
        $this.event_list = [];
      } else {
        $this.event_list = filtered_event_list;
      }
    },

    searchDevices: function (e) {
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var RESULT_CATEGORY = "";
      var STORE_GETTER = "";
      var DEVICE_TYPE = "";

      $this.camera_list = [];
      // console.log("searchDevices", $this.searchType);
      if ($this.searchType == "camera") {
        RESULT_CATEGORY = "DEV_CAM";
        STORE_GETTER = "psim/getCamera";
        DEVICE_TYPE = "camera";
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((mdata) => {
          let category_result = null;
          let camera_list = [];
          for (var service_url in mdata["result"]) {
            try {
              category_result =
                mdata["result"][service_url]["result"][RESULT_CATEGORY];
            } catch (err) {
              //
            }

            if (category_result != null) {
              for (var i in category_result) {
                var device_url = i;
                var device_data = category_result[i];
                var device_ip = new URL(device_url).host;
                if (device_data.details != undefined) {
                  if (device_data.details.ip != undefined) {
                    device_ip = device_data.details.ip;
                  }
                }

                if ($this.$store.getters[STORE_GETTER](device_ip) == null) {
                  var device_result = {
                    id: device_url,
                    ip: device_ip,
                    type: DEVICE_TYPE,
                    status: device_data["status"],
                    data: {
                      server_url:
                        device_data["monitor_url"] == undefined
                          ? null
                          : device_data["monitor_url"],
                      details:
                        device_data["details"] == undefined
                          ? null
                          : device_data["details"],
                      meta:
                        device_data["meta"] == undefined
                          ? null
                          : device_data["meta"]
                    }
                  };
                  camera_list.push(device_result);
                }
              }
            }
          }

          camera_list.sort(function (a, b) {
            const numA = Number(
              a["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            const numB = Number(
              b["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            return numA - numB;
          });

          for (let key in camera_list) {
            $this.camera_list.push({
              ip: camera_list[key].ip,
              name: camera_list[key].ip
            });
          }
        })
        .catch((err) => {
          // console.log("Error: No ");
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    setFilters() {
      /* let camera_qs = this.$store.getters["psim/getCameras"]();
        for (let c in camera_qs) {
          this.camera_list.push({ ip: camera_qs[c].ip, name: camera_qs[c].name });
        } */
      this.searchDevices();

      let event_status_list = [
        { id: 1, name: "Access Granted" },
        { id: 2, name: "Access Denied" }
      ];

      this.availableFilters = [
        {
          label: "Start",
          key: this.queryForm.dateRange.startDate,
          type: "datetime"
        },
        {
          label: "End",
          key: this.queryForm.dateRange.endDate,
          type: "datetime"
        }
      ];

      this.selectedFilters = {
        created__gte: this.queryForm.dateRange.startDate,
        created__lte: this.queryForm.dateRange.endDate
        //device_list__contains: null
        //event_status_list__contains: null
      };

      /* this.availableFilters = [
        {
          label: "Start",
          key: "created__gte",
          type: "datetime"
        },
        {
          label: "End",
          key: "created__lte",
          type: "datetime"
        }
        {
          label: "Camera",
          key: "device_list__contains",
          type: "multiple",
          choices: this.camera_list,
          obj_key: "ip",
          obj_value: "name"
        }
        {
          label: "Event Status",
          key: "event_status_list__contains",
          type: "select",
          choices: event_status_list,
          obj_key: "id",
          obj_value: "name"
        }
      ]; */

      /* this.selectedFilters = {
        created__gte: null,
        created__lte: null
        //device_list__contains: null
        //event_status_list__contains: null
      }; */
    },
    updateFilters: function (filterKey, selectedItem, objectKey, objectValue) {
      if (selectedItem == null || selectedItem == "") {
        delete this.currentFilters[filterKey];
      } else {
        if (objectKey == null) {
          this.currentFilters[filterKey] = selectedItem;
        } else {
          /* if (filterKey == "device_list__contains") {
              for (var key in selectedItem) {
                this.currentFilters[filterKey] = selectedItem.length;
              }
            } else { */
          this.currentFilters[filterKey] = selectedItem[objectKey];
          //}
        }
      }
      // console.log("filterkey: ", filterKey);
      // console.log("selecteditem: ", selectedItem);

      var filter_count = Object.keys(this.currentFilters).length;
      if (filter_count > 1) {
        this.currentFiltersText = filter_count + " selected filters";
      } else if (filter_count == 1) {
        if (objectValue == null) {
          this.currentFiltersText = this.selectedFilters[filterKey];
        } else {
          /* if (filterKey == "device_list__contains") {
              for (var key2 in this.selectedFilters[filterKey]) {
                this.currentFiltersText =
                  this.selectedFilters[filterKey][key2][objectValue];
              }
            } else { */
          this.currentFiltersText =
            this.selectedFilters[filterKey][objectValue];
          //}
        }
      } else if (filter_count < 1) {
        this.currentFiltersText = "";
      }
    },
    startSort: function () {
      for (var s in this.currentSort) {
        var sort_item = this.currentSort[s];
        var sort_type = sort_item["type"];
        var sort_order = sort_item["order"];
        var sort_key = s;

        this.alerts.sort(function (a, b) {
          if (sort_type == "datetime") {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if (sort_order == "descending") {
              return new Date(b[sort_key]) - new Date(a[sort_key]);
            } else if (sort_order == "ascending") {
              return new Date(a[sort_key]) - new Date(b[sort_key]);
            }
          } else if (sort_type == "integer") {
            if (sort_order == "descending") {
              return parseInt(b[sort_key]) - parseInt(a[sort_key]);
            } else if (sort_order == "ascending") {
              return parseInt(a[sort_key]) - parseInt(b[sort_key]);
            }
          }
        });
      }

      this.showSort = false;
    },
    clearSort: function () {
      this.currentSort = {};
      for (var i in this.selectedSort) {
        this.selectedSort[i] = null;
      }

      this.alerts
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
        .sort(function (a, b) {
          return parseInt(b.priority) - parseInt(a.priority);
        });

      this.showSort = false;
      this.currentSortText = "";
    },
    fetchStaffDetail(event_id) {
      // call the api
      // .then
      // event_staff_dict[event_id] = staff result
    },
    renderStaffDetail(event_id, field_name) {
      // return event_staff_dict[event_id][field_name]
    },
    getFormatedDateTime(datetime) {
      if (datetime != null) {
        let old_datetime = new Date(datetime);
        return old_datetime.toLocaleString("en-GB");
      } else {
        return "-";
      }
    },
    getCameraName(camera_ip) {
      let $this = this;
      let device_search;

      device_search = $this.$store.getters["device/filterCameras"]({
        ip: camera_ip
      });

      if (device_search.length > 0) {
        $this.cameraObj = device_search[0];

        if ($this.cameraObj.name !== undefined) {
          return $this.cameraObj.name;
        } else {
          return camera_ip;
        }
      } else {
        return camera_ip;
      }
    },
    async getStaffDetails(license_plate) {
      let $this = this;
      let staffObj = null;
      let API_URL =
        $this.getAPIServerURL +
        "/api/acsservice/person/?car_plate_no=" +
        license_plate;
      const client = $this.$root.getAjaxFetchClient();
      await client
        .getRequest(API_URL)
        .then((data) => {
          if (data !== undefined) {
            for (let key in data) {
              if (data[key] != null && data[key] != undefined) {
                for (let value in data[key]) {
                  for (let staffDetail in data[key][value]) {
                    if (typeof data[key][value][staffDetail] === "object") {
                      staffObj = data[key][value][staffDetail];
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });

      return staffObj;
    },
    goToEventDetail(record, index) {
      let $this = this;
      $this.showEventDetails = true;
      $this.eventObj = record;
      // console.log("eventObj : " + $this.eventObj);

      this.handleDynamicPanelEventDetail();
    },

    showPhoto(title, src) {
      let $this = this;

      if (src == "profile_picture") {
        $this.selectedImage = "";
      } else {
        $this.selectedImage = src;
      }
      $this.selectedImageTitle = title;
      $this.showModal = true;
    },
    handleDynamicPanelEventDetail() {
      if (this.showEventDetails) {
        let windowHeight = window.innerHeight;
        let componentHeight =
          this.$refs.panelLprEventTableComponent.clientHeight;
        if ((componentHeight / windowHeight) * 100 >= 50) {
          // follow screen size
          if (windowHeight >= 800) {
            this.panel_event_detail_top = "-30%";
          } else {
            this.panel_event_detail_top = "-50%";
          }
        } else {
          // follow component size
          this.panel_event_detail_top = "-80%";
        }
      }
    },
    createObjectPreferences: function () {
      let $this = this;
      let camera_list = [];
      let selected_camera_list = {};
      let workspaces = {};
      let id = {};
      let custom = {};
      let panels = {};
      let num = {};

      if ($this.filteredCameraEvent != null) {
        for (let key in $this.filteredCameraEvent) {
          camera_list.push($this.filteredCameraEvent[key].ip);
        }
        selected_camera_list["selected_camera_list"] = camera_list;
        num[$this.panel_info.num] = selected_camera_list;
        panels[$this.panel_info.panel] = num;
        custom["custom"] = panels;
        id[$this.workspaceID] = custom;
        workspaces["workspaces"] = id;

        $this.preferences = {
          ...workspaces
        };
      }
    },
    onSubmitFormAddDevice: function () {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/users/" + $this.currentUser.id + "/";

      if ($this.currentUserWorkspaces != undefined) {
        if ($this.currentUserPanelNum != null) {
          //
          if ($this.filteredCameraEvent != null) {
            //
            $this.selectedCameraEvent = $this.filteredCameraEvent.map(function (
              element
            ) {
              return `${element.ip}`;
            });

            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].selected_camera_list =
              $this.selectedCameraEvent;
          } else {
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ][$this.currentUserPanelNum].selected_camera_list =
              $this.filteredCameraEvent;
          }

          $this.merged.workspaces = {
            ...$this.currentUserWorkspaces
          };

          $this.formUpdate.preferences = $this.merged;
        } else {
          //
          $this.createObjectPreferences();

          if ($this.currentUserWorkspaceID == null) {
            $this.merged.workspaces = {
              ...$this.currentUserWorkspaces,
              ...$this.preferences.workspaces
            };
          } else {
            if ($this.currentUserPanel == null) {
              $this.currentUserPanel = $this.panel_info.panel;
            }
            $this.currentUserWorkspaces[$this.currentUserWorkspaceID].custom[
              $this.currentUserPanel
            ] = {
              ...$this.currentUserWorkspaces[$this.currentUserWorkspaceID]
                .custom[$this.currentUserPanel],
              ...$this.preferences.workspaces[$this.workspaceID].custom[
                $this.panel_info.panel
              ]
            };

            $this.merged.workspaces = {
              ...$this.currentUserWorkspaces
            };
          }

          $this.formUpdate.preferences = $this.merged;
        }
      } else {
        $this.createObjectPreferences();
        $this.formUpdate.preferences = $this.preferences;
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          if (data == null) {
            this.filteredPreferencesCameraEvent = [];
            for (let key in this.currentUserWorkspaces[
              this.currentUserWorkspaceID
            ].custom[this.currentUserPanel][this.currentUserPanelNum]
              .selected_camera_list) {
              $this.filteredPreferencesCameraEvent.push({
                ip: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                  .custom[this.currentUserPanel][this.currentUserPanelNum]
                  .selected_camera_list[key],
                name: this.currentUserWorkspaces[this.currentUserWorkspaceID]
                  .custom[this.currentUserPanel][this.currentUserPanelNum]
                  .selected_camera_list[key]
              });
            }

            $this.filteredCameraEvent = $this.filteredPreferencesCameraEvent;

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated preferences successfully",
              message_type: "success"
            });

            $this.updateFormErrorMessage = null;
          } else {
            $this.updateFormErrorMessage = data.detail;
          }
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./PanelLprEventTable.scss";

/* .panel-event-detail {
    position: relative;
    top: -50%;
    height: 100%;
    //background-color: $black;
    background-color: let(--colour-surface3);
    padding: 0rem 0.25rem;
  
    &.event-detail-slide-enter-active {
      animation: event-detail-slide-from-bottom 0.5s ease-in-out forwards;
    }
  
    &.event-detail-slide-leave-active {
      animation: event-detail-slide-from-bottom 0.5s ease-in-out reverse;
    }
  } */
</style>
