var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workspace"},[_c('grid-layout',{attrs:{"layout":_vm.getSelectedWorkspace.layout.panels,"col-num":12,"row-height":_vm.rowHeight,"max-rows":_vm.maxRows,"is-draggable":false,"is-resizable":false,"vertical-compact":true,"use-css-transforms":true,"autoSize":true},on:{"update:layout":function($event){return _vm.$set(_vm.getSelectedWorkspace.layout, "panels", $event)}}},_vm._l((_vm.getSelectedWorkspace.layout.panels),function(item){return _c('grid-item',{key:'grid_' +
        _vm.getSelectedWorkspace.id +
        '_' +
        _vm.getSelectedWorkspace.index +
        '_' +
        item.num +
        '_' +
        item.i +
        '_' +
        item.panel,staticClass:"panel",class:[item.panel === 'floorplan' ? 'panel-full' : ''],attrs:{"static":true,"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i,"data-grid-width":item.w}},[(item.panel === 'floorplan')?_c('PanelFloorplan'):(item.panel === 'devices')?_c('PanelDevices'):(item.panel === 'alerts')?_c('PanelAlerts'):(item.panel === 'face_recognition')?_c('PanelFaceRecognition'):(item.panel === 'face_search')?_c('PanelFaceSearch'):(item.panel === 'abnormal_status')?_c('PanelAbnormalStatus'):(item.panel === 'maintenance')?_c('PanelMaintenance'):(item.panel === 'building_stats')?_c('PanelBuildingStats'):(
          item.panel === 'live_camera_view' // && item.camera_ip !== undefined
        )?_c('PanelLiveCameraView',{attrs:{"cameraIp":item.camera_ip,"videoFormat":item.video_format,"item":item,"workspaceId":_vm.getSelectedWorkspace.id}}):(item.panel === 'lpr_alerts')?_c('PanelLprEventCard'):(item.panel === 'lpr_staff_alerts')?_c('PanelLprStaffEventCard',{attrs:{"item":item,"workspaceId":_vm.getSelectedWorkspace.id}}):(item.panel === 'lpr_transactions')?_c('PanelLprEventTable',{attrs:{"item":item,"workspaceId":_vm.getSelectedWorkspace.id}}):(item.panel === 'lpr_search')?_c('PanelLprSearch'):(
          item.panel === 'vms_transaction'
          /* && getCurrentUser.panels.length > 0 &&
          getCurrentUser.pages.length > 0 */
        )?_c('VMSDashboard'):(item.panel === 'vms_host_invite')?_c('VMSHostDashboard'):_vm._e()],1)}),1),_c('LprAlertDetails')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }