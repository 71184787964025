<template>
  <div class="floorplan-wrapper" id="floorplan_wrapper">
    <!-- Counters -->
    <div
      class="floorplan-window"
      ref="draggableContainer"
      v-show="floorActiveFloorplan.image != null"
    >
      <div class="floorplan-container" @mousedown="dragMouseDown">
        <div
          class="floorplan-zone"
          v-for="zone in zonePolygons"
          :key="zone.id"
          :style="zone.divStyle"
        >
          <svg :height="zone.height" :width="zone.width">
            <polygon :points="zone.points" :style="zone.polygonStyle" />
          </svg>
          <div class="floorplan-zone-name" @click="goToZone(zone.id)">
            {{ zone.name }}
          </div>
        </div>

        <img
          :src="floorActiveFloorplan.image"
          ref="floorplanImage"
          id="floorplan_image"
          class="floorplan-image position-absolute"
          @load="onLoad"
        />

        <DeviceCamera
          v-for="device in device_cameras"
          :containerDimensions="dimensions"
          :key="device.id"
          :device="device"
          :class="[viewCameras ? '' : 'hide']"
        ></DeviceCamera>
        <DeviceDoor
          v-for="device in device_doors"
          :containerDimensions="dimensions"
          :key="device.id"
          :device="device"
          :class="[viewDoors ? '' : 'hide']"
        ></DeviceDoor>
        <DeviceReader
          v-for="device in device_readers"
          :containerDimensions="dimensions"
          :key="device.id"
          :device="device"
          :class="[viewReaders ? '' : 'hide']"
        ></DeviceReader>
        <DeviceInput
          v-for="device in device_inputs"
          :containerDimensions="dimensions"
          :key="device.id"
          :device="device"
          :class="[viewInputs ? '' : 'hide']"
        ></DeviceInput>
      </div>
    </div>

    <div
      class="floorplan-devices"
      ref="deviceTable"
      v-show="floorActiveFloorplan.image == null"
    >
      <b-table
        dark
        :items="devicesTableItems"
        :fields="devicesTableFields"
        :tbody-tr-class="devicesTableRowClass"
      >
        <template #top-row v-if="devicesTableItems.length === 0">
          <!-- Adding &nbsp; to the cell so that it maintains the standard cell height -->
          <td :colspan="devicesTableFields.length">No devices in this floor</td>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(status)="data">
          <b
            :class="[data.value == 'Online' ? 'text-success' : 'text-danger']"
            >{{ data.value }}</b
          >
        </template>

        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="secondary"
            @click="doShowDeviceDetails(data.item.id, data.item.type)"
            ><i class="fa fa-eye"></i
          ></b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import DeviceCamera from "@/components/FloorplanDevices/Device/DeviceCamera.vue";
import DeviceDoor from "@/components/FloorplanDevices/Device/DeviceDoor.vue";
import DeviceReader from "@/components/FloorplanDevices/Device/DeviceReader.vue";
import DeviceInput from "@/components/FloorplanDevices/Device/DeviceInput.vue";
import { fabric } from "fabric";

export default {
  name: "Floorplan",
  components: {
    DeviceCamera,
    DeviceDoor,
    DeviceReader,
    DeviceInput
  },
  props: {
    floorplan_image: {
      type: String,
      required: false
    },
    building: {
      type: Object,
      required: true
    },
    floor: {
      type: Object,
      required: true
    },
    zones: {
      type: Array,
      required: false
    }
  },
  data: function () {
    return {
      shownBuilding: { id: null, name: null },
      shownFloor: { id: null, name: null },
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      dimensions: {
        currentWidth: 0,
        currentHeight: 0,
        zoomLevel: 0
      },
      wrapper: {
        width: 0,
        height: 0
      },
      container: {
        width: 0,
        height: 0
      },
      zonePolygons: [],
      counters: {
        cameras: "0 cameras",
        doors: "0 doors",
        readers: "0 readers",
        inputs: "0 inputs"
      },
      viewCameras: true,
      viewDoors: true,
      viewReaders: true,
      viewInputs: true,
      isEditMode: false,
      devicesTableItems: [],
      devicesTableFields: [],
      device_cameras: [],
      device_controllers: [],
      device_doors: [],
      device_readers: [],
      device_inputs: [],
      isDevicesLoaded: false,
      isDevicesFetched: false,
      hasFloorplanImage: false,
      plottedFloorDevicesList: {},
      userMovedFloorplan: false,
      floorActiveFloorplan: {
        id: null,
        name: null,
        devices: {},
        image: null
      }
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation",
      _getViewingDetails: "psim/getViewingDetails",
      isDevicesInitiated: "psim/isDevicesInitiated",
      activeFloorplan: "psim/getActiveFloorplan",
      getCameras: "device/getCameras",
      getControllers: "device/getControllers"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        // console.log("Viewing Location:", JSON.parse(JSON.stringify(n)));
        if (n != null) {
          if (n.pos != null) {
            // console.log("going to pos", n.pos);
            this.goToCoordinates(n.pos, this.dimensions.zoomLevel);
          } else if (n.device_id != null && n.pos == null) {
            // console.log("going to device_id", n.device_id);
            this.goToDevice(n.device_id);
          } else if (n.zone != null && n.pos == null) {
            // console.log("going to zone");
            this.goToZone(n.zone.id);
          } else if (
            n.floor != null &&
            n.zone == null &&
            n.device_id == null &&
            n.pos == null
          ) {
            // console.log("going to floor", n.floor);
            this.goToFloor();
          }

          if (n.device_type != null) {
            this.highlightTableRow(n.device_ip, n.device_type);
          }
        }
      },
      deep: true
    },
    activeFloorplan: {
      handler: function (n, o) {
        // console.log("activeFloorplan", n)
        if (n == null) {
          this.device_cameras = [];
          this.device_doors = [];
          this.device_readers = [];
          this.device_inputs = [];
          this.hasFloorplanImage = false;
        } else {
          let floorNewActiveFloorplan = n[String(this.floor.id)];
          let floorOldActiveFloorplan = o[String(this.floor.id)];

          if (
            floorNewActiveFloorplan == undefined ||
            floorOldActiveFloorplan == undefined
          ) {
            this.device_cameras = [];
            this.device_doors = [];
            this.device_readers = [];
            this.device_inputs = [];
          } else {
            if (
              floorNewActiveFloorplan.id !== floorOldActiveFloorplan.id &&
              floorOldActiveFloorplan.id !== null
            ) {
              this.device_cameras = [];
              this.device_doors = [];
              this.device_readers = [];
              this.device_inputs = [];
            }
          }

          if (floorNewActiveFloorplan.id == null) {
            this.hasFloorplanImage = false;
            this.floorActiveFloorplan = {
              id: null,
              name: null,
              devices: {},
              image: null
            };
          } else {
            this.hasFloorplanImage = true;
            this.floorActiveFloorplan = floorNewActiveFloorplan;
          }
        }
      },
      deep: true
    },
    getCameras: {
      handler: function (n, o) {
        this.fetchDevicesStatus();
      },
      deep: true
    },
    getDoors: {
      handler: function (n, o) {
        this.fetchDevicesStatus();
      },
      deep: true
    },
    isDevicesInitiated: {
      handler: function (n, o) {
        this.isDevicesFetched = true;
        if (this.hasFloorplanImage == false) {
          this.onLoadNoImage();
        }
      }
    }
  },
  mounted() {
    if (this._getViewingLocation != null) {
      if (
        this.activeFloorplan == null ||
        this.activeFloorplan[String(this.floor.id)] == undefined ||
        this.activeFloorplan[String(this.floor.id)].id == null
      ) {
        this.hasFloorplanImage = false;
        this.floorActiveFloorplan = {
          id: null,
          name: null,
          devices: {},
          image: null
        };
      } else {
        this.hasFloorplanImage = true;
        this.floorActiveFloorplan = this.activeFloorplan[String(this.floor.id)];
      }
    }

    if (this.isDevicesInitiated == true) {
      if (this.hasFloorplanImage == false) {
        this.onLoadNoImage();
      }
    }
  },
  methods: {
    onLoad: function () {
      // console.log("Loaded Floor Plan Image ----------")
      // Called when floorplan img src finish loading
      var $this = this;
      let container_width = 0;
      let container_height = 0;
      if (this.$refs.floorplanImage != undefined) {
        this.$refs.floorplanImage.style.width = null;
        this.$refs.floorplanImage.style.height = null;
        container_width = this.$refs.floorplanImage.offsetWidth;
        container_height = this.$refs.floorplanImage.offsetHeight;
      }

      var wrapper_width =
        document.getElementById("floorplan_wrapper").offsetWidth;
      var wrapper_height =
        document.getElementById("floorplan_wrapper").offsetHeight;

      // wrapper is the main component div and also the "viewport".
      this.wrapper.width = wrapper_width;
      this.wrapper.height = wrapper_height;
      // container is the whole floorplan image, which can be bigger than the wrapper.
      this.container.width = container_width;
      this.container.height = container_height;
      // console.log("Wrapper width:", this.wrapper.width, " height:", this.wrapper.height)
      // console.log("Container width:", this.container.width, " height:", this.container.height)
      // console.log(this._getViewingLocation.pos.x + ", " + this._getViewingLocation.pos.y);
      if (
        this._getViewingLocation &&
        (this._getViewingLocation.pos == null ||
          this._getViewingLocation.pos.x == undefined ||
          this._getViewingLocation.pos.y == undefined)
      ) {
        this.resetFloorplan();
      } else {
        this.goToCoordinates(this._getViewingLocation.pos, 70);
      }

      this.updatePolygons();
      this.isDevicesLoaded = false;
      this.device_cameras = [];
      this.device_doors = [];
      this.device_readers = [];
      this.device_inputs = [];

      // console.log("this.floorActiveFloorplan.devices");
      // console.log(this.floorActiveFloorplan.devices);

      // NOTES: Load plotted devices
      for (var device in this.floorActiveFloorplan.devices) {
        // this.activeFloorplan.devices[device].current_coords = this.getFloorDeviceCurrentCoordinates(this.activeFloorplan.devices[device].coords);
        let device_obj = this.floorActiveFloorplan.devices[device];
        // console.log(device_obj)
        switch (device_obj.type) {
          case "door":
            this.device_doors.push(device_obj);
            break;
          case "reader":
            this.device_readers.push(device_obj);
            break;
          case "input":
            this.device_inputs.push(device_obj);
            break;
          default:
            /// camera
            this.device_cameras.push(device_obj);
        }
      }

      this.fetchDevicesStatus();

      // const el = this.$refs.floorplanImage;
      const el = document.getElementById("floorplan_wrapper");
      el.onwheel = $this.mouseWheelZoom;
    },
    onLoadNoImage: function () {
      // console.log("onLoadNoImage");
      var $this = this;
      this.container.width = 0;
      this.container.height = 0;
      this.zonePolygons = [];

      if ($this.isDevicesLoaded == false) {
        this.loadDevices();
        if ($this.hasFloorplanImage == false) {
          try {
            $this.loadDeviceTable();
          } catch (err) {
            //
          }
        }
      }
      try {
        $this.fetchDevicesStatus();
      } catch (err) {
        //
      }

      if (
        this._getViewingLocation != null &&
        this._getViewingLocation.device_type != null
      ) {
        this.highlightTableRow(
          this._getViewingLocation.device_ip,
          this._getViewingLocation.device_type
        );
      }
    },
    fetchDevicesStatus: function () {
      var $this = this;
      for (var c in this.device_cameras) {
        var camera_obj = $this.$store.getters["device/filterCameras"]({
          ip: this.device_cameras[c].ip
        });
        if (camera_obj.length > 0) {
          this.device_cameras[c].status = camera_obj[0].status;
        }
      }

      // TODO: Check with EVA
      for (var d in this.device_doors) {
        var door_obj = $this.$store.getters["device/filterDoors"]({
          ip: this.device_doors[d].ip
        });
        if (door_obj.length > 0) {
          this.device_doors[d].status = door_obj[0].status;
        }
      }

      for (var r in this.device_readers) {
        var reader_obj = $this.$store.getters["device/filterReaders"]({
          ip: this.device_readers[r].ip
        });
        if (reader_obj.length > 0) {
          this.device_readers[r].status = reader_obj[0].status;
        }
      }

      for (var i in this.device_inputs) {
        var input_obj = $this.$store.getters["device/filterInputs"]({
          ip: this.device_inputs[i].ip
        });
        if (input_obj.length > 0) {
          this.device_inputs[i].status = input_obj[0].status;
        }
      }

      if ($this.hasFloorplanImage == false) {
        console.log("No floorplan image, updating table");
        $this.updateTableItems();
      }
    },
    loadDevices: function () {
      let $this = this;

      console.log("Loading devices into floorplan...");
      var device_filter = {
        building__id: this.building.id,
        floor__id: this.floor.id
      };
      var camera_list = this.$store.getters["psim/getCameras"](device_filter);
      var controller_list =
        this.$store.getters["psim/getControllers"](device_filter);
      // console.log(camera_list)
      this.device_cameras = camera_list;
      this.device_controllers = controller_list;

      if (
        this._getViewingLocation != null &&
        this._getViewingLocation.zone != null
      ) {
        this.updateCounters(this._getViewingLocation.zone.id);
      } else {
        this.updateCounters(null);
      }
    },
    loadDeviceTable: function () {
      console.log("Loading devices into table...");
      this.devicesTableFields = [
        {
          key: "index",
          label: "#"
        },
        {
          key: "ip",
          label: "IP Address",
          sortable: true
        },
        {
          key: "name",
          label: "Device Name",
          sortable: true
        },
        {
          key: "type",
          label: "Device Type",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        "actions"
      ];
      this.devicesTableItems = [];
      var cameras = this.device_cameras;
      var doors = this.device_doors;
      var readers = this.device_readers;
      var inputs = this.device_inputs;
      cameras.sort(function (a, b) {
        return a.ip - b.ip;
      });
      doors.sort(function (a, b) {
        return a.ip - b.ip;
      });
      readers.sort(function (a, b) {
        return a.ip - b.ip;
      });
      inputs.sort(function (a, b) {
        return a.ip - b.ip;
      });
      for (var c in cameras) {
        var c_status = cameras[c].status == 1 ? "Online" : "Offline";
        var c_data = {
          id: cameras[c].id,
          ip: cameras[c].ip,
          name: cameras[c].name,
          type: cameras[c].type,
          status: c_status,
          highlight: false
        };
        this.devicesTableItems.push(c_data);
      }
      for (var d in doors) {
        var d_status = doors[d].status == 1 ? "Online" : "Offline";
        var d_data = {
          id: doors[d].id,
          ip: doors[d].ip,
          name: doors[d].name,
          type: doors[d].type,
          status: d_status,
          highlight: false
        };
        this.devicesTableItems.push(d_data);
      }
      for (var r in readers) {
        var r_status = readers[r].status == 1 ? "Online" : "Offline";
        var r_data = {
          id: readers[d].id,
          ip: readers[d].ip,
          name: readers[d].name,
          type: readers[d].type,
          status: r_status,
          highlight: false
        };
        this.devicesTableItems.push(d_data);
      }
      for (var i in inputs) {
        var i_status = inputs[d].status == 1 ? "Online" : "Offline";
        var i_data = {
          id: inputs[i].id,
          ip: inputs[i].ip,
          name: inputs[i].name,
          type: inputs[i].type,
          status: i_status,
          highlight: false
        };
        this.devicesTableItems.push(i_data);
      }

      // console.log(this.$refs.deviceTable);
      if (this.$refs.deviceTable != undefined) {
        this.$refs.deviceTable.style.removeProperty("top");
        this.$refs.deviceTable.style.removeProperty("left");
      }
      this.isDevicesLoaded = true;
    },
    updateTableItems: function () {
      console.log("Updating table status");
      var $this = this;

      for (var i in $this.devicesTableItems) {
        let device = null;
        if ($this.devicesTableItems[i].type == "camera") {
          device = $this.device_cameras.filter(function (item) {
            if (item.id == $this.devicesTableItems[i].id) {
              return true;
            } else {
              return false;
            }
          });
        } else if ($this.devicesTableItems[i].type == "door") {
          device = $this.device_doors.filter(function (item) {
            if (item.id == $this.devicesTableItems[i].id) {
              return true;
            } else {
              return false;
            }
          });
        }

        if (device != null && device.length > 0) {
          var device_status = device[0].status == 1 ? "Online" : "Offline";
          $this.devicesTableItems[i].status = device_status;
        }
      }
    },
    doShowDeviceDetails: function (device_ip, device_type) {
      var currently_viewed = this._getViewingDetails;
      // var location = this._getViewingLocation;
      var device = null;
      if (device_type == "camera") {
        var cameras = this.device_cameras;
        for (var c in cameras) {
          if (cameras[c].id == device_ip) {
            device = cameras[c];
            break;
          }
        }
      } else if (device_type == "door") {
        var doors = this.device_doors;
        for (var d in doors) {
          if (doors[d].id == device_ip) {
            device = doors[d];
            break;
          }
        }
      } else if (device_type == "reader") {
        var readers = this.device_readers;
        for (var r in readers) {
          if (readers[r].id == device_ip) {
            device = readers[r];
            break;
          }
        }
      } else if (device_type == "input") {
        var inputs = this.device_inputs;
        for (var i in inputs) {
          if (inputs[d].id == device_ip) {
            device = inputs[d];
            break;
          }
        }
      }

      if (device != null) {
        var url_query_dict = {};
        var url_name = "PSIM";
        if (device.building != null) {
          url_query_dict["building"] = device.building.id;

          if (device.floor != null) {
            url_query_dict["floor"] = device.floor.id;

            if (device.zone != null) {
              url_query_dict["zone"] = device.zone.id;
            }
          }
        }

        if (
          currently_viewed != null &&
          currently_viewed.type == "camera" &&
          currently_viewed.data.ip == device.ip
        ) {
          // If same device, close details panel
          this.$store.dispatch("psim/setViewingDetails", null);

          // this.$store.dispatch('psim/setViewingLocation', location);
          this.$router
            .push({ name: url_name, query: url_query_dict })
            .catch(() => {});

          this.$store.dispatch("psim/setLocatingAlert", null);
        } else {
          this.$store.dispatch("psim/setViewingDetails", {
            type: "camera",
            data: device
          });

          url_query_dict["device_id"] = encodeURI(device.id);
          this.$router
            .push({ name: url_name, query: url_query_dict })
            .catch(() => {});

          this.$store.dispatch("psim/setLocatingAlert", null);
        }
      }
    },
    highlightTableRow: function (device_ip, device_type) {
      // console.log(this.devicesTableItems);
      for (var i in this.devicesTableItems) {
        if (
          this.devicesTableItems[i]["type"] == device_type &&
          this.devicesTableItems[i]["id"] == device_ip
        ) {
          this.devicesTableItems[i]["highlight"] = true;
        } else {
          this.devicesTableItems[i]["highlight"] = false;
        }
      }
    },
    devicesTableRowClass: function (item, type) {
      if (!item || type !== "row") return;
      if (item.highlight == true) return "table-warning";
    },
    updateCounters: function (zone_id) {
      var cameras = this.device_cameras;
      var doors = this.device_doors;
      var readers = this.device_readers;
      var inputs = this.device_inputs;
      if (zone_id) {
        cameras = cameras.filter(function (item) {
          if (item.zone.id == zone_id) {
            return true;
          } else {
            return false;
          }
        });
        doors = doors.filter(function (item) {
          if (item.zone.id == zone_id) {
            return true;
          } else {
            return false;
          }
        });
        readers = readers.filter(function (item) {
          if (item.zone.id == zone_id) {
            return true;
          } else {
            return false;
          }
        });
        inputs = inputs.filter(function (item) {
          if (item.zone.id == zone_id) {
            return true;
          } else {
            return false;
          }
        });
      }
      var counter_camera_text = cameras.length;
      if (cameras.length > 1 || cameras.length == 0) {
        counter_camera_text += " cameras";
      } else {
        counter_camera_text += " camera";
      }
      var counter_door_text = doors.length;
      if (doors.length > 1 || doors.length == 0) {
        counter_door_text += " doors";
      } else {
        counter_door_text += " door";
      }
      var counter_reader_text = readers.length;
      if (readers.length > 1 || readers.length == 0) {
        counter_reader_text += " readers";
      } else {
        counter_reader_text += " reader";
      }
      var counter_input_text = inputs.length;
      if (inputs.length > 1 || inputs.length == 0) {
        counter_input_text += " inputs";
      } else {
        counter_input_text += " input";
      }
      this.counters = {
        cameras: counter_camera_text,
        doors: counter_door_text,
        readers: counter_reader_text,
        inputs: counter_input_text
      };
    },
    updatePolygons: function () {
      // Setup zonePolygons
      // console.log("Updating zone polygons");
      this.zonePolygons = [];
      for (var i in this.zones) {
        var zone = this.zones[i];
        // console.log(zone.box);
        var min_x = null;
        var max_x = 0;
        for (var x in zone.box) {
          if (zone.box[x][0] > max_x) {
            max_x = zone.box[x][0];
          }
          if (min_x == null) {
            min_x = zone.box[x][0];
          } else {
            if (zone.box[x][0] < min_x) {
              min_x = zone.box[x][0];
            }
          }
        }
        var actual_min_x = this.dimensions.currentWidth * (min_x / 100);
        var actual_max_x = this.dimensions.currentWidth * (max_x / 100);
        var zone_w = actual_max_x - actual_min_x;

        var min_y = null;
        var max_y = 0;
        for (var y in zone.box) {
          if (zone.box[y][1] > max_y) {
            max_y = zone.box[y][1];
          }
          if (min_y == null) {
            min_y = zone.box[y][1];
          } else {
            if (zone.box[y][1] < min_y) {
              min_y = zone.box[y][1];
            }
          }
        }
        var actual_min_y = this.dimensions.currentHeight * (min_y / 100);
        var actual_max_y = this.dimensions.currentHeight * (max_y / 100);
        var zone_h = actual_max_y - actual_min_y;

        var points_list = [];
        for (var p in zone.box) {
          var coor = zone.box[p];
          var coor_x =
            this.dimensions.currentWidth * (coor[0] / 100) - actual_min_x;
          var coor_y =
            this.dimensions.currentHeight * (coor[1] / 100) - actual_min_y;
          var coor_str = coor_x + "," + coor_y;
          points_list.push(coor_str);
        }
        var points_str = points_list.join(" ");

        var fill_colour = zone.colour;
        var style_str =
          "fill:" +
          fill_colour +
          "; fill-opacity:0.05; stroke:" +
          fill_colour +
          "; stroke-width:1";
        var div_style_str =
          "left: " + actual_min_x + "px; top: " + actual_min_y + "px;";
        var zone_polygon = {
          id: zone.id,
          name: zone.name,
          width: zone_w,
          height: zone_h,
          points: points_str,
          polygonStyle: style_str,
          divStyle: div_style_str
        };
        this.zonePolygons.push(zone_polygon);
      }
    },
    resetFloorplan: function () {
      // set zoom level
      var widthRatio = parseInt(
        (this.wrapper.width / this.container.width) * 100
      );
      this.dimensions.zoomLevel = widthRatio;
      this.onZoom(this.dimensions.zoomLevel);
      this.$emit("change-zoom-level", this.dimensions.zoomLevel);

      // set position
      if (this.$refs.draggableContainer != undefined) {
        var element_width = this.$refs.floorplanImage.offsetWidth;
        var element_height = this.$refs.floorplanImage.offsetHeight;

        this.$refs.draggableContainer.style.left =
          this.wrapper.width / 2 - element_width / 2 + "px";
        this.$refs.draggableContainer.style.top =
          this.wrapper.height / 2 - element_height / 2 + "px";
      }
      // console.log(this.$refs.deviceTable)
      if (this.$refs.deviceTable != undefined) {
        this.$refs.deviceTable.style.removeProperty("top");
        this.$refs.deviceTable.style.removeProperty("left");
      }

      this.isDevicesLoaded = false;
      // if (this.isDevicesInitiated == true) {
      //     this.loadDevices();
      // }
    },
    goToCoordinates: function (pos, zoomLevel) {
      // if (this.isDevicesInitiated == true) {
      //     this.loadDevices();
      // }

      if (pos.x != undefined && pos.y != undefined) {
        this.dimensions.zoomLevel = zoomLevel;
        this.onZoom(this.dimensions.zoomLevel);
        this.$emit("change-zoom-level", this.dimensions.zoomLevel);

        // set position
        if (this.$refs.draggableContainer != undefined) {
          var element_width = this.$refs.floorplanImage.offsetWidth;
          var element_height = this.$refs.floorplanImage.offsetHeight;
          var pos_x = (pos.x / 100) * element_width;
          var pos_y = (pos.y / 100) * element_height;
          // console.log("Floorplan.vue - pos.x: " + pos.x + " pos_x: " + pos_x + " element_width: " + element_width);

          this.$refs.draggableContainer.style.left =
            this.wrapper.width / 2 - pos_x + "px";
          this.$refs.draggableContainer.style.top =
            this.wrapper.height / 2 - pos_y + "px";
        }
        if (this.$refs.deviceTable != undefined) {
          this.$refs.deviceTable.style.removeProperty("top");
          this.$refs.deviceTable.style.removeProperty("left");
        }
      }
    },
    goToDevice: function (device_id) {
      // console.log("Zooming to device");
      var $this = this;

      let view_data = $this._getViewingDetails;

      let show_details_panel = false;
      const device_types = ["camera", "door", "reader", "input"];
      if (view_data == null || device_types.indexOf(view_data.type) >= 0) {
        show_details_panel = true;
      }

      var camera_results = this.device_cameras.filter(function (item) {
        if (item.id == device_id) {
          return true;
        }
        return false;
      });

      var door_results = this.device_doors.filter(function (item) {
        if (item.id == device_id) {
          return true;
        }
        return false;
      });

      var reader_results = this.device_readers.filter(function (item) {
        if (item.id == device_id) {
          return true;
        }
        return false;
      });

      var input_results = this.device_inputs.filter(function (item) {
        if (item.id == device_id) {
          return true;
        }
        return false;
      });

      // TODO: Check with EVA
      let device_obj = null;
      let device_type = null;
      if (camera_results.length > 0) {
        device_obj = camera_results[0];
        const device_search = this.$store.getters["device/filterCameras"]({
          id: device_obj.id
        });
        if (device_search.length > 0) {
          device_obj = device_search[0];
        }
        device_type = "camera";
      } else if (door_results.length > 0) {
        device_obj = door_results[0];
        const device_search = this.$store.getters["device/filterDoors"]({
          id: device_obj.id
        });
        if (device_search.length > 0) {
          device_obj = device_search[0];
        }
        device_type = "door";
      } else if (reader_results.length > 0) {
        device_obj = reader_results[0];
        const device_search = this.$store.getters["device/filterReaders"]({
          id: device_obj.id
        });
        if (device_search.length > 0) {
          device_obj = device_search[0];
        }
        device_type = "reader";
      } else if (input_results.length > 0) {
        device_obj = input_results[0];
        const device_search = this.$store.getters["device/filterInputs"]({
          id: device_obj.id
        });
        if (device_search.length > 0) {
          device_obj = device_search[0];
        }
        device_type = "input";
      }

      if (device_obj != null) {
        let pos = null;
        if (
          device_obj.coords != undefined &&
          device_obj.coords.posX != null &&
          device_obj.coords.posY != null
        ) {
          pos = { x: device_obj.coords.posX, y: device_obj.coords.posY };
        }

        if (show_details_panel && device_type != null) {
          $this.$store.dispatch("psim/setViewingDetails", {
            type: device_type,
            data: device_obj
          });
        }
        // console.log(pos)
        if (pos != null) {
          this.goToCoordinates(pos, this.dimensions.zoomLevel);
        }
      }
    },
    goToZone: function (zone_id) {
      var zone_dict = null;
      var zone_mid_pos = { x: 0, y: 0 };
      var zone_width = 0;
      var zone_actual_width = 0;
      for (var i in this.zones) {
        var zone = this.zones[i];
        if (zone.id == zone_id) {
          zone_dict = { id: zone.id, name: zone.name };
          zone_mid_pos.x =
            zone.box[0][0] + (zone.box[1][0] - zone.box[0][0]) / 2;
          zone_mid_pos.y =
            zone.box[0][1] + (zone.box[3][1] - zone.box[0][1]) / 2;
          zone_width = zone.box[1][0] - zone.box[0][0];

          var actual_min_x =
            this.dimensions.currentWidth * (zone.box[0][0] / 100);
          var actual_max_x =
            this.dimensions.currentWidth * (zone.box[1][0] / 100);
          zone_actual_width = actual_max_x - actual_min_x;
          break;
        }
      }

      var widthRatio = parseInt(
        (this.dimensions.currentWidth / zone_actual_width) * 100
      );
      if (widthRatio > 100) {
        widthRatio -= 50;
      }
      this.dimensions.zoomLevel = widthRatio;
      this.onZoom(this.dimensions.zoomLevel);
      this.$emit("change-zoom-level", this.dimensions.zoomLevel);

      var url_name = "PSIM";
      var url_query_dict = {};
      if (this.building != null) {
        url_query_dict["building"] = this.building.id;

        if (this.floor != null) {
          url_query_dict["floor"] = this.floor.id;

          if (zone_dict != null) {
            url_query_dict["zone"] = zone_dict.id;
            url_query_dict["pos_x"] = zone_mid_pos.x;
            url_query_dict["pos_y"] = zone_mid_pos.y;
          }
        }
      }

      this.$router
        .push({ name: url_name, query: url_query_dict })
        .catch(() => {});

      this.updateCounters(zone_dict.id);
    },
    goToFloor: function () {
      this.updateCounters(null);
      // this.resetFloorplan();
    },
    onZoom: function (zoom_level) {
      // console.log("onZoom zoom level:", zoom_level);
      var newWidth = this.container.width * (zoom_level / 100);
      var newHeight = this.container.height * (zoom_level / 100);

      var draggableWindowLeft =
        this.$refs.draggableContainer.style.left != ""
          ? parseFloat(
              this.$refs.draggableContainer.style.left.replace("px", "")
            )
          : 0;
      var draggableWindowTop =
        this.$refs.draggableContainer.style.top != ""
          ? parseFloat(
              this.$refs.draggableContainer.style.top.replace("px", "")
            )
          : 0;
      var widthDiff = newWidth - this.dimensions.currentWidth;
      var heightDiff = newHeight - this.dimensions.currentHeight;
      this.$refs.draggableContainer.style.left =
        draggableWindowLeft - widthDiff / 2 + "px";
      this.$refs.draggableContainer.style.top =
        draggableWindowTop - heightDiff / 2 + "px";

      this.dimensions.currentWidth = newWidth;
      this.dimensions.currentHeight = newHeight;

      // console.log("Dimensions width:", this.dimensions.currentWidth, " height:", this.dimensions.currentHeight)

      if (this.$refs.floorplanImage != undefined) {
        // console.log("Has Floor Plan Image ###########")
        this.$refs.floorplanImage.style.width = newWidth + "px";
        this.$refs.floorplanImage.style.height = newHeight + "px";
      }
      // console.log("Zoom Level " + zoom_level);

      this.updatePolygons();
    },
    dragMouseDown: function (event) {
      if (this.isEditMode) {
        return;
      }
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      if (this.isEditMode) {
        return;
      }
      event.preventDefault();
      this.$refs.draggableContainer.style.cursor = "move";

      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      if (this.isEditMode) {
        return;
      }
      document.onmouseup = null;
      document.onmousemove = null;
      this.$refs.draggableContainer.style.cursor = "default";

      // console.log(this.positions.movementX + ", " + this.positions.movementY + " | " + this.positions.clientX + ", " + this.positions.clientY);

      var w_max = this.wrapper.width;
      var h_max = this.wrapper.height;
      var element_width = this.dimensions.currentWidth;
      var element_height = this.dimensions.currentHeight;

      var current_left = this.$refs.draggableContainer.offsetLeft;
      var current_top = this.$refs.draggableContainer.offsetTop;

      var offset = 30;
      var edge_buffer = 30;

      // console.log(current_left + ", " + current_top + " | " + element_width + ", " + element_height);

      if (current_left > w_max - edge_buffer) {
        this.$refs.draggableContainer.style.left = w_max - offset + "px";
        this.positions.clientX = this.positions.clientX - offset;
      }

      if (current_left + element_width <= 0 + edge_buffer) {
        this.$refs.draggableContainer.style.left =
          0 - element_width + offset + "px";
        this.positions.clientX = this.positions.clientX + offset;
      }

      if (current_top > h_max - edge_buffer) {
        this.$refs.draggableContainer.style.top = h_max - offset + "px";
        this.positions.clientY = this.positions.clientY - offset;
      }

      if (current_top + element_height <= 0 + edge_buffer + 60) {
        this.$refs.draggableContainer.style.top =
          0 - element_height + offset + 60 + "px";
        this.positions.clientY = this.positions.clientY + offset + 60;
      }
    },
    dragZoom: function (value) {
      // console.log(value);
      this.dimensions.zoomLevel = value;
      this.onZoom(this.dimensions.zoomLevel);
      this.goToCoordinates({ x: 50, y: 50 }, this.dimensions.zoomLevel);
      this.$emit("change-zoom-level", this.dimensions.zoomLevel);
      // console.log("dragging Zoom")
    },
    mouseWheelZoom: function (event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        // zoom in
        this.dimensions.zoomLevel += 1;
        this.zoomIn();
      } else if (event.deltaY < 0) {
        // zoom out
        this.dimensions.zoomLevel -= 1;
        this.zoomOut();
      }
    },
    zoomIn() {
      // todo
      // console.log("zooming in");
      if (this.dimensions.zoomLevel < 100) {
        this.dimensions.zoomLevel += 1;
      } else {
        this.dimensions.zoomLevel = 100;
      }
      this.onZoom(this.dimensions.zoomLevel);
      this.$emit("change-zoom-level", this.dimensions.zoomLevel);
    },
    zoomOut() {
      // todo
      // console.log("zooming out");
      if (this.dimensions.zoomLevel > 1) {
        this.dimensions.zoomLevel -= 1;
      } else {
        this.dimensions.zoomLevel = 1;
      }
      this.onZoom(this.dimensions.zoomLevel);
      this.$emit("change-zoom-level", this.dimensions.zoomLevel);
    },
    toggleCameras: function (value) {
      this.viewCameras = value;
    },
    toggleDoors: function (value) {
      this.viewDoors = value;
    }
  }
};
</script>

<style lang="scss">
@import "./Floorplan.scss";
</style>
