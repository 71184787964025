<template>
  <div>
    <div class="building-window" ref="buildingWindow">
      <div class="building-container" ref="buildingContainer">
        <div v-for="floor in floorList" :key="floor.id" class="building-floor">
          <div
            class="building-floor-rect"
            :data-ok="[floor.isOK ? 'true' : 'false']"
            @click="goToFloor(floor.id)"
          >
            {{ floor.name }}
          </div>
          <div
            class="building-floor-notifications"
            v-show="!floor.isOK"
            v-b-tooltip.hover.right
            :title="floor.alerts + ' Alerts, ' + floor.cases + ' Cases'"
          >
            <div class="building-floor-alerts">
              <i class="fa fa-exclamation-circle mr-1"></i>{{ floor.alerts }}
            </div>
            <div class="building-floor-cases">
              <i class="fa fa-clipboard mr-1"></i>{{ floor.cases }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="building-minimap" ref="minimapNavigator">
      <div
        class="building-minimap-scroller"
        ref="draggableScroller"
        @mousedown="dragMouseDown"
      ></div>
      <div class="building-minimap-container" ref="minimapBuilding">
        <div
          v-for="floor in floorList"
          :key="floor.id"
          class="building-minimap-floor"
        >
          <div
            class="building-minimap-floor-rect"
            :data-ok="[floor.isOK ? 'true' : 'false']"
          ></div>
        </div>
      </div>
    </div>

    <!-- Legend -->
    <div class="building-legend">
      <div class="mr-4">
        <div class="building-legend-box mr-1" data-colour="green"></div>
        OK
      </div>
      <div>
        <div class="building-legend-box mr-1" data-colour="red"></div>
        Has Alerts or Cases
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Building",
  props: {
    building: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      scrollerPositions: {
        clientY: undefined,
        movementY: 0,
        defaultY: undefined,
        height: 0
      },
      buildingPositions: {
        defaultY: undefined
      },
      floorList: []
    };
  },
  computed: {
    ...mapGetters({
      _getViewingLocation: "psim/getViewingLocation",
      getAlertIDs: "alertWS/getAlertIDs",
      getFloorAlerts: "psim/getFloorAlerts"
    })
  },
  watch: {
    _getViewingLocation: {
      handler: function (n, o) {
        // console.log(n);
        if (n != null) {
          this.getFloors();
        }
      },
      deep: true
    },
    getFloorAlerts: {
      handler: function (n, o) {
        // console.log("Floor Alerts")
        this.getAlerts();
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.building.id != undefined) {
      console.log(
        "Building mounted - " +
          this.building.name +
          " with " +
          this.building.floors.length +
          " floors"
      );
      this.getFloors();
    }
  },
  methods: {
    getFloors: function () {
      this.floorList = [];

      for (var f in this.building.floors) {
        var index = f;
        var floor = this.building.floors[f];
        this.floorList.push(floor);
        this.checkIsOK(index, floor);
      }
      this.floorList.sort(function (a, b) {
        return a.index - b.index;
      });
      // console.log(this.floorList);

      this.$nextTick(() => {
        // buildingPositions
        var building_height = this.$refs.buildingContainer.offsetHeight;
        var building_window_height = this.$refs.buildingWindow.offsetHeight;
        // console.log("building_height: " + building_height + " vs building_window_height: " + building_window_height);
        this.buildingPositions.defaultY =
          building_window_height - building_height - 2;
        this.$refs.buildingContainer.style.top =
          this.buildingPositions.defaultY + "px";

        var visible_floor_count = building_window_height / 53;
        this.$refs.draggableScroller.style.height =
          visible_floor_count * 10 + "px";

        // scrollerPositions
        var minimap_building_height = this.$refs.minimapBuilding.offsetHeight;
        var scroller_height = this.$refs.draggableScroller.offsetHeight;
        var height_multiplier = Math.ceil(
          minimap_building_height / scroller_height
        );
        var minimap_height = height_multiplier * (scroller_height + 2);
        var default_scroller_pos_y = this.$refs.draggableScroller.offsetTop;

        this.$refs.minimapNavigator.style.height = minimap_height + "px";
        this.scrollerPositions.height = minimap_height;
        this.scrollerPositions.defaultY = minimap_height - scroller_height;
      });
    },
    getAlerts: function () {
      var alerts_count = 0;
      for (var f in this.floorList) {
        var index = f;
        var floor = this.floorList[f];
        this.checkIsOK(index, floor);
      }
      // console.log(this.floorList);
    },
    checkIsOK: function (index, floor) {
      var alerts_count = this.checkAlerts(floor);
      var cases_count = this.checkCases(floor);
      // console.log(floor, alerts_count, cases_count)

      floor["alerts"] = alerts_count;
      floor["cases"] = cases_count;

      if (alerts_count <= 0 && cases_count <= 0) {
        floor["isOK"] = true;
      } else {
        floor["isOK"] = false;
      }
      // console.log(this.floorList[index])
      this.$set(this.floorList, index, floor);
    },
    checkAlerts: function (floor) {
      var floor_alerts = this.$store.getters["psim/getFloorAlerts"][floor.id];
      var alerts_count = 0;
      if (floor_alerts != undefined) {
        alerts_count = floor_alerts.length;
      }
      return alerts_count;
    },
    checkCases: function (floor) {
      var floor_cases = this.$store.getters["psim/getFloorCases"][floor.id];
      var cases_count = 0;
      if (floor_cases != undefined) {
        cases_count = floor_cases.length;
      }
      return cases_count;
    },
    goToFloor: function (floor_id) {
      this.$router
        .push({
          name: "PSIM",
          query: { building: this.building.id, floor: floor_id }
        })
        .catch(() => {});
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.scrollerPositions.clientX = event.clientX;
      this.scrollerPositions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.$refs.draggableScroller.style.cursor = "move";

      this.scrollerPositions.movementY =
        this.scrollerPositions.clientY - event.clientY;
      this.scrollerPositions.clientY = event.clientY;

      // set the element's new position:
      var can_move_building = true;
      var new_pos =
        this.$refs.draggableScroller.offsetTop -
        this.scrollerPositions.movementY;
      // console.log(new_pos);
      if (new_pos < 0) {
        // max top
        new_pos = 0;
        can_move_building = false;
      } else if (new_pos > this.scrollerPositions.defaultY) {
        // max bottom
        new_pos = this.scrollerPositions.defaultY;
        can_move_building = false;
        this.$refs.buildingContainer.style.top =
          this.buildingPositions.defaultY + "px";
      }
      this.$refs.draggableScroller.style.top = new_pos + "px";

      if (can_move_building) {
        // console.log("building offsetTop:" + this.$refs.buildingContainer.offsetTop);
        // console.log("scrollerPositions movementY:" + this.scrollerPositions.movementY);
        var offsetY = 5.5;
        var building_pos =
          this.$refs.buildingContainer.offsetTop +
          this.scrollerPositions.movementY * offsetY;
        // console.log("building_pos: " + building_pos);
        this.$refs.buildingContainer.style.top = building_pos + "px";
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
};
</script>

<style lang="scss">
@import "./Building.scss";
</style>
