<template>
  <transition name="panel-slide" mode="out-in">
    <div v-if="showPanel" class="panel-device-details">
      <div class="panel-row h-100 mb-2" id="panel_device_details">
        <div class="panel w-100">
          <div class="panel-content">
            <div
              class="panel-content-header d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <h2 class="mb-0 mr-2">{{ device_display }}</h2>
                <DeviceStatus :status="device.status"></DeviceStatus>
                <b-badge
                  v-if="deviceIsRecording == true"
                  class="ml-2"
                  variant="danger"
                  >Recording</b-badge
                >
              </div>
              <b-button size="sm" @click="doHidePanel"
                ><i class="fa fa-times"></i
              ></b-button>
            </div>

            <div class="panel-content-body scroller">
              <!-- Camera -->
              <div class="d-flex" v-if="device_type == 'camera'">
                <div class="w-33">
                  <div class="d-flex flex-wrap">
                    <div class="status-card w-50">
                      <div class="status-card-content flex-column">
                        <span class="text-counter"
                          ><i class="fa fa-exclamation-circle"></i
                        ></span>
                        <div class="text-center">
                          <div class="alert-counter">{{ alert_count }}</div>
                          <div class="alert-subtitle">Alerts</div>
                        </div>
                      </div>
                    </div>
                    <div class="status-card no-padding w-50">
                      <div class="status-card-content flex-column mr-1">
                        <span class="text-counter"
                          ><i class="fa fa-clipboard"></i
                        ></span>
                        <div class="text-center">
                          <div class="case-counter">{{ case_count }}</div>
                          <div class="case-subtitle">Cases</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="info-card mt-5">
                    <h2>Information</h2>
                    <div><strong>IP:</strong> {{ device.ip }}</div>
                    <div v-if="device.data != undefined">
                      <strong>Model:</strong> {{ device.data.details.model }}
                    </div>
                    <div v-if="device.data != undefined">
                      <div v-if="device.data.details.vendor != undefined">
                        <strong>Vendor:</strong>
                        {{ device.data.details.vendor }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-66">
                  <!-- Video -->
                  <div
                    class="video-player-container text-center"
                    v-show="liveVideoURL != null"
                  >
                    <div
                      id="live_video_container"
                      ref="liveVideoContainer"
                    ></div>
                  </div>
                  <div
                    class="video-player-container d-flex align-items-center justify-content-center"
                    v-if="liveVideoURL == null"
                  >
                    {{ liveVideoMessage }}
                  </div>

                  <div class="video-format-options-selector">
                    <b-form-select
                      v-model="videoFormat"
                      :options="videoFormatOptions"
                      @change="loadPlayer"
                      size="sm"
                      class="mt-2"
                    ></b-form-select>
                  </div>
                </div>
              </div>

              <!-- Controller -->
              <div v-else-if="device_type == 'controller'">
                <!-- Controller data -->
                <div class="d-flex">
                  <div class="w-33 mr-2">
                    <div class="info-card mb-2">
                      <h2>Information</h2>
                      <div><strong>IP:</strong> {{ device.ip }}</div>
                      <div
                        :class="[
                          device.data.details.controller_model != undefined
                            ? ''
                            : 'hide'
                        ]"
                        v-if="device.data != undefined"
                      >
                        <strong>Model:</strong>
                        {{ device.data.details.controller_model }}
                      </div>
                      <div v-if="device.data != undefined">
                        <strong>Controller Name:</strong>
                        {{ device.data.details.controller_name }}
                      </div>
                      <div v-if="device.data != undefined">
                        <strong>Controller Type:</strong>
                        {{ device.data.details.controller_type }}
                      </div>
                      <div v-if="device.data != undefined">
                        <strong>VCI Enabled:</strong>
                        <i
                          class="fa fa-check text-success ml-1"
                          v-if="device.data.details.vci_enabled == 'Y'"
                        ></i>
                        <i class="fa fa-times text-danger" v-else></i>
                      </div>
                    </div>
                  </div>

                  <div class="w-66">
                    <div class="d-flex flex-wrap">
                      <div class="status-card w-25 mb-2">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-exclamation-circle mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="alert-counter">{{ alert_count }}</div>
                            <div class="alert-subtitle">Alerts</div>
                          </div>
                        </div>
                      </div>
                      <div class="status-card w-25 mb-2">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-clipboard mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="case-counter">{{ case_count }}</div>
                            <div class="case-subtitle">Cases</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex" v-if="device.data != undefined">
                  <div class="w-33 mr-2">
                    <div class="info-card mb-2">
                      <h2>Doors</h2>
                      <div v-if="device.data.details.doors.length <= 0">
                        <small>No doors found</small>
                      </div>
                      <div
                        v-for="(obj, index) in device.data.details.doors"
                        :key="index"
                        class="mb-2"
                      >
                        <div>
                          <strong>Door #{{ obj.door_no }}</strong>
                        </div>
                        <div>Name: {{ obj.door_name }}</div>
                        <div>
                          Antipassback Lock Out:
                          <i
                            class="fa fa-check text-success"
                            v-if="obj.antipassback_lockout"
                          ></i
                          ><i class="fa fa-times text-danger" v-else></i>
                        </div>
                        <div>
                          Enable Keypad:
                          <i
                            class="fa fa-check text-success"
                            v-if="obj.enable_keypad"
                          ></i
                          ><i class="fa fa-times text-danger" v-else></i>
                        </div>
                        <div>
                          Security Mode:
                          <b-badge
                            v-if="obj.security_mode == 'Low'"
                            variant="secondary"
                            >{{ obj.security_mode }}
                          </b-badge>
                          <b-badge
                            v-if="obj.security_mode == 'High'"
                            variant="warning"
                            >{{ obj.security_mode }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="w-33 mr-2" v-if="device.data != undefined">
                    <div class="info-card mb-2">
                      <h2>Inputs</h2>
                      <div v-if="device.data.details.inputs.length <= 0">
                        <small>No inputs found</small>
                      </div>
                      <div
                        v-for="(obj, index) in device.data.details.inputs"
                        :key="index"
                        class="mb-2"
                      >
                        <div>
                          <strong>Input #{{ obj.input_no }}</strong>
                        </div>
                        <div>Name: {{ obj.input_name }}</div>
                        <div>Type: {{ obj.input_type }}</div>
                        <div>
                          Trigger Mode:
                          <b-badge
                            v-if="obj.trigger_mode == 'Open'"
                            variant="success"
                            >{{ obj.trigger_mode }}</b-badge
                          >
                          <b-badge
                            v-if="obj.trigger_mode == 'Close'"
                            variant="warning"
                            >{{ obj.trigger_mode }}</b-badge
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="w-33" v-if="device.data != undefined">
                    <div class="info-card">
                      <h2>Readers</h2>
                      <div v-if="device.data.details.readers.length <= 0">
                        <small>No readers found</small>
                      </div>
                      <div
                        v-for="(obj, index) in device.data.details.readers"
                        :key="index"
                      >
                        <div>
                          <strong>Reader #{{ obj.reader_no }}</strong>
                        </div>
                        <div>Name: {{ obj.reader_name }}</div>
                        <div>Type: {{ obj.reader_type }}</div>
                        <div>Bound to Door #{{ obj.bind_to_door_no }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Door -->
              <div v-else-if="device_type == 'door'">
                <div class="d-flex">
                  <!-- Controller data -->
                  <div class="w-33 mr-2">
                    <div class="info-card mb-2">
                      <h2>Controller Information</h2>
                      <div><strong>IP:</strong> {{ controller.ip }}</div>
                      <div
                        :class="[
                          controller.data.details.controller_model != undefined
                            ? ''
                            : 'hide'
                        ]"
                        v-if="controller.data != undefined"
                      >
                        <strong>Model:</strong>
                        {{ controller.data.details.controller_model }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Name:</strong>
                        {{ controller.data.details.controller_name }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Type:</strong>
                        {{ controller.data.details.controller_type }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>VCI Enabled:</strong>
                        <i
                          class="fa fa-check text-success ml-1"
                          v-if="controller.data.details.vci_enabled == 'Y'"
                        ></i>
                        <i class="fa fa-times text-danger" v-else></i>
                      </div>
                    </div>

                    <!-- alert & case -->
                    <div class="d-flex flex-wrap">
                      <div class="status-card w-50 mb-2">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-exclamation-circle mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="alert-counter">{{ alert_count }}</div>
                            <div class="alert-subtitle">Alerts</div>
                          </div>
                        </div>
                      </div>
                      <div class="status-card w-50 mb-2 pr-0">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-clipboard mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="case-counter">{{ case_count }}</div>
                            <div class="case-subtitle">Cases</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Device data -->
                  <div class="w-66">
                    <div class="info-card mb-2">
                      <h2>Device Information</h2>

                      <div>
                        <strong>Door #{{ device.data.details.door_no }}</strong>
                      </div>
                      <div>Name: {{ device.data.details.door_name }}</div>
                      <div>
                        Antipassback Lock Out:
                        <i
                          class="fa fa-check text-success"
                          v-if="device.data.details.antipassback_lockout"
                        ></i
                        ><i class="fa fa-times text-danger" v-else></i>
                      </div>
                      <div>
                        Enable Keypad:
                        <i
                          class="fa fa-check text-success"
                          v-if="device.data.details.enable_keypad"
                        ></i
                        ><i class="fa fa-times text-danger" v-else></i>
                      </div>
                      <div>
                        Security Mode:
                        <b-badge
                          v-if="device.data.details.security_mode == 'Low'"
                          variant="secondary"
                          >{{ device.data.details.security_mode }}
                        </b-badge>
                        <b-badge
                          v-if="device.data.details.security_mode == 'High'"
                          variant="warning"
                          >{{ device.data.details.security_mode }}
                        </b-badge>
                      </div>

                      <div>
                        Antipassback Time Zone:
                        {{ device.data.details.antipassback_time_zone }}
                      </div>
                      <div>
                        Authorization Lock Release:
                        {{ device.data.details.authorization_lock_release }}
                      </div>
                      <div>
                        Buddy Mode Time Zone:
                        {{ device.data.details.buddy_mode_time_zone }}
                      </div>
                      <div>
                        Card Pin Lockout Count:
                        {{ device.data.details.card_pin_lockout_count }}
                      </div>
                      <div>
                        Card Pin Time Zone:
                        {{ device.data.details.card_pin_time_zone }}
                      </div>
                      <div>
                        Door Sensor Delay:
                        {{ device.data.details.door_sensor_delay }}
                      </div>
                      <div>
                        Entry Zone Id: {{ device.data.details.entry_zone_id }}
                      </div>
                      <div>
                        Exit Button Time Zone:
                        {{ device.data.details.exit_button_time_zone }}
                      </div>
                      <div>
                        Exit Button Time Zone:
                        {{ device.data.details.exit_button_time_zone }}
                      </div>
                      <div>
                        Exit Zone Id: {{ device.data.details.exit_zone_id }}
                      </div>
                      <div>
                        Fire Release: {{ device.data.details.fire_release }}
                      </div>
                      <div>
                        Keyed Card Pin Time Zone:
                        {{ device.data.details.keyed_card_pin_time_zone }}
                      </div>
                      <div>
                        Lock Energize Option:
                        {{ device.data.details.lock_energize_option }}
                      </div>
                      <div>
                        Lock Release Time:
                        {{ device.data.details.lock_release_time }}
                      </div>
                      <div>
                        Lock Release Time:
                        {{ device.data.details.lock_release_time }}
                      </div>
                      <div>
                        Lock Release Time Zone:
                        {{ device.data.details.lock_release_time_zone }}
                      </div>
                      <div>
                        Nested Car Park Entry Id:
                        {{ device.data.details.nested_car_park_entry_id }}
                      </div>
                      <div>
                        Nested Car Park Exit Ud:
                        {{ device.data.details.nested_car_park_exit_ud }}
                      </div>
                      <div>
                        Nested Car Park Is Outer Zone:
                        {{ device.data.details.nested_car_park_is_outer_zone }}
                      </div>
                      <div>Open Time: {{ device.data.details.open_time }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Reader -->
              <div v-else-if="device_type == 'reader'">
                <div class="d-flex">
                  <!-- Controller data -->
                  <div class="w-33 mr-2">
                    <div class="info-card mb-2">
                      <h2>Controller Information</h2>
                      <div><strong>IP:</strong> {{ controller.ip }}</div>
                      <div
                        :class="[
                          controller.data.details.controller_model != undefined
                            ? ''
                            : 'hide'
                        ]"
                        v-if="controller.data != undefined"
                      >
                        <strong>Model:</strong>
                        {{ controller.data.details.controller_model }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Name:</strong>
                        {{ controller.data.details.controller_name }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Type:</strong>
                        {{ controller.data.details.controller_type }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>VCI Enabled:</strong>
                        <i
                          class="fa fa-check text-success ml-1"
                          v-if="controller.data.details.vci_enabled == 'Y'"
                        ></i>
                        <i class="fa fa-times text-danger" v-else></i>
                      </div>
                    </div>

                    <!-- alert & case -->
                    <div class="d-flex flex-wrap">
                      <div class="status-card w-50 mb-2">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-exclamation-circle mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="alert-counter">{{ alert_count }}</div>
                            <div class="alert-subtitle">Alerts</div>
                          </div>
                        </div>
                      </div>
                      <div class="status-card w-50 mb-2 pr-0">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-clipboard mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="case-counter">{{ case_count }}</div>
                            <div class="case-subtitle">Cases</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Device data -->
                  <div class="w-66">
                    <div class="info-card mb-2">
                      <h2>Device Information</h2>

                      <div>
                        <strong
                          >Reader #{{ device.data.details.reader_no }}</strong
                        >
                      </div>
                      <div>Name: {{ device.data.details.reader_name }}</div>
                      <div>Type: {{ device.data.details.reader_type }}</div>
                      <div>
                        Bound to Door #{{ device.data.details.bind_to_door_no }}
                      </div>

                      <div>
                        Bind Gate Zone: {{ device.data.details.bind_gate_zone }}
                      </div>
                      <div>
                        Activated Time Zone:
                        {{ device.data.details.activated_time_zone }}
                      </div>
                      <div>
                        Bypass Access Limit:
                        {{ device.data.details.bypass_access_limit }}
                      </div>
                      <div>
                        Card Bypass: {{ device.data.details.card_bypass }}
                      </div>
                      <div>
                        Enable Secure Channel:
                        {{ device.data.details.enable_secure_channel }}
                      </div>
                      <div>
                        Enabled Access Limit:
                        {{ device.data.details.enabled_access_limit }}
                      </div>
                      <div>Hid Agk: {{ device.data.details.hid_agk }}</div>
                      <div>
                        Is Out Rdr: {{ device.data.details.is_out_rdr }}
                      </div>
                      <div>
                        Is Tamper Lockdown:
                        {{ device.data.details.is_tamper_lockdown }}
                      </div>
                      <div>Qr Bypass: {{ device.data.details.qr_bypass }}</div>
                      <div>
                        Use Sensor: {{ device.data.details.use_sensor }}
                      </div>
                      <div>
                        Use Tamper: {{ device.data.details.use_tamper }}
                      </div>
                      <div>
                        Wiegand Group: {{ device.data.details.wiegand_group }}
                      </div>
                      <div>
                        Wiegand Option: {{ device.data.details.wiegand_option }}
                      </div>
                      <div>
                        Wiegand Out: {{ device.data.details.wiegand_out }}
                      </div>
                      <div>
                        Wiegand Selection:
                        {{ device.data.details.wiegand_selection }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Input -->
              <div v-else-if="device_type == 'input'">
                <div class="d-flex">
                  <!-- Controller data -->
                  <div class="w-33 mr-2">
                    <div class="info-card mb-2">
                      <h2>Controller Information</h2>
                      <div><strong>IP:</strong> {{ controller.ip }}</div>
                      <div
                        :class="[
                          controller.data.details.controller_model != undefined
                            ? ''
                            : 'hide'
                        ]"
                        v-if="controller.data != undefined"
                      >
                        <strong>Model:</strong>
                        {{ controller.data.details.controller_model }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Name:</strong>
                        {{ controller.data.details.controller_name }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>Controller Type:</strong>
                        {{ controller.data.details.controller_type }}
                      </div>
                      <div v-if="controller.data != undefined">
                        <strong>VCI Enabled:</strong>
                        <i
                          class="fa fa-check text-success ml-1"
                          v-if="controller.data.details.vci_enabled == 'Y'"
                        ></i>
                        <i class="fa fa-times text-danger" v-else></i>
                      </div>
                    </div>

                    <!-- alert & case -->
                    <div class="d-flex flex-wrap">
                      <div class="status-card w-50 mb-2">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-exclamation-circle mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="alert-counter">{{ alert_count }}</div>
                            <div class="alert-subtitle">Alerts</div>
                          </div>
                        </div>
                      </div>
                      <div class="status-card w-50 mb-2 pr-0">
                        <div class="status-card-content flex-row">
                          <span class="text-counter"
                            ><i class="fa fa-clipboard mr-1"></i
                          ></span>
                          <div class="pb-2">
                            <div class="case-counter">{{ case_count }}</div>
                            <div class="case-subtitle">Cases</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Device data -->
                  <div class="w-66">
                    <div class="info-card mb-2">
                      <h2>Device Information</h2>
                      <div>
                        <strong
                          >Input #{{ device.data.details.input_no }}</strong
                        >
                      </div>
                      <div>Name: {{ device.data.details.input_name }}</div>
                      <div>Type: {{ device.data.details.input_type }}</div>
                      <div>
                        Trigger Mode:
                        <b-badge
                          v-if="device.data.details.trigger_mode == 'Open'"
                          variant="success"
                          >{{ device.data.details.trigger_mode }}</b-badge
                        >
                        <b-badge
                          v-if="device.data.details.trigger_mode == 'Close'"
                          variant="warning"
                          >{{ device.data.details.trigger_mode }}</b-badge
                        >
                      </div>

                      <div>
                        Digital Analog: {{ device.data.details.digital_analog }}
                      </div>
                      <div>
                        Door Reader Name:
                        {{ device.data.details.door_reader_name }}
                      </div>
                      <div>
                        Normal State Delay:
                        {{ device.data.details.normal_state_delay }}
                      </div>
                      <div>
                        Trigger State Delay:
                        {{ device.data.details.trigger_state_delay }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="panel-row h-50">-->
      <!--        <div class="panel w-100 mr-2">-->
      <!--          <PanelAlerts :filters="alertFilters"></PanelAlerts>-->
      <!--        </div>-->

      <!--        &lt;!&ndash; <div class="panel w-50">-->
      <!--            <PanelCases :filters="caseFilters"></PanelCases>-->
      <!--        </div> &ndash;&gt;-->
      <!--      </div>-->
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Plyr from "plyr";
import Hls from "hls.js";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import DeviceStatus from "@/components/FloorplanDevices/DeviceStatus/DeviceStatus";
// import PanelAlerts from "@/components/PanelAlerts/PanelAlerts";
// import PanelCases from "@/components/PanelCases/PanelCases"

export default {
  name: "DeviceDetails",
  components: {
    DeviceStatus
    // PanelAlerts
    // PanelCases,
  },
  data: function () {
    return {
      showPanel: false,
      liveVideoURL: null,
      liveVideoMessage: "Loading camera stream",
      livePlayer: null,
      device: null,
      device_type: null,
      controller: {},
      alertFilters: { device: null },
      caseFilters: { device: null },
      alert_count: 0,
      case_count: 0,
      device_display: "",
      deviceIsRecording: null,
      videoFormat: "mpjpeg",
      videoFormatOptions: [
        { value: "mp4", text: "MP4" },
        { value: "hls", text: "HLS" },
        { value: "mpjpeg", text: "MPJPEG" },
        { value: "webm", text: "WEBM" }
      ],
      videoURLs: {
        mp4: null,
        hls: null,
        mpjpeg: null,
        webm: null
      },
      videoAuthSession: null,
      videoAuthData: null,
      videoAuth: null
    };
  },
  computed: {
    ...mapGetters({
      _getViewingDetails: "psim/getViewingDetails",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    _getViewingDetails: {
      handler: function (n, o) {
        this.checkPanelVisible(n);
        // this.getAlertsByDevice(n);
      },
      deep: true
    }
  },
  mounted: function () {
    // this.updateStatus();
  },
  methods: {
    checkPanelVisible: function (view_data) {
      var $this = this;
      var device_types = ["camera", "controller", "door", "reader", "input"];
      if (view_data == null || device_types.indexOf(view_data.type) < 0) {
        $this.showPanel = false;
        $this.resetData();
      } else {
        if ($this.showPanel == true && $this.device.ip != view_data.data.ip) {
          $this.showPanel = false;
          $this.resetData();

          setTimeout(function () {
            $this.showPanel = true;
            $this.loadData(view_data);
          }, 500);
        } else {
          $this.showPanel = true;
          $this.loadData(view_data);
        }
      }
      var view_type = view_data != null ? view_data["type"] : null;
      // console.log("DeviceDetails: view type:", view_type, "Show?", $this.showPanel);
    },
    loadData: function (view_data) {
      var $this = this;
      $this.device = view_data.data;
      $this.device_type = view_data.type;
      // console.log($this.device)
      // console.log($this.device_type)

      $this.alertFilters = { device_ip: $this.device.ip };
      $this.caseFilters = { device_list__contains: $this.device.ip };
      var device_alerts =
        this.$store.getters["psim/getDeviceAlerts"][$this.device.id];
      var device_cases =
        this.$store.getters["psim/getDeviceCases"][$this.device.id];
      $this.alert_count = device_alerts != undefined ? device_alerts.length : 0;
      $this.case_count = device_cases != undefined ? device_cases.length : 0;

      // TODO:: check with Eva about device naming
      if ($this.device.name == $this.device.ip) {
        $this.device_display = $this.device.ip;
      } else if ($this.device.name != $this.device.ip) {
        // $this.device_display = $this.device.ip + " ("+ $this.device.name +")";
        $this.device_display = $this.device.name;
      }

      $this.deviceIsRecording = null;
      if ($this.device_type == "camera" && $this.device.data != undefined) {
        $this.getVideoStream();

        if ($this.device.data.details.is_recording != undefined) {
          $this.deviceIsRecording = $this.device.data.details.is_recording;
        }
      }

      if (
        $this.device_type === "door" ||
        $this.device_type === "reader" ||
        $this.device_type === "input"
      ) {
        let controller_data_array = $this.device.id.split("_");

        // console.log("controller_data_array[0]");
        // console.log(controller_data_array[0]);

        $this.controller = this.$store.getters["device/getController"](
          controller_data_array[0]
        );
      }
    },
    // getVideoStream: function () {
    //   var $this = this;

    //   var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
    //   var stream_urls = $this.device.data.details.stream_url;
    //   var mp4_stream = stream_urls.mp4;
    //   var hls_stream = stream_urls.hls;
    //   var mpjpeg_stream = stream_urls.mpjpeg;
    //   var webm_stream = stream_urls.webm;
    //   var has_stream = false;
    //   if (mp4_stream || hls_stream || mpjpeg_stream || webm_stream) {
    //     has_stream = true;
    //     $this.videoURLs.mp4 = mp4_stream ? mp4_stream : null;
    //     $this.videoURLs.hls = hls_stream ? hls_stream : null;
    //     $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
    //     $this.videoURLs.webm = webm_stream ? webm_stream : null;
    //   }

    //   if (has_stream == false) {
    //     $this.liveVideoMessage = "No camera stream available";
    //   } else {
    //     const client = $this.$root.getAjaxFetchClient();
    //     client
    //       .getRequest(API_URL)
    //       .then((mdata) => {
    //         let camera_auth_key = null;
    //         let camera_result = null;
    //         for (var service_url in mdata["result"]) {
    //           try {
    //             camera_result =
    //               mdata["result"][service_url]["result"]["DEV_CAM"];
    //           } catch (err) {
    //             //
    //           }

    //           if (camera_result != null) {
    //             for (var i in camera_result) {
    //               var camera_data = camera_result[i];
    //               var camera_ip = new URL(i).hostname;
    //               if (camera_ip == $this.device.ip) {
    //                 camera_auth_key = camera_data["details"]["authkey"];
    //                 break;
    //               }
    //             }
    //           }

    //           if (camera_auth_key != null) {
    //             break;
    //           }
    //         }

    //         if (camera_auth_key != null) {
    //           $this.videoAuth = camera_auth_key;
    //         }

    //         $this.loadPlayer();
    //       })
    //       .catch((err) => {
    //         $this.$store.dispatch("session/addGlobalAlertMessage", {
    //           message_text: err.detail,
    //           message_type: "danger"
    //         });
    //         // if (err.status == 401) {
    //         //   $this.$store.dispatch("session/logoutSession");
    //         // }
    //       });
    //   }
    // },
    getVideoStream: function () {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      let stream_url = null;
      let stream_urls = null;
      var mpjpeg_stream = null;
      let has_stream = false;

      if ($this.device.monitor_type === "DIGIFORT") {
        stream_urls = $this.device.data.details.stream_url;
        mpjpeg_stream = stream_urls.mpjpeg;

        if (mpjpeg_stream) {
          has_stream = true;
          $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
        }

        if (has_stream == false) {
          $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.device.ip) {
                      $this.videoAuthSession =
                        camera_data["details"]["authsession"]["AuthSession"];
                      $this.videoAuthData =
                        camera_data["details"]["authsession"]["AuthData"];
                      break;
                    }
                  }
                }
              }
              $this.loadPlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        }
      } else {
        //

        if ($this.device.data.details.stream_url != undefined) {
          stream_url = $this.device.data.details.stream_url;
        } else {
          stream_urls = $this.device.data.details.stream_urls;
        }

        // console.log("stream_url");
        // console.log(stream_url);

        if (stream_url != null) {
          let mp4_stream = stream_url.mp4;
          let hls_stream = stream_url.hls;
          let webm_stream = stream_url.webm;
          mpjpeg_stream = stream_url.mpjpeg;

          if (mp4_stream || hls_stream || mpjpeg_stream || webm_stream) {
            has_stream = true;
            $this.videoURLs.mp4 = mp4_stream ? mp4_stream : null;
            $this.videoURLs.hls = hls_stream ? hls_stream : null;
            $this.videoURLs.mpjpeg = mpjpeg_stream ? mpjpeg_stream : null;
            $this.videoURLs.webm = webm_stream ? webm_stream : null;
          }
        } else {
          //
          has_stream = false;
        }

        // console.log("has_stream");
        // console.log(has_stream);

        if (has_stream == false) {
          // console.log("No camera stream available");
          // $this.liveVideoMessage = "No camera stream available";
        } else {
          const client = $this.$root.getAjaxFetchClient();
          client
            .getRequest(API_URL)
            .then((mdata) => {
              // console.log("mdata");
              // console.log(mdata);

              let camera_auth_key = null;
              let camera_result = null;
              for (let service_url in mdata["result"]) {
                try {
                  camera_result =
                    mdata["result"][service_url]["result"]["DEV_CAM"];
                } catch (err) {
                  // console.log(err);
                }

                // console.log("camera_result");
                // console.log(camera_result);

                if (camera_result != null) {
                  for (let i in camera_result) {
                    let camera_data = camera_result[i];
                    let camera_ip = new URL(i).hostname;
                    if (camera_ip == $this.device.ip) {
                      camera_auth_key = camera_data["details"]["authkey"];
                      break;
                    }
                  }
                }

                if (camera_auth_key != null) {
                  break;
                }
              }

              if (camera_auth_key != null) {
                $this.videoAuth = camera_auth_key;
              }
              $this.loadPlayer();
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
              // if (err.status == 401) {
              //   $this.$store.dispatch("session/logoutSession");
              // }
            });
        }
      }
    },
    loadPlayer: function () {
      var $this = this;
      let video_url = null;
      if ($this.videoFormat == "mp4") {
        video_url = $this.videoURLs.mp4;
      } else if ($this.videoFormat == "hls") {
        video_url = $this.videoURLs.hls;
      } else if ($this.videoFormat == "mpjpeg") {
        video_url = $this.videoURLs.mpjpeg;
      } else if ($this.videoFormat == "webm") {
        video_url = $this.videoURLs.webm;
      }
      // console.log($this.videoFormat);

      $this.resetData();

      try {
        let live_stream_url = new URL(video_url);
        if ($this.device.monitor_type === "DIGIFORT") {
          if ($this.videoAuthSession && $this.videoAuthData) {
            live_stream_url.searchParams.set(
              "AuthSession",
              $this.videoAuthSession
            );
            live_stream_url.searchParams.set("AuthData", $this.videoAuthData);
          }
        } else {
          if ($this.videoAuth) {
            // console.log("$this.videoAuth");
            // console.log($this.videoAuth);
            live_stream_url.searchParams.set("auth", $this.videoAuth);
          }
        }
        $this.liveVideoURL = live_stream_url.toString();

        let live_video;
        let live_video_source;
        let live_player;

        if ($this.videoFormat == "mp4") {
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/mp4");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.videoFormat == "webm") {
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          live_video_source = document.createElement("source");
          live_video_source.setAttribute("src", $this.liveVideoURL);
          live_video_source.setAttribute("type", "video/webm");
          live_video.appendChild(live_video_source);

          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.videoFormat == "hls") {
          live_video = document.createElement("video");
          live_video.setAttribute("playsinline", "");
          live_video.setAttribute("controls", "");
          live_video.setAttribute("crossorigin", "");
          live_video.setAttribute("muted", "");
          $this.$refs.liveVideoContainer.appendChild(live_video);

          const hls = new Hls();
          // hls.loadSource("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8");
          hls.loadSource($this.liveVideoURL);
          hls.attachMedia(live_video);
          live_player = new Plyr(live_video, {});
          $this.livePlayer = live_player;
        } else if ($this.videoFormat == "mpjpeg") {
          live_video = document.createElement("img");
          live_video.classList.add("img-fluid");
          live_video.setAttribute("src", $this.liveVideoURL);
          live_video.setAttribute("alt", "MPJPEG Player");

          $this.$refs.liveVideoContainer.appendChild(live_video);

          $this.livePlayer = null;
        }
        // console.log(live_player);
      } catch (err) {
        $this.liveVideoMessage = "No camera stream available";
      }
    },
    resetData: function () {
      var $this = this;
      try {
        if ($this.livePlayer != null) {
          $this.livePlayer.destroy();
          $this.livePlayer = null;
        }

        $this.controller = {};
      } catch (err) {
        //
      }

      if ($this.liveVideoURL != null) {
        // console.log($this.$refs);
        while ($this.$refs.liveVideoContainer.hasChildNodes()) {
          $this.$refs.liveVideoContainer.removeChild(
            $this.$refs.liveVideoContainer.lastChild
          );
        }
      }

      $this.liveVideoURL = null;
      $this.liveVideoMessage = "Loading camera stream";
    },
    doHidePanel: function () {
      this.resetData();

      this.$store.dispatch("psim/setViewingDetails", null);
      this.$store.dispatch("psim/setLocatingAlert", null);
      // device detail url e.g. http://192.168.88.22:8080/?building=2&floor=4&device_ip=192.168.88.91&device_name=Entrance%2520Camera&device_type=camera

      var url_query_dict = JSON.parse(JSON.stringify(this.$route.query));
      if (url_query_dict.device_id != undefined) {
        delete url_query_dict["device_id"];
      }

      this.$router
        .push({
          name: this.$route.name,
          params: this.$route.params,
          query: url_query_dict
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss">
@import "./DeviceDetails.scss";
</style>
