<template>
  <!--  <div class="workspace-column">-->
  <!--    <div class="panel panel-full">-->
  <!--      <div class="panel-content">-->
  <!--        <div class="container-fluid pt-4">-->
  <div>
    <b-row no-gutters>
      <div class="error-text">
        <!--              <h1>-->
        <b-card>
          {{ pageTitle }}
        </b-card>
        <!--              </h1>-->
      </div>
    </b-row>
  </div>
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      pageTitle:
        "You do not have permission to view Dashboard. Please contact admin.",
      currentUserID: null
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
@import "./Error.scss";
</style>
